.component-school-my-homepage-pt-dailies > div:first-child {
  margin-bottom: 15px; }

.component-school-my-homepage-pt-dailies-modal-students .main {
  display: flex;
  flex-direction: column;
  align-items: flex-end; }

.component-school-my-homepage-pt-dailies-modal-students .list {
  max-height: 250px;
  overflow: scroll;
  width: 100%; }
  .component-school-my-homepage-pt-dailies-modal-students .list > div {
    display: flex;
    justify-content: space-between;
    padding: 8px 16px; }
    .component-school-my-homepage-pt-dailies-modal-students .list > div > div:nth-child(2) > a:first-child {
      margin-right: 8px; }
  .component-school-my-homepage-pt-dailies-modal-students .list > div:hover {
    background-color: #eff9ff; }

.component-school-my-homepage-pt-dailies-modal-students .ant-modal-footer > button:first-child {
  display: none; }
