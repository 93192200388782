.banke-detail .tabs-container {
  margin-top: 40px !important; }
  .banke-detail .tabs-container .back {
    cursor: pointer; }

.banke-detail .date-range {
  margin-bottom: 20px; }
  .banke-detail .date-range .ant-calendar-picker {
    margin: 0 15px; }

.banke-detail .banke-add-task {
  margin-bottom: 20px; }
  .banke-detail .banke-add-task .button-task a {
    margin-right: 10px;
    font-size: 12px; }

.banke-detail .check-all {
  margin-bottom: 10px;
  font-size: 12px; }
  .banke-detail .check-all .ant-checkbox-wrapper {
    margin-right: 4px; }
