.container-student-public-class-list {
  height: calc(100% - 40px) !important; }
  .container-student-public-class-list .public-class-box .buttons {
    width: 100%;
    margin-top: 30px;
    text-align: center; }
    .container-student-public-class-list .public-class-box .buttons button {
      margin-left: 10px;
      height: 28px;
      width: 28px;
      text-align: center; }
    .container-student-public-class-list .public-class-box .buttons .bgColor {
      background: #385dae;
      color: #fff; }
  .container-student-public-class-list .public-class-content .publicClassList {
    width: 100%;
    background: #fff;
    margin-bottom: 10px; }
    .container-student-public-class-list .public-class-content .publicClassList ul {
      position: relative;
      display: flex;
      height: 84px;
      padding-left: 40px;
      overflow: hidden; }
      .container-student-public-class-list .public-class-content .publicClassList ul li {
        font-size: 12px;
        line-height: 84px; }
        .container-student-public-class-list .public-class-content .publicClassList ul li:last-child {
          position: absolute;
          right: 20px;
          text-align: right; }
      .container-student-public-class-list .public-class-content .publicClassList ul .publicClassImg {
        width: 20%;
        height: 78px;
        overflow: hidden;
        padding: 9px 0; }
        .container-student-public-class-list .public-class-content .publicClassList ul .publicClassImg img {
          height: 67px;
          width: 120px; }
      .container-student-public-class-list .public-class-content .publicClassList ul .publicClassName {
        width: 20%; }
      .container-student-public-class-list .public-class-content .publicClassList ul .publicClassCount {
        width: 16%; }
      .container-student-public-class-list .public-class-content .publicClassList ul .publicClassStatus {
        width: 12%; }
      .container-student-public-class-list .public-class-content .publicClassList ul .publicClassBeginTime {
        width: 16%; }
      .container-student-public-class-list .public-class-content .publicClassList ul .publicClassOperation {
        width: 16%; }
        .container-student-public-class-list .public-class-content .publicClassList ul .publicClassOperation .anticon {
          cursor: pointer; }
    .container-student-public-class-list .public-class-content .publicClassList .coursePlay {
      padding: 0 40px; }
      .container-student-public-class-list .public-class-content .publicClassList .coursePlay > div {
        padding-bottom: 10px; }
      .container-student-public-class-list .public-class-content .publicClassList .coursePlay .PublicClassCourse {
        display: flex;
        height: 44px;
        width: 100%;
        background: #fbfcfd;
        line-height: 44px;
        margin: 20px 0;
        font-size: 12px; }
        .container-student-public-class-list .public-class-content .publicClassList .coursePlay .PublicClassCourse .publicCoursePlayName {
          width: 37%;
          padding-left: 20px; }
        .container-student-public-class-list .public-class-content .publicClassList .coursePlay .PublicClassCourse .publicCoursePlayTime {
          width: 29%; }
        .container-student-public-class-list .public-class-content .publicClassList .coursePlay .PublicClassCourse .publicCoursePlayStatus {
          width: 16%; }
        .container-student-public-class-list .public-class-content .publicClassList .coursePlay .PublicClassCourse .publicCourseOperation {
          width: 16%; }
          .container-student-public-class-list .public-class-content .publicClassList .coursePlay .PublicClassCourse .publicCourseOperation .button {
            display: inline-block;
            width: 80px;
            height: 28px;
            text-align: center;
            line-height: 28px;
            border: 1px solid #5279cf;
            border-radius: 4px;
            cursor: pointer;
            color: #5279cf; }
        .container-student-public-class-list .public-class-content .publicClassList .coursePlay .PublicClassCourse:hover {
          background: #f6f8f9; }
    .container-student-public-class-list .public-class-content .publicClassList .productHide {
      height: 0;
      overflow: hidden; }
  .container-student-public-class-list .public-class-content + ul {
    margin: 16px 0;
    text-align: center; }
  .container-student-public-class-list .ant-pagination li:hover {
    background: #f6f8f9;
    border-color: #d9d9d9;
    color: #29323a !important; }
  .container-student-public-class-list .ant-pagination li a {
    display: inline-block;
    height: 100%;
    width: 100%;
    margin: 0;
    text-align: center; }
    .container-student-public-class-list .ant-pagination li a:hover {
      color: #29323a !important; }
