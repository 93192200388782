.ai-chatbox {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-width: 600px;
  padding-top: 80px; }
  .ai-chatbox .chat-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    justify-content: flex-start;
    overflow: scroll; }
    .ai-chatbox .chat-content .message-container {
      margin-top: 24px; }
      .ai-chatbox .chat-content .message-container .message-content-wrapper {
        align-items: center;
        display: flex;
        flex-direction: row;
        height: fit-content;
        width: fit-content;
        max-width: 100%; }
        .ai-chatbox .chat-content .message-container .message-content-wrapper .self-message-content {
          background: #06f; }
          .ai-chatbox .chat-content .message-container .message-content-wrapper .self-message-content pre {
            color: #fff; }
        .ai-chatbox .chat-content .message-container .message-content-wrapper .assistant-message-content {
          background: #eaf1fb; }
        .ai-chatbox .chat-content .message-container .message-content-wrapper pre {
          margin: 0;
          white-space: pre-wrap; }
        .ai-chatbox .chat-content .message-container .message-content-wrapper .message-content {
          white-space: pre;
          word-wrap: normal;
          padding: 12px 16px;
          overflow: visible;
          width: 100%;
          word-break: break-word;
          border-radius: 16px;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px; }
  .ai-chatbox .chat-input {
    display: flex;
    height: 60px;
    align-items: center;
    margin-top: 12px; }
    .ai-chatbox .chat-input .chat-content {
      border: 0;
      height: 100%; }
    .ai-chatbox .chat-input button {
      height: 100%;
      background-color: white;
      border: 0;
      margin-left: 2px; }
