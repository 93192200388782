.component-school-modal-transfer-receive {
  padding: 0 20px; }
  .component-school-modal-transfer-receive .title {
    font-size: 16px;
    font-weight: 600; }
  .component-school-modal-transfer-receive .product-name {
    font-weight: 600;
    line-height: 40px;
    padding-left: 20px; }
  .component-school-modal-transfer-receive .component-common-suggest {
    margin-top: 20px; }

.transfer-list-teachers-assign.component-school-modal-transfer-receive {
  max-width: 800px;
  overflow: auto; }
  @media screen and (max-height: 600px) {
    .transfer-list-teachers-assign.component-school-modal-transfer-receive {
      max-height: 400px; } }
  @media screen and (min-height: 601px) and (max-height: 800px) {
    .transfer-list-teachers-assign.component-school-modal-transfer-receive {
      max-height: 500px; } }
  @media screen and (min-height: 801px) and (max-height: 1000px) {
    .transfer-list-teachers-assign.component-school-modal-transfer-receive {
      max-height: 700px; } }
  @media screen and (min-height: 1001px) {
    .transfer-list-teachers-assign.component-school-modal-transfer-receive {
      max-height: 900px; } }
