.questionaire-modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }
  .questionaire-modal-container .mask {
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.3; }
  .questionaire-modal-container .questionaire-modal {
    position: absolute;
    top: calc( 50% - 139px);
    left: calc( 50% - 154px);
    width: 308px;
    height: 278px;
    background-image: url(//dev-static.smartstudy.com/apollon/assets/images/questionaire-modal.4c69209.png);
    background-size: 100%; }
    .questionaire-modal-container .questionaire-modal span {
      position: absolute;
      width: 112px;
      height: 32px;
      top: 196px;
      left: 90px;
      cursor: pointer;
      z-index: 9; }

.questionaire-link {
  position: fixed;
  top: 183px;
  right: 0;
  width: 88px;
  height: 82px;
  background-image: url(//dev-static.smartstudy.com/apollon/assets/images/questionaire-link.e10cdbf.png);
  background-size: 100%;
  cursor: pointer; }
