.container-school-role-upsert .bread-crum {
  line-height: 1em;
  font-size: 16px;
  padding: 10px 0 20px 0;
  margin-bottom: 20px;
  border-bottom: 1px solid #eee; }
  .container-school-role-upsert .bread-crum .back {
    cursor: pointer; }

.container-school-role-upsert .formLabel {
  margin-right: 20px; }

.container-school-role-upsert .modulesLabel {
  style: block; }

.container-school-role-upsert .required {
  display: inline-block;
  color: red;
  font-size: 24px;
  margin-right: 7px;
  height: auto;
  width: auto; }

.container-school-role-upsert .itemInput {
  width: 20%; }

.container-school-role-upsert .item {
  margin-bottom: 30px; }

.container-school-role-upsert .selectTabs .ant-affix {
  z-index: 0; }

.container-school-role-upsert .modulesContainer {
  margin-top: 20px; }
  .container-school-role-upsert .modulesContainer .moduleCheck {
    margin-left: 10px; }
    .container-school-role-upsert .modulesContainer .moduleCheck .Checkbox {
      color: #800080; }
  .container-school-role-upsert .modulesContainer .checkGroup {
    border: 1px solid #C4C4C4;
    padding: 10px;
    width: 90%;
    margin-top: 15px; }
    .container-school-role-upsert .modulesContainer .checkGroup .optionCheck {
      margin: 5px 0 5px 0; }

.container-school-role-upsert .component-common-form-checkbox {
  display: block; }
  .container-school-role-upsert .component-common-form-checkbox:before, .container-school-role-upsert .component-common-form-checkbox:after {
    content: ' ';
    display: table;
    clear: both; }
  .container-school-role-upsert .component-common-form-checkbox .component-common-form-radio {
    display: block;
    width: 25%;
    float: left;
    border-left: none; }
