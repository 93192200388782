.student-banke-list .banke-list-components .ant-select {
  width: 75px; }

.student-banke-list .banke-list-components .course-card-wrapper {
  margin-right: 40px; }

.student-banke-list .banke-detail .back {
  font-size: 12px; }
  .student-banke-list .banke-detail .back .list {
    color: #818C96;
    cursor: pointer; }

.student-banke-list .banke-detail .banke-info {
  height: 86px;
  padding: 0 20px;
  background: #fff;
  margin-top: 20px; }
  .student-banke-list .banke-detail .banke-info > div {
    display: flex;
    height: 43px;
    line-height: 43px; }
    .student-banke-list .banke-detail .banke-info > div > div {
      flex: 1;
      display: flex;
      font-size: 12px; }
      .student-banke-list .banke-detail .banke-info > div > div > span {
        color: #818C96; }
      .student-banke-list .banke-detail .banke-info > div > div .banke-name {
        display: block;
        width: 150px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #000; }

.student-banke-list .banke-detail .tabs-container {
  margin-bottom: 20px;
  background: none; }
  .student-banke-list .banke-detail .tabs-container .ant-menu-horizontal {
    border: none; }
  .student-banke-list .banke-detail .tabs-container ul {
    background: none; }
    .student-banke-list .banke-detail .tabs-container ul .ant-menu-item {
      border: none;
      height: 32px;
      width: 80px;
      padding: 0;
      margin-right: 10px;
      border: 1px solid #C3CCD1;
      border-radius: 4px;
      text-align: center;
      line-height: 32px;
      background: #fff;
      font-size: 12px; }
    .student-banke-list .banke-detail .tabs-container ul .ant-menu-item-selected {
      background: #385DAE;
      color: #fff !important; }

.student-banke-list .banke-detail .banke-group-course .schedule-calendar {
  background: #f6f8f9; }
