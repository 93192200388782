.ellipsis {
  overflow: hidden;
  display: inline-block;
  word-break: break-all;
  width: 100%;
  font-size: 12px; }

.lines {
  position: relative; }
  .lines .shadow {
    display: block;
    position: relative;
    color: transparent;
    opacity: 0;
    z-index: -999; }

.lineClamp {
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box; }
