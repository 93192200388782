.online-trial-course-request {
  width: 100%; }
  .online-trial-course-request .container {
    display: flex;
    flex-direction: column;
    align-items: left;
    margin: auto;
    width: 800px; }
  .online-trial-course-request .modal-line {
    margin-top: 20px; }
  .online-trial-course-request .filter-item {
    margin-right: 20px;
    display: flex;
    align-items: center; }
    .online-trial-course-request .filter-item label {
      display: inline-block;
      width: 60px;
      font-size: 12px; }
  .online-trial-course-request .rules p {
    margin: 0;
    font-size: 12px; }
  .online-trial-course-request .submit {
    margin-top: 20px;
    width: 100px; }
