.create-teaching-plan-modal .ant-modal-body {
  padding: 32px 0 20px 50px; }
  .create-teaching-plan-modal .ant-modal-body .select-container > div {
    margin-bottom: 20px; }
    .create-teaching-plan-modal .ant-modal-body .select-container > div .ant-select {
      width: 360px; }

.teaching-plans-container .header {
  display: flex;
  justify-content: space-between; }
  .teaching-plans-container .header .filters {
    display: flex;
    width: 100%; }
    .teaching-plans-container .header .filters .filter-item {
      margin-right: 14px; }
      .teaching-plans-container .header .filters .filter-item label {
        margin-right: 10px;
        font-size: 12px; }

.teaching-plans-container .table-container {
  margin-top: 32px !important; }
  .teaching-plans-container .table-container .ant-table-thead tr th {
    text-align: center; }
  .teaching-plans-container .table-container .ant-table-tbody .ant-table-row td {
    text-align: center !important;
    padding: 16px; }
    .teaching-plans-container .table-container .ant-table-tbody .ant-table-row td:last-of-type a {
      margin-right: 5px; }

.teaching-plan-detail-container .header {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px; }
  .teaching-plan-detail-container .header > span {
    width: 100px;
    color: #666;
    font-size: 12px;
    cursor: pointer; }
    .teaching-plan-detail-container .header > span i {
      margin-right: 5px; }
  .teaching-plan-detail-container .header > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid #d9d9d9; }
    .teaching-plan-detail-container .header > div p {
      font-size: 12px;
      margin: 0;
      color: #666; }
      .teaching-plan-detail-container .header > div p .red {
        color: red; }

.teaching-plan-detail-container .footer {
  margin-top: 40px;
  text-align: center; }
  .teaching-plan-detail-container .footer .ant-btn {
    width: 80px;
    margin: 0 22px; }

.choose-template-modal .ant-modal {
  width: 600px !important; }

.choose-template-modal .ant-modal-body {
  padding: 33px 80px 40px; }
  .choose-template-modal .ant-modal-body .choose-template-container > div label {
    margin-right: 20px; }
  .choose-template-modal .ant-modal-body .choose-template-container .stage {
    margin-bottom: 15px; }

.teaching-plan-block-container .teaching-plan-block-header {
  margin-bottom: 32px;
  padding: 0 60px 0 20px;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fafafa;
  cursor: pointer; }
  .teaching-plan-block-container .teaching-plan-block-header .index {
    font-size: 16px;
    color: #333;
    font-weight: 600; }
  .teaching-plan-block-container .teaching-plan-block-header .edit-btns {
    height: 30px; }
    .teaching-plan-block-container .teaching-plan-block-header .edit-btns span, .teaching-plan-block-container .teaching-plan-block-header .edit-btns i {
      float: left;
      padding: 0 8px;
      height: 30px;
      line-height: 30px;
      color: #39f;
      font-size: 12px; }
    .teaching-plan-block-container .teaching-plan-block-header .edit-btns i {
      color: #aaa; }

.teaching-plan-block-container .teaching-plan-block-body.show {
  display: block; }

.teaching-plan-block-container .teaching-plan-block-body.hide {
  display: none; }

.teaching-plan-block-container .teaching-plan-block-body .course-template-container {
  padding-left: 20px; }
