.report-detail-container .back-link-container {
  position: absolute;
  top: 0;
  left: 0;
  padding-left: 32px;
  width: 100%;
  height: 57px;
  line-height: 57px; }
  .report-detail-container .back-link-container .back-link {
    font-size: 12px;
    color: #282828; }

.report-detail-container .export-container {
  display: flex;
  justify-content: flex-end; }
