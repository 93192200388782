.knowledge-repository-container {
  margin: 0 auto 50px;
  font-family: PingFang SC; }
  .knowledge-repository-container .knowledge-title {
    height: 68px;
    line-height: 68px;
    text-align: left;
    font-size: 14px;
    font-weight: 600;
    color: #2A303C; }
  .knowledge-repository-container .knowledge-anchor {
    position: fixed;
    bottom: 100px;
    right: 15px;
    width: 40px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    cursor: pointer;
    border-radius: 4px;
    box-shadow: 0 0 3px 0 rgba(195, 204, 209, 0.99); }
