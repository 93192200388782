.add-pigai-authorization-modal {
  margin-top: 20px;
  text-align: center; }
  .add-pigai-authorization-modal .modal-item {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    width: 90%; }
    .add-pigai-authorization-modal .modal-item label {
      width: 80px; }
    .add-pigai-authorization-modal .modal-item .ant-select,
    .add-pigai-authorization-modal .modal-item .ant-input-number {
      width: 340px;
      margin-right: 3px; }
