.student-score-container .student-score-filter {
  display: flex;
  flex-wrap: wrap; }
  .student-score-container .student-score-filter .filter-item {
    margin: 0 15px 10px 0; }
    .student-score-container .student-score-filter .filter-item .label {
      font-size: 12px; }

.student-score-container .operations a {
  margin-right: 6px; }

.student-score-modal .tip {
  font-size: 16px;
  font-weight: bold; }

.student-score-modal .warn {
  margin: 10px 0 0;
  font-size: 12px;
  color: red; }
