.component-common-filter {
  padding: 15px 18px;
  background: #fff;
  border-radius: 2px;
  box-shadow: rgba(0, 0, 0, 0.0470588) 0 1px 1px 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center; }
  .component-common-filter:before, .component-common-filter:after {
    content: ' ';
    display: table;
    clear: both; }
  .component-common-filter > .list {
    margin-left: -18px;
    margin-top: -15px;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -moz-box-flex: 1;
    -moz-flex: 1;
    -ms-flex: 1;
    flex: 1; }
  .component-common-filter .item {
    display: block;
    float: left;
    margin-top: 15px;
    margin-left: 18px;
    height: 35px;
    line-height: 35px; }
  .component-common-filter .date-interval:before, .component-common-filter .date-interval:after {
    content: ' ';
    display: table;
    clear: both; }
  .component-common-filter .date-interval .ant-calendar-picker-icon {
    top: 9px;
    right: 0; }
  .component-common-filter .date-interval .ant-calendar-picker {
    width: 220px;
    margin: -1px 7px 7px 2px; }
    .component-common-filter .date-interval .ant-calendar-picker .ant-calendar-picker-input {
      border: none; }
  .component-common-filter .date-interval .ant-calendar-range-picker {
    width: 200px;
    border: none;
    padding: 0;
    margin-right: 10px;
    height: 22px; }
  .component-common-filter .date-interval .ant-calendar-picker-icon {
    display: none; }
  .component-common-filter .date-interval > .title {
    cursor: default;
    float: left;
    padding: 0 10px;
    padding-left: 16px;
    display: inline-block;
    background: #f7f7f7;
    border-radius: 35px 0 0 35px;
    border: 1px solid #eee;
    height: 35px;
    font-size: 13px; }
  .component-common-filter .date-interval > .content {
    display: block;
    float: left;
    border: 1px solid #eee;
    border-left: none;
    border-radius: 0 35px 35px 0;
    height: 35px;
    font-size: 13px; }
  .component-common-filter .buttons {
    float: right; }
    .component-common-filter .buttons .item {
      background: #39f;
      color: #fff;
      border: 1px solid #39f;
      padding: 0px 18px;
      border-radius: 35px;
      overflow: hidden;
      cursor: pointer;
      transition: background .15s;
      font-size: 13px;
      margin-top: 0; }
      .component-common-filter .buttons .item:hover {
        background: #1f8fff; }
