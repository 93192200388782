.container-platform-config-organization-create-update .component-common-form:nth-child(2) {
  margin-top: 25px; }

.container-platform-config-organization-create-update .selector {
  height: 35px;
  font-size: 13px;
  border-radius: 2px;
  border: 1px solid #d9d9d9;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none; }
  .container-platform-config-organization-create-update .selector .component-common-form-input {
    padding: 0;
    line-height: 35px;
    border: none;
    border-right: 1px solid #e9e9e9;
    font-size: 13px;
    -webkit-box-flex: 5;
    -webkit-flex: 5;
    -moz-box-flex: 5;
    -moz-flex: 5;
    -ms-flex: 5;
    flex: 5; }
    .container-platform-config-organization-create-update .selector .component-common-form-input input {
      border: none;
      height: 33px; }
  .container-platform-config-organization-create-update .selector .button {
    text-align: center;
    line-height: 35px;
    background: #f9f9f9;
    cursor: pointer;
    transition: background .15s;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -moz-box-flex: 1;
    -moz-flex: 1;
    -ms-flex: 1;
    flex: 1; }
    .container-platform-config-organization-create-update .selector .button:hover {
      background: #f5f5f5; }
    .container-platform-config-organization-create-update .selector .button .text {
      margin-right: 5px; }
