.checkbox-all .title-item {
  font-weight: 500; }

.checkbox-all .checkbox-title {
  float: left;
  min-width: 70px;
  line-height: 37px; }

.checkbox-all .checkbox-item {
  padding-left: 70px;
  border: 1px solid #d9d9d9;
  background: #f9f9f9;
  border-radius: 2px; }

.checkbox-all .component-common-form-radio {
  display: inline-block;
  margin-bottom: 2px;
  width: 25%; }
