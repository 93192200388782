.teacher-course-hours .filter {
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee; }

.teacher-course-hours .filter-item {
  display: flex;
  flex-wrap: wrap;
  margin-right: 20px; }
  .teacher-course-hours .filter-item .ant-calendar-picker {
    width: 110px; }
  .teacher-course-hours .filter-item .filter-item-input {
    width: 236px; }
  .teacher-course-hours .filter-item > label {
    padding: 7px 0;
    display: inline-block; }
  .teacher-course-hours .filter-item > div {
    padding: 3px; }
  .teacher-course-hours .filter-item .exam {
    width: 160px; }

.teacher-course-hours .container-main {
  background-color: #fff; }
  .teacher-course-hours .container-main .getout-excel {
    display: inline-block;
    width: 100px;
    height: 28px;
    margin-top: 15px;
    text-align: center;
    line-height: 28px;
    border-radius: 4px;
    background: #355AA0;
    color: #fff !important; }
  .teacher-course-hours .container-main .table-name {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    padding: 15px; }
  .teacher-course-hours .container-main .teacher-course-hours-table .ant-table-tbody > tr > td {
    padding: 8px 8px; }
  .teacher-course-hours .container-main .teacher-course-hours-exported-table {
    display: none !important; }
  .teacher-course-hours .container-main .ant-table-fixed .ant-table-thead tr th:first-child {
    border-left: 1px solid #eee; }
  .teacher-course-hours .container-main .ant-table-fixed tr:last-child td span {
    font-weight: normal !important; }
  .teacher-course-hours .container-main .ant-table-fixed tr:first-child td:first-child {
    border-left: 1px solid #eee; }
  .teacher-course-hours .container-main .ant-table-fixed th,
  .teacher-course-hours .container-main .ant-table-fixed td {
    height: 54px; }
    .teacher-course-hours .container-main .ant-table-fixed th span,
    .teacher-course-hours .container-main .ant-table-fixed td span {
      display: inline-block;
      text-align: center; }
    .teacher-course-hours .container-main .ant-table-fixed th:first-child,
    .teacher-course-hours .container-main .ant-table-fixed td:first-child {
      border: 1px solid #eee;
      border-top: 0;
      width: 50px;
      border-left: 1px solid #eee; }
    .teacher-course-hours .container-main .ant-table-fixed th:last-child,
    .teacher-course-hours .container-main .ant-table-fixed td:last-child {
      border: 1px solid #eee;
      border-top: 0;
      -webkit-boder-left: 1px solid #eee; }

.ant-radio-button-wrapper {
  font-size: 12px; }
