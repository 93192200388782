.transfer-list-container .table-container table tr th {
  padding-left: 8px; }

.transfer-list-container .table-container table tr td .ant-table-wrapper {
  margin: 0; }

.transfer-list-container .table-container table tr td .ant-empty-image {
  display: none; }

.transfer-list-container .table-container table tr td .ant-table-placeholder {
  border-bottom: none; }

.transfer-list-container .table-container table tr td .ant-empty-normal {
  margin: 0; }

.transfer-list-container .table-container table tr .transfer-id {
  padding-left: 8px;
  height: 48px;
  position: relative; }
  .transfer-list-container .table-container table tr .transfer-id .discarded {
    position: absolute;
    left: 10px;
    height: 30px;
    width: 50px;
    display: inline-block;
    background-size: cover;
    opacity: 0.5; }
  .transfer-list-container .table-container table tr .transfer-id .refund {
    position: absolute;
    left: 10px;
    height: 30px;
    width: 50px;
    display: inline-block;
    background-size: cover;
    opacity: 0.5; }

.transfer-list-container .table-container table tr .center {
  height: 48px; }
  .transfer-list-container .table-container table tr .center img {
    margin-top: 2px; }

.transfer-list-container .expandedrows table, .transfer-list-container .expandedrows tr, .transfer-list-container .expandedrows td {
  border: none; }

.reject-reason {
  width: 400px;
  height: 150px;
  border: 1px solid #999;
  margin: 30px 43px;
  padding: 10px; }

.teachPT .ant-modal-body {
  overflow-y: scroll;
  max-height: 500px; }

.teachPT .product-info .total-class {
  margin-left: 30px; }

.teachPT .product-info > div {
  padding: 10px;
  display: flex; }
  .teachPT .product-info > div .form-item {
    display: flex; }
    .teachPT .product-info > div .form-item .form-text {
      width: 40px;
      text-align: right;
      margin-right: 15px; }
    .teachPT .product-info > div .form-item .antd-select {
      width: 200px;
      margin-right: 15px; }
  .teachPT .product-info > div .hour {
    margin-left: 8px; }
  .teachPT .product-info > div .form-item-small {
    margin-left: 15px; }

.servicePT .ant-modal-body {
  height: 100px;
  text-align: center;
  margin-top: 35px; }
