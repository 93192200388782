.smart-adaptation-preparing {
  height: 100%;
  background: #fff; }
  .smart-adaptation-preparing header {
    position: relative;
    height: 56px;
    line-height: 56px;
    font-size: 18px;
    text-align: center;
    font-weight: bolder;
    cursor: default;
    color: #29323a; }
    .smart-adaptation-preparing header > i {
      position: absolute;
      top: 20px;
      left: 23px;
      color: #39f;
      cursor: pointer; }
  .smart-adaptation-preparing .smart-adaptation-preparing-wrapper {
    height: calc( 100% - 100px);
    display: flex;
    align-items: center; }
    .smart-adaptation-preparing .smart-adaptation-preparing-wrapper .smart-adaptation-preparing-content {
      width: 100%;
      text-align: center; }
      .smart-adaptation-preparing .smart-adaptation-preparing-wrapper .smart-adaptation-preparing-content .header {
        font-size: 14px; }
        .smart-adaptation-preparing .smart-adaptation-preparing-wrapper .smart-adaptation-preparing-content .header p {
          color: #818c96;
          font-size: 14px; }
        .smart-adaptation-preparing .smart-adaptation-preparing-wrapper .smart-adaptation-preparing-content .header h1 {
          margin-top: 40px;
          font-size: 24px;
          font-weight: 600;
          color: #29323a; }
      .smart-adaptation-preparing .smart-adaptation-preparing-wrapper .smart-adaptation-preparing-content .smart-adaptation-preparing-block,
      .smart-adaptation-preparing .smart-adaptation-preparing-wrapper .smart-adaptation-preparing-content .smart-adaptation-preparing-exam-date {
        padding-top: 1.5%; }
        .smart-adaptation-preparing .smart-adaptation-preparing-wrapper .smart-adaptation-preparing-content .smart-adaptation-preparing-block .smart-adaptation-preparing-step-body,
        .smart-adaptation-preparing .smart-adaptation-preparing-wrapper .smart-adaptation-preparing-content .smart-adaptation-preparing-exam-date .smart-adaptation-preparing-step-body {
          margin-top: 15px; }
      .smart-adaptation-preparing .smart-adaptation-preparing-wrapper .smart-adaptation-preparing-content .smart-adaptation-preparing-block {
        height: 220px; }
      .smart-adaptation-preparing .smart-adaptation-preparing-wrapper .smart-adaptation-preparing-content .smart-adaptation-preparing-exam-date {
        height: 108; }
        .smart-adaptation-preparing .smart-adaptation-preparing-wrapper .smart-adaptation-preparing-content .smart-adaptation-preparing-exam-date .ant-calendar-picker {
          margin-top: 18px;
          padding-left: 48px; }
  .smart-adaptation-preparing footer {
    margin-top: 30px;
    text-align: center; }

.smart-adaptation-preparing-step-body {
  text-align: center; }
  .smart-adaptation-preparing-step-body > .title {
    height: 33px;
    font-family: PingFangSC;
    font-size: 24px;
    font-weight: 600;
    color: #29323a;
    margin-bottom: 11px;
    margin-top: 40px; }
  .smart-adaptation-preparing-step-body > .sub-title {
    height: 17px;
    font-family: PingFangSC;
    font-size: 12px;
    color: #818c96;
    margin-bottom: 23px; }
  .smart-adaptation-preparing-step-body > .total-score {
    margin-bottom: 50px; }
    .smart-adaptation-preparing-step-body > .total-score input {
      text-align: center;
      font-size: large;
      color: #aaa; }
  .smart-adaptation-preparing-step-body > .text {
    color: #c3ccd1; }
  .smart-adaptation-preparing-step-body > .subject-wrapper .score {
    margin-bottom: 30px;
    text-align: center; }
    .smart-adaptation-preparing-step-body > .subject-wrapper .score .ant-input-number .ant-input-number-input-wrap {
      height: 100%; }
      .smart-adaptation-preparing-step-body > .subject-wrapper .score .ant-input-number .ant-input-number-input-wrap input {
        height: 100%;
        font-size: 20px;
        text-align: center;
        color: #818C96; }

.smart-adaptation-preparing-step-footer {
  text-align: center; }
  .smart-adaptation-preparing-step-footer > button {
    width: 120px;
    height: 32px; }
  .smart-adaptation-preparing-step-footer > button:nth-child(2) {
    margin-left: 97px; }

.smart-adaptation-preparing-popup .ant-popover-content {
  transform: translateY(-18px); }

.smart-adaptation-preparing-popup .ant-popover-arrow {
  display: none; }

.smart-adaptation-preparing-popup .popup-image {
  background: url(//dev-static.smartstudy.com/apollon/assets/images/mascot.0142dc5.png);
  background-size: 100%;
  background-repeat: no-repeat;
  height: 52px;
  width: 52px;
  transform: scaleX(-1);
  display: inline-block; }

.smart-adaptation-preparing-popup .ant-popover-inner {
  width: 181px;
  padding-left: 15px;
  margin-top: 24px; }

.smart-adaptation-preparing-popup .ant-popover-inner-content {
  padding: 0; }
  .smart-adaptation-preparing-popup .ant-popover-inner-content > div > span {
    display: inline-block;
    transform: translateY(-6px); }

.popover-in-modal .ant-popover-inner {
  margin-top: 34px; }

.target-history > div:not(:last-child) {
  margin-bottom: 32px; }

.target-history > div header {
  color: #818C96; }

.target-history > div > div {
  margin-top: 10px;
  color: #29323a; }
  .target-history > div > div span {
    margin-right: 26px;
    color: #29323a; }

.target-history > div .xiaozhi {
  display: inline-block;
  width: 41px;
  height: 49px;
  background: url(//dev-static.smartstudy.com/apollon/assets/images/xiaozhi.6b694f8.png) no-repeat;
  background-size: contain;
  margin: 0; }

.target-history > div .prompt {
  margin-top: -27px; }
  .target-history > div .prompt span {
    display: inline-block;
    width: 280px;
    height: 26px;
    font-size: 12px;
    background: #fff4f3;
    border-radius: 13px;
    color: #f04134;
    line-height: 26px;
    margin: 0; }
