.testpaper-generator-container .filter-container {
  display: flex;
  justify-content: space-between; }
  .testpaper-generator-container .filter-container .filter-left {
    display: flex;
    flex-wrap: wrap;
    font-size: 12px; }
    .testpaper-generator-container .filter-container .filter-left .filter-item {
      margin-bottom: 16px; }
    .testpaper-generator-container .filter-container .filter-left .filter-item:not(:last-of-type) {
      margin-right: 20px; }

.testpaper-generator-container .table-container .ant-spin {
  width: 100%;
  height: 200px;
  text-align: center; }
  .testpaper-generator-container .table-container .ant-spin .ant-spin-dot {
    top: 76px;
    display: inline-block;
    width: 48px;
    height: 48px; }
    .testpaper-generator-container .table-container .ant-spin .ant-spin-dot i {
      width: 30px;
      height: 30px; }

.testpaper-generator-container .table-container .report-link {
  margin-right: 6px;
  color: #39f;
  cursor: pointer; }

.testpaper-generator-container .table-container table th {
  text-align: left !important; }

.testpaper-generator-container .table-container table td:first-child,
.testpaper-generator-container .table-container table th:first-child {
  padding-left: 25px; }

.testpaper-generator-container .table-container table td {
  text-align: left; }

.testpaper-generator-container .table-container .ant-table-body-inner td:first-child,
.testpaper-generator-container .table-container .ant-table-body-inner th:first-child {
  padding-left: 16px; }

.duration-text {
  margin-left: 59px; }
  .duration-text .num,
  .duration-text .text {
    padding: 0 2px;
    color: #FC6238; }
  .duration-text .num {
    padding-left: 4px; }

.modal-line {
  display: flex;
  margin-bottom: 20px;
  align-items: center; }
  .modal-line .ant-select {
    width: 81.8%; }
  .modal-line .minute {
    width: 70%;
    margin-right: 12px; }
