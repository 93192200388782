.evaluation-container .filter-container {
  display: flex;
  justify-content: space-between; }
  .evaluation-container .filter-container .filter-left {
    display: flex;
    flex-wrap: wrap;
    font-size: 12px; }
    .evaluation-container .filter-container .filter-left .filter-item {
      margin-bottom: 16px; }
    .evaluation-container .filter-container .filter-left .filter-item:not(:last-of-type) {
      margin-right: 20px; }

.evaluation-container .mokao-list-container .ant-spin,
.evaluation-container .table-container .ant-spin {
  width: 100%;
  height: 200px;
  text-align: center; }
  .evaluation-container .mokao-list-container .ant-spin .ant-spin-dot,
  .evaluation-container .table-container .ant-spin .ant-spin-dot {
    top: 76px;
    display: inline-block;
    width: 48px;
    height: 48px; }
    .evaluation-container .mokao-list-container .ant-spin .ant-spin-dot i,
    .evaluation-container .table-container .ant-spin .ant-spin-dot i {
      width: 30px;
      height: 30px; }

.evaluation-container .mokao-list-container .report-link,
.evaluation-container .table-container .report-link {
  margin-right: 6px;
  color: #39f;
  cursor: pointer; }

.evaluation-container .mokao-list-container table th,
.evaluation-container .table-container table th {
  text-align: left !important; }

.evaluation-container .mokao-list-container table td:first-child,
.evaluation-container .mokao-list-container table th:first-child,
.evaluation-container .table-container table td:first-child,
.evaluation-container .table-container table th:first-child {
  padding-left: 25px; }

.evaluation-container .mokao-list-container table td,
.evaluation-container .table-container table td {
  text-align: left; }

.evaluation-container .mokao-list-container .ant-table-body-inner td:first-child,
.evaluation-container .mokao-list-container .ant-table-body-inner th:first-child,
.evaluation-container .table-container .ant-table-body-inner td:first-child,
.evaluation-container .table-container .ant-table-body-inner th:first-child {
  padding-left: 16px; }

.duration-text {
  margin-left: 59px; }
  .duration-text .num,
  .duration-text .text {
    padding: 0 2px;
    color: #FC6238; }
  .duration-text .num {
    padding-left: 4px; }

.modal-line {
  display: flex;
  margin-bottom: 20px;
  align-items: center; }
  .modal-line .ant-select {
    width: 81.8%; }
  .modal-line .minute {
    width: 70%;
    margin-right: 12px; }

.report-list-container .report-item-block {
  margin-top: 18px;
  padding-bottom: 10px; }
  .report-list-container .report-item-block p {
    margin-bottom: 8px;
    font-weight: 600;
    font-size: 14px; }
  .report-list-container .report-item-block .report-list-item {
    padding: 8px 0;
    display: flex;
    justify-content: space-between; }
    .report-list-container .report-item-block .report-list-item span {
      text-align: center; }
    .report-list-container .report-item-block .report-list-item .name {
      width: 60%;
      text-align: left; }
    .report-list-container .report-item-block .report-list-item .isCompleted {
      width: 20%;
      position: relative; }
      .report-list-container .report-item-block .report-list-item .isCompleted .prefix {
        width: 6px;
        height: 6px;
        position: absolute;
        top: 7px;
        left: 11px;
        border-radius: 9px; }
      .report-list-container .report-item-block .report-list-item .isCompleted .gray {
        background: #999; }
      .report-list-container .report-item-block .report-list-item .isCompleted .green {
        background: #67CD3C; }
    .report-list-container .report-item-block .report-list-item .link {
      width: 20%;
      color: #39f;
      text-align: right; }

.report-list-container .report-item-block:first-child {
  margin-top: 0; }

.student-reports-container {
  padding-bottom: 40px;
  position: relative;
  font-size: 12px; }
  .student-reports-container .ant-spin {
    width: 100%;
    height: 600px;
    text-align: center; }
    .student-reports-container .ant-spin .ant-spin-dot {
      top: 176px;
      display: inline-block;
      width: 48px;
      height: 48px; }
      .student-reports-container .ant-spin .ant-spin-dot i {
        width: 30px;
        height: 30px; }
  .student-reports-container .no-test {
    margin-top: 16%;
    display: inline-block;
    width: 200px;
    height: 200px;
    text-align: center; }
    .student-reports-container .no-test img {
      background-image: none; }
    .student-reports-container .no-test p {
      font-weight: 600; }
  .student-reports-container .student-reports-list .report-title {
    padding-bottom: 10px;
    font-weight: 600; }
    .student-reports-container .student-reports-list .report-title .number {
      padding: 0 8px;
      color: #39f; }
  .student-reports-container .student-reports-list .real-mokao-item {
    padding: 23px 0 23px 40px;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff; }
    .student-reports-container .student-reports-list .real-mokao-item .name {
      width: 40%; }
      .student-reports-container .student-reports-list .real-mokao-item .name i {
        margin-right: 12px; }
    .student-reports-container .student-reports-list .real-mokao-item .date {
      width: 18%;
      text-align: left; }
    .student-reports-container .student-reports-list .real-mokao-item .status {
      width: 10%;
      position: relative;
      text-align: left; }
      .student-reports-container .student-reports-list .real-mokao-item .status .status-container {
        position: relative;
        display: flex;
        align-items: center; }
        .student-reports-container .student-reports-list .real-mokao-item .status .status-container .prefix {
          display: inline-block;
          margin-right: 4px;
          width: 4px;
          height: 4px;
          border-radius: 9px; }
        .student-reports-container .student-reports-list .real-mokao-item .status .status-container .pre1 {
          background: #c3ccd1;
          box-shadow: 0 0 3px 0 rgba(195, 204, 209, 0.99); }
        .student-reports-container .student-reports-list .real-mokao-item .status .status-container .pre3 {
          background: #23cb5b;
          box-shadow: 0 0 3px 0 rgba(49, 212, 104, 0.5); }
        .student-reports-container .student-reports-list .real-mokao-item .status .status-container .pre4 {
          background: #5279cf;
          box-shadow: 0 0 3px 0 rgba(51, 153, 255, 0.5); }
      .student-reports-container .student-reports-list .real-mokao-item .status .red {
        background: red; }
      .student-reports-container .student-reports-list .real-mokao-item .status .green {
        background: green; }
    .student-reports-container .student-reports-list .real-mokao-item .report {
      width: 32%;
      text-align: left; }
      .student-reports-container .student-reports-list .real-mokao-item .report .report-btn {
        display: inline-block;
        width: 78px;
        height: 26px;
        border: solid 1px #5279cf;
        margin-right: 3%;
        border-radius: 4px;
        color: #5279cf;
        cursor: pointer;
        line-height: 25px;
        text-align: center;
        background: #fff; }
        .student-reports-container .student-reports-list .real-mokao-item .report .report-btn:hover {
          background: #f6f8f9; }
      .student-reports-container .student-reports-list .real-mokao-item .report .big-report-btn {
        width: 98px; }
  .student-reports-container .student-reports-list .folding-report-item .folding-report {
    padding: 23px 0 23px 40px;
    margin-top: 10px;
    background: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .student-reports-container .student-reports-list .folding-report-item .folding-report .name {
      width: 40%; }
      .student-reports-container .student-reports-list .folding-report-item .folding-report .name i {
        margin-right: 14px; }
    .student-reports-container .student-reports-list .folding-report-item .folding-report .date {
      width: 18%; }
    .student-reports-container .student-reports-list .folding-report-item .folding-report .status {
      width: 10%;
      position: relative;
      text-align: left; }
      .student-reports-container .student-reports-list .folding-report-item .folding-report .status .status-container {
        position: relative;
        display: flex;
        align-items: center; }
        .student-reports-container .student-reports-list .folding-report-item .folding-report .status .status-container .prefix {
          display: inline-block;
          margin-right: 4px;
          width: 4px;
          height: 4px;
          border-radius: 9px; }
        .student-reports-container .student-reports-list .folding-report-item .folding-report .status .status-container .pre1 {
          background: #c3ccd1;
          box-shadow: 0 0 3px 0 rgba(195, 204, 209, 0.99); }
        .student-reports-container .student-reports-list .folding-report-item .folding-report .status .status-container .pre3 {
          background: #23cb5b;
          box-shadow: 0 0 3px 0 rgba(49, 212, 104, 0.5); }
        .student-reports-container .student-reports-list .folding-report-item .folding-report .status .status-container .pre4 {
          background: #5279cf;
          box-shadow: 0 0 3px 0 rgba(51, 153, 255, 0.5); }
    .student-reports-container .student-reports-list .folding-report-item .folding-report .combined {
      display: flex;
      width: 32%; }
      .student-reports-container .student-reports-list .folding-report-item .folding-report .combined .score {
        width: 75%;
        text-align: left; }
        .student-reports-container .student-reports-list .folding-report-item .folding-report .combined .score a {
          display: inline-block;
          width: 98px;
          height: 26px;
          border: solid 1px #5279cf;
          margin-right: 8%;
          border-radius: 4px;
          color: #5279cf;
          cursor: pointer;
          line-height: 25px;
          text-align: center; }
          .student-reports-container .student-reports-list .folding-report-item .folding-report .combined .score a:hover {
            background: #fbfcfd; }
      .student-reports-container .student-reports-list .folding-report-item .folding-report .combined .switch {
        width: 20%;
        text-align: right;
        margin-right: 20px;
        cursor: pointer;
        color: #818c96;
        line-height: 26px; }
  .student-reports-container .student-reports-list .folding-report-item .report-list-body {
    padding: 23px 0 23px 40px;
    background: #fff;
    border-top: 1px solid #eee; }
    .student-reports-container .student-reports-list .folding-report-item .report-list-body .report-list-block {
      padding: 10px 0; }
      .student-reports-container .student-reports-list .folding-report-item .report-list-body .report-list-block .subject-title {
        margin-bottom: 6px;
        padding-left: 4px;
        line-height: 14px;
        border-left: 4px solid #355aa0; }
      .student-reports-container .student-reports-list .folding-report-item .report-list-body .report-list-block .report-inner-block {
        display: flex;
        justify-content: space-between;
        padding: 13px 0;
        align-items: center; }
        .student-reports-container .student-reports-list .folding-report-item .report-list-body .report-list-block .report-inner-block:hover {
          background: #fbfcfd; }
        .student-reports-container .student-reports-list .folding-report-item .report-list-body .report-list-block .report-inner-block .name {
          padding-left: 8px;
          width: 58%;
          color: #29323a; }
          .student-reports-container .student-reports-list .folding-report-item .report-list-body .report-list-block .report-inner-block .name .anticon {
            color: #d0dce7;
            margin-right: 10px; }
        .student-reports-container .student-reports-list .folding-report-item .report-list-body .report-list-block .report-inner-block .status {
          position: relative;
          width: 10%; }
          .student-reports-container .student-reports-list .folding-report-item .report-list-body .report-list-block .report-inner-block .status .status-container {
            position: relative;
            display: flex;
            align-items: center; }
            .student-reports-container .student-reports-list .folding-report-item .report-list-body .report-list-block .report-inner-block .status .status-container .prefix {
              display: inline-block;
              margin-right: 4px;
              width: 4px;
              height: 4px;
              border-radius: 9px; }
            .student-reports-container .student-reports-list .folding-report-item .report-list-body .report-list-block .report-inner-block .status .status-container .pre1 {
              background: #c3ccd1;
              box-shadow: 0 0 3px 0 rgba(195, 204, 209, 0.99); }
            .student-reports-container .student-reports-list .folding-report-item .report-list-body .report-list-block .report-inner-block .status .status-container .pre3 {
              background: #23cb5b;
              box-shadow: 0 0 3px 0 rgba(49, 212, 104, 0.5); }
            .student-reports-container .student-reports-list .folding-report-item .report-list-body .report-list-block .report-inner-block .status .status-container .pre2 {
              background: #5279cf;
              box-shadow: 0 0 3px 0 rgba(51, 153, 255, 0.5); }
          .student-reports-container .student-reports-list .folding-report-item .report-list-body .report-list-block .report-inner-block .status .red {
            background: red; }
          .student-reports-container .student-reports-list .folding-report-item .report-list-body .report-list-block .report-inner-block .status .green {
            background: green; }
        .student-reports-container .student-reports-list .folding-report-item .report-list-body .report-list-block .report-inner-block .link {
          width: 32%;
          text-align: left; }
        .student-reports-container .student-reports-list .folding-report-item .report-list-body .report-list-block .report-inner-block a {
          display: inline-block;
          margin-right: 20px;
          width: 78px;
          height: 26px;
          border: solid 1px #5279cf;
          border-radius: 4px;
          color: #5279cf;
          text-align: center;
          line-height: 25px; }
    .student-reports-container .student-reports-list .folding-report-item .report-list-body .no-border-bottom {
      border-bottom: none; }

.modal-score-form-container {
  padding: 8px; }
  .modal-score-form-container .ant-row {
    margin-bottom: 0; }
  .modal-score-form-container .ant-table-bordered table {
    border-radius: 0; }
  .modal-score-form-container .ant-table-bordered .ant-table-thead tr th {
    background: #fff;
    text-align: center;
    color: #666; }
  .modal-score-form-container .ant-table-bordered .ant-modal-body tr td {
    text-align: center;
    color: #282828; }
  .modal-score-form-container .exam-score td, .modal-score-form-container .exam-score th {
    text-align: center; }
  .modal-score-form-container .student-info,
  .modal-score-form-container .test-score {
    padding: 4px;
    text-align: center;
    font-size: 14px;
    background: #f7f7f7;
    border: 1px solid #ddd;
    border-bottom: none;
    font-weight: 600; }
  .modal-score-form-container .test-score {
    margin-top: 30px; }

.modal-task-list-container .ant-modal-header {
  color: #666; }

.modal-task-list-container .ant-modal-body {
  padding: 24px 48px;
  color: #282828; }

.modal-task-list-container .ant-modal-footer button:first-child {
  display: none; }

.operation-btn, .ant-btn {
  font-size: 12px; }
