.component-school-modal-selector-task {
  width: 800px;
  height: 400px;
  padding: 20px;
  padding-bottom: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column; }
  .component-school-modal-selector-task > .filter > .row {
    margin-top: 15px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center; }
    .component-school-modal-selector-task > .filter > .row:first-child {
      margin-top: 0; }
    .component-school-modal-selector-task > .filter > .row .component-common-form-select {
      -webkit-box-flex: 1;
      -webkit-flex: 1;
      -moz-box-flex: 1;
      -moz-flex: 1;
      -ms-flex: 1;
      flex: 1; }
      .component-school-modal-selector-task > .filter > .row .component-common-form-select:nth-child(2) {
        margin-left: 15px; }
    .component-school-modal-selector-task > .filter > .row .component-common-form-input,
    .component-school-modal-selector-task > .filter > .row .component-common-form-datetime {
      -webkit-box-flex: 1;
      -webkit-flex: 1;
      -moz-box-flex: 1;
      -moz-flex: 1;
      -ms-flex: 1;
      flex: 1; }
    .component-school-modal-selector-task > .filter > .row .search {
      margin-left: 15px;
      color: #fff;
      background: #39f;
      height: 33px;
      width: 120px;
      line-height: 33px;
      text-align: center;
      border-radius: 2px;
      cursor: pointer;
      font-size: 13px; }
      .component-school-modal-selector-task > .filter > .row .search:hover {
        background: #039be0; }
    .component-school-modal-selector-task > .filter > .row .duration {
      width: 372.5px;
      margin-left: 15px;
      border: 1px solid #d9d9d9;
      border-radius: 2px;
      background: #f9f9f9;
      line-height: 35px;
      padding: 0 15px;
      font-size: 13px; }
  .component-school-modal-selector-task .component-common-form {
    margin-top: 15px;
    overflow: auto;
    box-shadow: none;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -moz-box-flex: 1;
    -moz-flex: 1;
    -ms-flex: 1;
    flex: 1; }
