.req-info {
  padding: 0;
  margin: 0;
  position: relative; }
  .req-info .teaching-req-table h3 {
    margin-left: 0 !important; }
  .req-info .teaching-req-table .product-title {
    margin: 24px 0 16px 0; }
  .req-info .teaching-req-table table {
    border: 1px solid #eee; }
    .req-info .teaching-req-table table thead {
      background: #f7f7f7; }
      .req-info .teaching-req-table table thead th {
        border-right: 1px solid #eee; }
        .req-info .teaching-req-table table thead th:last-child {
          border-right: none; }
    .req-info .teaching-req-table table tr {
      border-bottom: 1px solid #eee; }
      .req-info .teaching-req-table table tr td {
        border-right: 1px solid #eee; }
        .req-info .teaching-req-table table tr td:last-child {
          border-right: none; }
  .req-info .see-class {
    margin-top: 66px !important;
    padding-bottom: 15px;
    border-bottom: 1px solid #eee; }
    .req-info .see-class .span-see-class {
      line-height: 30px;
      font-size: 16px;
      font-weight: 600;
      line-height: 1.12;
      color: #282828; }
    .req-info .see-class .buttons {
      display: inline-block;
      width: 104px;
      height: 28px;
      border-radius: 4px;
      background: #355AA0;
      text-align: center;
      line-height: 28px; }
      .req-info .see-class .buttons a {
        color: #fff !important;
        font-size: 12px;
        margin: 0 !important; }
  .req-info .choose-student {
    font-size: 12px; }
  .req-info .back-list {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 58px;
    line-height: 58px;
    margin: 0;
    padding-left: 32px;
    border: 1px solid #f7f7f7;
    font-size: 12px;
    cursor: pointer; }
  .req-info .searchClass {
    padding: 35px 0 10px 0;
    font-weight: 500;
    border-bottom: 1px solid #eee;
    display: flex;
    justify-content: space-between; }
    .req-info .searchClass .class-change-see {
      font-weight: 600;
      font-size: 16px; }
  .req-info .changeClass {
    margin-top: 20px; }
  .req-info > div {
    background-color: #fff; }
  .req-info .h3 {
    font-size: 14px;
    padding-left: 10px;
    margin-left: 15px !important;
    line-height: 15px;
    font-weight: 600;
    border-left: 5px solid #39f;
    margin: 50px 0 20px 0; }
  .req-info .require-class {
    margin-left: 0 !important;
    margin-top: 30px; }
  .req-info .input-wrap {
    padding: 10px 20px; }
    .req-info .input-wrap .input-text {
      width: 100%; }
  .req-info .info {
    margin-left: 15px;
    font-size: 12px; }
  .req-info .info-dis {
    margin-left: 0; }
  .req-info .input-text {
    position: relative;
    display: inline-block;
    padding: 4px 7px;
    width: 60%;
    height: 80px;
    cursor: text;
    font-size: 14px;
    line-height: 1.5;
    color: #333;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    resize: none;
    width: 910px; }
  .req-info .component-common-suggest {
    padding-top: 10px; }
  .req-info .table-container {
    padding: 10px 0 10px 30px; }
    .req-info .table-container table {
      border: 1px solid #eee;
      border-radius: 4px; }
      .req-info .table-container table td, .req-info .table-container table th {
        border-right: 1px solid #eee;
        font-size: 12px; }
      .req-info .table-container table td {
        height: 45px; }
    .req-info .table-container thead {
      background: #f7f7f7; }
  .req-info .contract-container {
    padding: 10px 15px; }
    .req-info .contract-container .contract-title {
      position: relative;
      margin-left: -15px;
      line-height: 30px;
      font-weight: 500; }
      .req-info .contract-container .contract-title span:first-child {
        padding-left: 8px;
        border-left: 5px solid #39f; }
      .req-info .contract-container .contract-title .father-box, .req-info .contract-container .contract-title .uncle-box {
        position: relative;
        border: none;
        margin-left: 20px; }
      .req-info .contract-container .contract-title .out {
        position: absolute;
        left: 0px;
        top: -8px;
        display: inline-block;
        height: 40px;
        width: 60px;
        background-size: cover; }
      .req-info .contract-container .contract-title .refund-out {
        position: absolute;
        left: 0px;
        top: -8px;
        display: inline-block;
        height: 40px;
        width: 60px;
        background-size: cover; }
    .req-info .contract-container .product-title {
      line-height: 28px;
      font-size: 12px; }
    .req-info .contract-container .product-container {
      padding-bottom: 10px; }
      .req-info .contract-container .product-container table th,
      .req-info .contract-container .product-container table td {
        font-size: 12px; }

.req-info table,
.ant-modal-body table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  text-align: center;
  overflow: hidden; }
  .req-info table thead,
  .ant-modal-body table thead {
    background-color: #72c5eb; }
  .req-info table td, .req-info table th,
  .ant-modal-body table td,
  .ant-modal-body table th {
    padding: 10px 8px;
    word-break: break-all;
    border-bottom: 1px solid #e9e9e9; }
  .req-info table th,
  .ant-modal-body table th {
    font-weight: 500; }

.mark-red .row:nth-child(n+2) > .item:nth-child(9) {
  color: red; }

.mark-red .row:first-child {
  color: #666;
  font-weight: 600; }

.comment-container textarea {
  resize: none; }

.comment-container .comment {
  margin-bottom: 20px;
  background-color: #fff;
  padding: 20px; }

.comment-container .comment + .ant-row {
  background-color: #fff; }

.comment-container form {
  background-color: transparent; }

.req-info .buttons,
.comment-container .buttons {
  padding: 16px 18px 16px 38px;
  text-align: center; }
  .req-info .buttons .back,
  .comment-container .buttons .back {
    background: #fff;
    border: 1px solid #d9d9d9; }
  .req-info .buttons .submit,
  .comment-container .buttons .submit {
    color: #fff;
    background: #355AA0;
    border: 0; }
  .req-info .buttons .button,
  .comment-container .buttons .button {
    display: inline-block;
    padding: 0 12px;
    width: 60px;
    height: 30px;
    cursor: pointer;
    font-size: 13px;
    color: #333;
    border-radius: 4px; }
    .req-info .buttons .button + .button,
    .comment-container .buttons .button + .button {
      margin-left: 18px;
      background-color: #355AA0;
      color: #fff; }
    .req-info .buttons .button.primary,
    .comment-container .buttons .button.primary {
      background-color: #355AA0;
      color: #fff; }

.change-class-detail-modal .ant-modal-body table {
  border-radius: none !important; }

.change-class-detail-modal .disable-reason {
  width: 424px;
  height: 120px;
  margin-left: 32px;
  margin-top: 10px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px; }

.change-class-detail-modal .before-change,
.change-class-detail-modal .after-change {
  text-align: center;
  background: #f7f7f7;
  border: 1px solid #eee; }

.change-class-detail-modal .after-change {
  margin-top: 15px; }

.change-class-detail-modal .before-change-info, .change-class-detail-modal .after-change-info {
  background: none !important; }

.change-class-detail-modal .info-table {
  border-left: 1px solid #eee; }

.change-class-detail-modal .info-table th,
.change-class-detail-modal .info-table td {
  border-right: 1px solid #eee; }

.change-class-detail-modal .ant-checkbox-wrapper {
  margin-left: 32px; }

.change-class-detail-modal .ant-form-item-control-wrapper {
  width: 332px;
  display: inline-block;
  color: #000;
  font-size: 12px; }

.change-class-detail-modal .ant-form-item-control-wrapper .ant-select-selection--single {
  font-size: 12px;
  color: #000; }

.change-class-detail-modal .ant-select-lg .ant-select-selection--single {
  width: 332px; }

.change-class-detail-modal .disable-input {
  background: #fff !important;
  border: 0 !important; }

.change-class-detail-modal .ant-form-item-label {
  width: 132px !important; }

.change-class-detail-modal .ant-modal-title {
  font-size: 14px; }

.change-class-detail-modal .ant-modal-footer {
  width: 100%; }

.change-class-detail-modal .class-change {
  height: 165px; }

.change-class-detail-modal .ant-col-24 {
  height: 50px; }

.change-class-detail-modal .ant-form-horizontal {
  margin-top: 15px; }

.change-class-detail-modal .every-week {
  height: 200px; }

.order-class-detail-modal .ant-modal-content {
  position: relative;
  min-height: 320px;
  padding-bottom: 50px; }

.order-class-detail-modal .ant-modal-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%; }

.order-class-detail-modal .ant-modal-body {
  padding: 24px; }
  .order-class-detail-modal .ant-modal-body table {
    border: 1px solid #eee; }
    .order-class-detail-modal .ant-modal-body table thead {
      background: #f7f7f7; }
    .order-class-detail-modal .ant-modal-body table tr {
      border-bottom: 1px solid #eee; }
      .order-class-detail-modal .ant-modal-body table tr th,
      .order-class-detail-modal .ant-modal-body table tr td {
        border-right: 1px solid #eee; }
        .order-class-detail-modal .ant-modal-body table tr th:last-child,
        .order-class-detail-modal .ant-modal-body table tr td:last-child {
          border-right: none; }

.order-class-detail-modal .ant-btn-lg {
  height: 32px;
  font-size: 14px; }

.go-back {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 58px;
  padding-left: 32px;
  line-height: 58px;
  border-bottom: 1px solid #eee;
  font-size: 12px;
  cursor: pointer; }

table th, table td {
  font-size: 12px; }
