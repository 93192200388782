.student-select .ant-select-dropdown-menu-item {
  font-size: 12px; }

.student-select .ant-select-dropdown-menu-item-active,
.student-select .ant-select-dropdown-menu-item-select:hover,
.student-select .ant-dropdown-menu-item:hover,
.student-select .ant-dropdown-menu-item-selected,
.student-select .ant-select-dropdown-menu-item:hover {
  background-color: #f6f8f9;
  color: #29323a;
  font-size: 12px; }
  .student-select .ant-select-dropdown-menu-item-active a,
  .student-select .ant-select-dropdown-menu-item-select:hover a,
  .student-select .ant-dropdown-menu-item:hover a,
  .student-select .ant-dropdown-menu-item-selected a,
  .student-select .ant-select-dropdown-menu-item:hover a {
    background-color: #f6f8f9;
    color: #29323a; }

.student-select .ant-dropdown-menu-item-select a {
  background-color: #f6f8f9;
  color: #29323a; }

.student-body table tr:hover {
  background: #fbfcfd !important; }
