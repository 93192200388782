.smart-adaptation-getting-started {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #fff;
  margin-top: 20px; }
  .smart-adaptation-getting-started .skip {
    position: absolute;
    top: 20px;
    right: 32px;
    color: #39f;
    cursor: pointer; }
  .smart-adaptation-getting-started .inner {
    width: 100%;
    margin: 0 auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center; }
    .smart-adaptation-getting-started .inner div {
      display: flex;
      flex-direction: column;
      align-items: center; }
      .smart-adaptation-getting-started .inner div img {
        width: 300px;
        height: 240px;
        margin-bottom: 12px;
        background: transparent; }
        .smart-adaptation-getting-started .inner div img + p {
          margin-bottom: 0;
          font-size: 14px;
          color: #3c3c3c; }
      .smart-adaptation-getting-started .inner div .create-profile {
        margin: 60px 0 20px 0;
        display: block;
        width: 160px;
        height: 32px;
        font-size: 12px;
        background: linear-gradient(-90deg, #ffc616, #ff8f09);
        box-shadow: 0px 2px 10px 0px rgba(247, 175, 37, 0.3);
        border-radius: 20px;
        color: #fff;
        border: none;
        outline: none;
        cursor: pointer; }
        .smart-adaptation-getting-started .inner div .create-profile + a {
          font-size: 12px; }
  .smart-adaptation-getting-started .unplanned-container {
    width: 600px;
    height: 450px;
    margin: 20px auto;
    display: block;
    text-align: center; }
    .smart-adaptation-getting-started .unplanned-container .title {
      text-align: center;
      font-size: 18px;
      color: #29323a; }
      .smart-adaptation-getting-started .unplanned-container .title:last-of-type {
        margin-top: 10px; }
    .smart-adaptation-getting-started .unplanned-container img {
      margin-top: 10px;
      width: 221px; }
    .smart-adaptation-getting-started .unplanned-container .tip {
      margin-top: 10px;
      height: 17px;
      font-size: 12px;
      line-height: 17px;
      color: #818C96; }
    .smart-adaptation-getting-started .unplanned-container button {
      display: block;
      margin: 10px 0 20px 220px;
      width: 160px;
      height: 40px;
      background: linear-gradient(-90deg, #ffc616, #ff8f09);
      box-shadow: 0px 2px 10px 0px rgba(247, 175, 37, 0.3);
      border: 0;
      border-radius: 20px;
      color: #fff;
      font-size: 14px; }
      .smart-adaptation-getting-started .unplanned-container button:hover {
        opacity: 0.75; }
      .smart-adaptation-getting-started .unplanned-container button + a {
        font-size: 12px; }
    .smart-adaptation-getting-started .unplanned-container:div {
      display: flex;
      align-items: center; }

.eTpeTG {
  display: none !important; }

.reactour__helper {
  border-radius: 4px !important; }

.tour-button {
  display: inline-block;
  width: 65px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  border: 1px solid #caccce;
  border-radius: 16px; }

.hhDKZp {
  display: none !important; }

.jrBYqH {
  justify-content: flex-end; }

.sc-htpNat {
  margin: 0 0 0 10px !important; }

.hvBjrP {
  display: none !important; }

.unhandle {
  margin-top: 0;
  display: flex;
  align-items: center; }

.entrance-testing-modal-content p {
  margin-left: 24px;
  text-align: left;
  color: #29323a; }
