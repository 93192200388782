.student-archives-container .filter-container .filter-item {
  display: inline-block;
  margin-right: 20px;
  font-size: 12px; }
  .student-archives-container .filter-container .filter-item .title {
    padding-right: 10px; }

.student-archives-container .no-students {
  margin-top: 70px;
  text-align: center; }

.student-archives-container .tabs-container {
  margin-top: 10px;
  margin-bottom: 30px; }

.student-archives-container .detail-container .basic-info .basic-info-container .basic-info-fields-container .edit-container {
  text-align: right;
  margin-bottom: 30px;
  margin-top: 6px; }
  .student-archives-container .detail-container .basic-info .basic-info-container .basic-info-fields-container .edit-container button {
    height: 28px;
    margin-left: 4px; }

.student-archives-container .detail-container .basic-info .basic-info-container .basic-info-fields-container .area-code-container {
  height: 30px;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  line-height: 30px;
  border-radius: 2px; }

.student-archives-container .detail-container .basic-info .basic-info-container .basic-info-fields-container .fileds-container label, .student-archives-container .detail-container .basic-info .basic-info-container .basic-info-fields-container .fileds-container span {
  font-size: 12px; }

.student-archives-container .detail-container .basic-info .basic-info-container .basic-info-fields-container .fileds-container label {
  color: rgba(0, 0, 0, 0.65); }

.student-archives-container .detail-container .basic-info .basic-info-container .basic-info-fields-container .fileds-container .ant-form .ant-form-item {
  margin-bottom: 30px; }
  .student-archives-container .detail-container .basic-info .basic-info-container .basic-info-fields-container .fileds-container .ant-form .ant-form-item .ant-form-item-control .area-code-container {
    margin-top: 0;
    overflow: hidden; }
    .student-archives-container .detail-container .basic-info .basic-info-container .basic-info-fields-container .fileds-container .ant-form .ant-form-item .ant-form-item-control .area-code-container .ant-input {
      border-radius: 0;
      border: 0;
      border-left: 1px solid #ddd; }
      .student-archives-container .detail-container .basic-info .basic-info-container .basic-info-fields-container .fileds-container .ant-form .ant-form-item .ant-form-item-control .area-code-container .ant-input:focus {
        border-color: white; }
    .student-archives-container .detail-container .basic-info .basic-info-container .basic-info-fields-container .fileds-container .ant-form .ant-form-item .ant-form-item-control .area-code-container .ant-input-group-addon {
      padding: 0;
      border-radius: 0;
      border: 0; }
    .student-archives-container .detail-container .basic-info .basic-info-container .basic-info-fields-container .fileds-container .ant-form .ant-form-item .ant-form-item-control .area-code-container .area-code-select-container {
      width: 33px; }
      .student-archives-container .detail-container .basic-info .basic-info-container .basic-info-fields-container .fileds-container .ant-form .ant-form-item .ant-form-item-control .area-code-container .area-code-select-container .area-code-select {
        width: 280px;
        height: 28px;
        left: 3px;
        top: -9px; }

.student-archives-container .detail-container .basic-info .basic-info-container .basic-info-fields-container .fileds-container .ant-form .required label:before {
  display: inline-block;
  margin-right: 4px;
  content: "*";
  line-height: 1;
  font-size: 18px;
  color: #f04134;
  vertical-align: middle; }

.student-archives-container .detail-container .basic-info .basic-info-container .basic-info-fields-container .footer-btns {
  text-align: center; }
  .student-archives-container .detail-container .basic-info .basic-info-container .basic-info-fields-container .footer-btns button {
    width: 80px;
    height: 28px;
    margin: 0 10px; }

.student-archives-container .detail-container .course-product-info .course-product-info-container .changing-class-container {
  height: 28px;
  line-height: 28px;
  color: #333;
  display: flex;
  justify-content: space-between; }
  .student-archives-container .detail-container .course-product-info .course-product-info-container .changing-class-container > div {
    display: inline-block;
    height: 100%;
    font-size: 12px; }
    .student-archives-container .detail-container .course-product-info .course-product-info-container .changing-class-container > div label {
      color: #666; }
  .student-archives-container .detail-container .course-product-info .course-product-info-container .changing-class-container .count3 .red {
    color: #f63e3e; }
  .student-archives-container .detail-container .course-product-info .course-product-info-container .changing-class-container .count5 button {
    float: right; }

.student-archives-container .detail-container .course-product-info .course-product-info-container .course-product-info-blocks-container {
  margin-top: 20px; }
  .student-archives-container .detail-container .course-product-info .course-product-info-container .course-product-info-blocks-container .course-product-info-blocks-header {
    padding: 0 15px 0 10px;
    height: 40px;
    line-height: 40px;
    background: #f7f7f7;
    color: #333;
    font-weight: 600;
    font-size: 12px;
    cursor: pointer; }
    .student-archives-container .detail-container .course-product-info .course-product-info-container .course-product-info-blocks-container .course-product-info-blocks-header .order-span {
      margin-right: 20px; }
    .student-archives-container .detail-container .course-product-info .course-product-info-container .course-product-info-blocks-container .course-product-info-blocks-header .switch-span {
      float: right;
      font-weight: normal; }
      .student-archives-container .detail-container .course-product-info .course-product-info-container .course-product-info-blocks-container .course-product-info-blocks-header .switch-span i {
        margin-left: 6px; }
  .student-archives-container .detail-container .course-product-info .course-product-info-container .course-product-info-blocks-container .course-product-info-blocks-buttons {
    text-align: right; }
  .student-archives-container .detail-container .course-product-info .course-product-info-container .course-product-info-blocks-container .course-product-info-blocks-item {
    padding-left: 10px; }
    .student-archives-container .detail-container .course-product-info .course-product-info-container .course-product-info-blocks-container .course-product-info-blocks-item .first-title {
      margin-top: 30px; }
    .student-archives-container .detail-container .course-product-info .course-product-info-container .course-product-info-blocks-container .course-product-info-blocks-item .title {
      margin-bottom: 20px;
      height: 17px;
      font-size: 12px;
      font-weight: 600;
      color: #3559a0; }
    .student-archives-container .detail-container .course-product-info .course-product-info-container .course-product-info-blocks-container .course-product-info-blocks-item .detail-container {
      display: flex; }
      .student-archives-container .detail-container .course-product-info .course-product-info-container .course-product-info-blocks-container .course-product-info-blocks-item .detail-container > div {
        width: 25%;
        display: inline-block;
        font-size: 12px; }
        .student-archives-container .detail-container .course-product-info .course-product-info-container .course-product-info-blocks-container .course-product-info-blocks-item .detail-container > div p {
          margin-bottom: 20px;
          color: #333; }
          .student-archives-container .detail-container .course-product-info .course-product-info-container .course-product-info-blocks-container .course-product-info-blocks-item .detail-container > div p .label {
            color: #666; }
          .student-archives-container .detail-container .course-product-info .course-product-info-container .course-product-info-blocks-container .course-product-info-blocks-item .detail-container > div p span {
            width: 92px;
            display: inline-block;
            text-align: right; }
          .student-archives-container .detail-container .course-product-info .course-product-info-container .course-product-info-blocks-container .course-product-info-blocks-item .detail-container > div p .red {
            color: #f63e3e; }
      .student-archives-container .detail-container .course-product-info .course-product-info-container .course-product-info-blocks-container .course-product-info-blocks-item .detail-container .hours p span {
        width: 130px; }

.student-archives-container .detail-container .course-product-info .course-product-info-container .footer-btns {
  margin-top: 20px;
  text-align: center; }
  .student-archives-container .detail-container .course-product-info .course-product-info-container .footer-btns button {
    width: 80px;
    height: 28px;
    margin: 0 10px; }

.student-archives-container .detail-container .course-product-info .no-product {
  margin-top: 70px;
  text-align: center; }

.student-archives-container .detail-container .beikao-info .beikao-info-container .add-container {
  text-align: right;
  margin-bottom: 20px;
  margin-top: 6px; }
  .student-archives-container .detail-container .beikao-info .beikao-info-container .add-container button {
    width: 100px;
    height: 28px; }

.student-archives-container .detail-container .beikao-info .beikao-info-container .no-beikao {
  margin-top: 70px;
  text-align: center; }

.student-archives-container .detail-container .beikao-info .beikao-info-container .beikao-info-item-container {
  margin-bottom: 20px; }
  .student-archives-container .detail-container .beikao-info .beikao-info-container .beikao-info-item-container .beikao-info-item-header {
    padding: 0 15px 0 10px;
    height: 40px;
    line-height: 40px;
    background: #f7f7f7;
    color: #333;
    font-weight: 600;
    font-size: 12px;
    cursor: pointer; }
    .student-archives-container .detail-container .beikao-info .beikao-info-container .beikao-info-item-container .beikao-info-item-header .order-span {
      margin-right: 20px; }
    .student-archives-container .detail-container .beikao-info .beikao-info-container .beikao-info-item-container .beikao-info-item-header .switch-span {
      float: right;
      font-weight: normal; }
      .student-archives-container .detail-container .beikao-info .beikao-info-container .beikao-info-item-container .beikao-info-item-header .switch-span i {
        margin-left: 6px; }
  .student-archives-container .detail-container .beikao-info .beikao-info-container .beikao-info-item-container .basic-info .header,
  .student-archives-container .detail-container .beikao-info .beikao-info-container .beikao-info-item-container .score-manage .header {
    margin: 25px 0 30px 0;
    padding: 0 0 10px 10px;
    padding-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e9e9e9; }
    .student-archives-container .detail-container .beikao-info .beikao-info-container .beikao-info-item-container .basic-info .header .title,
    .student-archives-container .detail-container .beikao-info .beikao-info-container .beikao-info-item-container .score-manage .header .title {
      width: 48px;
      height: 17px;
      font-size: 12px;
      font-weight: 600;
      color: #3559a0; }
  .student-archives-container .detail-container .beikao-info .beikao-info-container .beikao-info-item-container .basic-info .table-container .ant-table-wrapper .operations a,
  .student-archives-container .detail-container .beikao-info .beikao-info-container .beikao-info-item-container .score-manage .table-container .ant-table-wrapper .operations a {
    margin-right: 20px; }
  .student-archives-container .detail-container .beikao-info .beikao-info-container .beikao-info-item-container .basic-info .basic-info-detail,
  .student-archives-container .detail-container .beikao-info .beikao-info-container .beikao-info-item-container .score-manage .basic-info-detail {
    margin-bottom: 23px; }
    .student-archives-container .detail-container .beikao-info .beikao-info-container .beikao-info-item-container .basic-info .basic-info-detail .item,
    .student-archives-container .detail-container .beikao-info .beikao-info-container .beikao-info-item-container .score-manage .basic-info-detail .item {
      padding-right: 30px;
      font-size: 12px; }
  .student-archives-container .detail-container .beikao-info .beikao-info-container .beikao-info-item-container .score-info {
    margin-bottom: 30px;
    font-size: 12px; }
    .student-archives-container .detail-container .beikao-info .beikao-info-container .beikao-info-item-container .score-info span {
      padding-right: 20px;
      font-size: 12px; }

.student-archives-container .detail-container .arrange-info .head {
  width: 100%;
  height: 40px;
  border-bottom: 1px solid #e9e9e9; }
  .student-archives-container .detail-container .arrange-info .head h5 {
    height: 40px;
    line-height: 40px;
    padding-left: 10px;
    float: left;
    font-size: 12px;
    color: #3559a0; }
  .student-archives-container .detail-container .arrange-info .head .edit-container {
    float: right; }

.student-archives-container .detail-container .arrange-info .footer-btns {
  text-align: center; }
  .student-archives-container .detail-container .arrange-info .footer-btns button:first-child {
    margin-right: 40px; }

.student-archives-container .detail-container .arrange-info .student-arrange-info .intruction {
  margin: 20px 0 10px 10px; }
  .student-archives-container .detail-container .arrange-info .student-arrange-info .intruction .title {
    font-size: 12px;
    font-weight: 600; }
  .student-archives-container .detail-container .arrange-info .student-arrange-info .intruction .intruction-content {
    padding-top: 10px;
    font-size: 12px; }
    .student-archives-container .detail-container .arrange-info .student-arrange-info .intruction .intruction-content textarea {
      width: 100%;
      padding: 5px;
      border-color: #d9d9d9;
      border-radius: 4px;
      color: #333; }

.student-archives-container .detail-container .arrange-info .servicePT {
  margin-top: 20px;
  font-size: 12px; }
  .student-archives-container .detail-container .arrange-info .servicePT .selectServicePT {
    padding: 20px 0 0 10px; }
  .student-archives-container .detail-container .arrange-info .servicePT .select {
    width: 240px; }

.student-archives-container .detail-container .arrange-info .teachPT {
  margin-top: 20px;
  font-size: 12px; }
  .student-archives-container .detail-container .arrange-info .teachPT .selectTeachPT {
    padding: 20px 0 0 10px; }
    .student-archives-container .detail-container .arrange-info .teachPT .selectTeachPT .status {
      display: flex;
      margin-bottom: 20px; }
      .student-archives-container .detail-container .arrange-info .teachPT .selectTeachPT .status h5 {
        width: 180px; }
    .student-archives-container .detail-container .arrange-info .teachPT .selectTeachPT .total {
      display: inline-block;
      width: 80px;
      text-align: right; }
    .student-archives-container .detail-container .arrange-info .teachPT .selectTeachPT .productInfo {
      display: flex;
      margin-bottom: 15px; }
      .student-archives-container .detail-container .arrange-info .teachPT .selectTeachPT .productInfo > div {
        width: 300px; }
    .student-archives-container .detail-container .arrange-info .teachPT .selectTeachPT .info {
      display: flex; }
      .student-archives-container .detail-container .arrange-info .teachPT .selectTeachPT .info > div {
        display: flex;
        width: 300px;
        margin-bottom: 15px; }
        .student-archives-container .detail-container .arrange-info .teachPT .selectTeachPT .info > div .subject {
          width: 85px;
          text-align: right; }
  .student-archives-container .detail-container .arrange-info .teachPT .select {
    width: 240px;
    clear: both; }

.student-archives-container .detail-container .recommend-liuxue {
  height: 500px; }

.add-beikao-subject-container .ant-modal {
  width: 709px !important; }
  .add-beikao-subject-container .ant-modal .ant-modal-content .ant-modal-body .ant-row {
    margin-bottom: 30px; }
  .add-beikao-subject-container .ant-modal .ant-modal-content .ant-modal-body .history-score .history-score-inner {
    display: flex;
    font-size: 12px; }
    .add-beikao-subject-container .ant-modal .ant-modal-content .ant-modal-body .history-score .history-score-inner > label {
      display: inline-block;
      width: 110px;
      margin-top: 4px;
      text-align: right; }
    .add-beikao-subject-container .ant-modal .ant-modal-content .ant-modal-body .history-score .history-score-inner .score-container {
      width: 560px;
      display: inline-block; }
      .add-beikao-subject-container .ant-modal .ant-modal-content .ant-modal-body .history-score .history-score-inner .score-container .header label {
        display: inline-block;
        margin-right: 10px; }
      .add-beikao-subject-container .ant-modal .ant-modal-content .ant-modal-body .history-score .history-score-inner .score-container .header input {
        margin-right: 10px;
        width: 53px; }
      .add-beikao-subject-container .ant-modal .ant-modal-content .ant-modal-body .history-score .history-score-inner .score-container .body .cell {
        display: inline-block;
        margin-right: 30px;
        margin-top: 10px; }
        .add-beikao-subject-container .ant-modal .ant-modal-content .ant-modal-body .history-score .history-score-inner .score-container .body .cell label {
          display: inline-block;
          margin-right: 10px; }
        .add-beikao-subject-container .ant-modal .ant-modal-content .ant-modal-body .history-score .history-score-inner .score-container .body .cell input {
          margin-right: 10px;
          width: 53px; }
  .add-beikao-subject-container .ant-modal .examination-select {
    display: flex; }
    .add-beikao-subject-container .ant-modal .examination-select label:not(.ant-checkbox-wrapper) {
      width: 110px;
      display: inline-block;
      text-align: right; }
    .add-beikao-subject-container .ant-modal .examination-select .ant-select, .add-beikao-subject-container .ant-modal .examination-select .ant-calendar-picker, .add-beikao-subject-container .ant-modal .examination-select .ant-input {
      width: 180px; }
    .add-beikao-subject-container .ant-modal .examination-select .ant-upload-list-picture {
      width: 460px; }
    .add-beikao-subject-container .ant-modal .examination-select .ant-checkbox-group {
      width: 250px;
      display: inline-block; }
    .add-beikao-subject-container .ant-modal .examination-select .other-weakness {
      display: inline-block; }
  .add-beikao-subject-container .ant-modal .with-margin .ant-upload-list-picture {
    width: 460px;
    margin-left: 110px; }
  .add-beikao-subject-container .ant-modal .upload-container {
    display: inline-block; }
    .add-beikao-subject-container .ant-modal .upload-container .ant-upload-select-picture-card {
      display: inline-block; }
      .add-beikao-subject-container .ant-modal .upload-container .ant-upload-select-picture-card > span > div {
        margin: 20px 0 0 25px; }
  .add-beikao-subject-container .ant-modal .modal-score-container {
    display: flex; }
    .add-beikao-subject-container .ant-modal .modal-score-container .file-name {
      margin-right: 15px; }

.add-product-modal .modal-item {
  width: 100%;
  margin: 10px 0;
  padding: 0 10%;
  display: flex;
  align-items: center; }
  .add-product-modal .modal-item > span {
    width: 20%;
    text-align: left; }
    .add-product-modal .modal-item > span + * {
      width: 80%; }

.watch-img {
  width: 70% !important; }

.add-course-product-modal .select-option-div {
  display: flex; }
  .add-course-product-modal .select-option-div span {
    width: 50%;
    text-align: center; }
