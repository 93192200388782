.component-common-list {
  display: block;
  padding: 0;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.05) 0 0 1px 1px;
  overflow: hidden;
  color: #333; }
  .component-common-list:before, .component-common-list:after {
    content: ' ';
    display: table;
    clear: both; }
  .component-common-list .row {
    border-top: 1px solid #eee;
    transition: background .15s;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center; }
    .component-common-list .row:before, .component-common-list .row:after {
      content: ' ';
      display: table;
      clear: both; }
    .component-common-list .row:first-child {
      font-weight: bold;
      border-top: none; }
      .component-common-list .row:first-child:hover {
        background: #fff; }
    .component-common-list .row:hover {
      background: #fbfbfb; }
    .component-common-list .row .item {
      float: left;
      padding: 10px 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center;
      line-height: 2.2; }
      .component-common-list .row .item:last-child {
        -webkit-box-flex: 1;
        -webkit-flex: 1;
        -moz-box-flex: 1;
        -moz-flex: 1;
        -ms-flex: 1;
        flex: 1;
        border-right: none; }
      .component-common-list .row .item .button {
        color: #39f;
        margin-left: 10px;
        cursor: pointer;
        margin-right: 5px; }
        .component-common-list .row .item .button:first-child {
          margin-left: 0; }
      .component-common-list .row .item img {
        display: block;
        width: 30px;
        height: 30px;
        border-radius: 30px;
        margin: 0 auto; }
  .component-common-list .row:not(:first-child) .item:first-child {
    font-weight: 600; }
  .component-common-list .empty {
    margin: 25px;
    text-align: center; }
    .component-common-list .empty .fa-frown-o {
      font-size: 28px;
      margin-bottom: 10px;
      color: #555; }
    .component-common-list .empty span {
      display: block;
      text-align: center; }

.component-common-list-with-image .row {
  height: 60px;
  line-height: 32px;
  overflow: hidden; }
