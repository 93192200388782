.student-course-hours .filter {
  padding-bottom: 15px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.0470588) 0 1px 1px 0;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px; }

.student-course-hours .filter-item {
  display: flex;
  flex-wrap: wrap;
  margin-right: 30px; }
  .student-course-hours .filter-item > label {
    padding: 7px 0;
    display: inline-block; }
  .student-course-hours .filter-item > div {
    padding: 3px; }
  .student-course-hours .filter-item:last-child {
    width: 200px; }
    .student-course-hours .filter-item:last-child .ant-input-affix-wrapper {
      width: 170px !important; }
    .student-course-hours .filter-item:last-child .ant-input-group-addon {
      background: #fff; }

.student-course-hours .container-main {
  background-color: #fff; }
  .student-course-hours .container-main .table-name {
    font-weight: 600; }
  .student-course-hours .container-main .getout-excel {
    position: absolute;
    top: 0;
    right: 0;
    height: 28px;
    width: 100px;
    text-align: center;
    line-height: 28px;
    border-radius: 4px; }
  .student-course-hours .container-main .show-complete {
    position: absolute;
    top: 0;
    left: 450px; }
  .student-course-hours .container-main .ant-table-fixed-right {
    box-shadow: -6px 0 6px -4px rgba(0, 0, 0, 0.2) !important; }

.student-course-hours .ant-table-fixed-left .ant-table-body-outer .ant-table-fixed {
  border: none; }
  .student-course-hours .ant-table-fixed-left .ant-table-body-outer .ant-table-fixed .ant-table-tbody tr td {
    border-right: none;
    border-left: none; }

.student-course-hours .ant-table > table {
  position: relative; }

.student-course-hours .ant-table table {
  border-collapse: collapse; }

.student-course-hours .ant-table td, .student-course-hours .ant-table th {
  border: 1px solid #e9e9e9;
  padding: 8px; }

.fix-head .admin-time-des {
  position: fixed;
  top: 52px;
  width: calc(100% - 264px);
  z-index: 5; }

.fix-head .classroom-table table {
  padding-top: 50px; }

.fix-head .classroom-table .ant-table-thead {
  position: fixed;
  top: 116px;
  width: calc(100% - 265px);
  z-index: 5; }
  .fix-head .classroom-table .ant-table-thead tr {
    display: flex;
    width: 100%; }
    .fix-head .classroom-table .ant-table-thead tr th {
      display: inline-block;
      height: 51px;
      flex-grow: 1;
      width: 50px; }

.fix-head .common-course-schedule .hd {
  position: fixed;
  top: 52px;
  left: 232px;
  right: 32px;
  width: calc(100% - 264px);
  background: #fff;
  z-index: 5; }

.fix-head .common-course-schedule .schedule {
  padding-top: 87px; }

.student-course-hours__modal-wrapper .ant-modal-footer {
  display: none; }
