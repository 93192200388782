.expended-teacher-course-hours .filter {
  padding-bottom: 15px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.0470588) 0 1px 1px 0;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px; }

.expended-teacher-course-hours .filter-item {
  display: flex;
  flex-wrap: wrap;
  margin-right: 30px; }
  .expended-teacher-course-hours .filter-item > label {
    padding: 7px 0;
    display: inline-block; }
  .expended-teacher-course-hours .filter-item > div {
    padding: 3px; }
  .expended-teacher-course-hours .filter-item:last-child {
    width: 200px; }
    .expended-teacher-course-hours .filter-item:last-child .ant-input-affix-wrapper {
      width: 170px !important; }
    .expended-teacher-course-hours .filter-item:last-child .ant-input-group-addon {
      background: #fff; }

.expended-teacher-course-hours .container-main {
  background-color: #fff; }
  .expended-teacher-course-hours .container-main .table-name {
    font-weight: 600; }
  .expended-teacher-course-hours .container-main .getout-excel {
    position: absolute;
    top: 0;
    right: 0;
    height: 28px;
    width: 100px;
    text-align: center;
    line-height: 28px;
    border-radius: 4px; }
  .expended-teacher-course-hours .container-main .show-complete {
    position: absolute;
    top: 0;
    left: 450px; }
  .expended-teacher-course-hours .container-main .ant-table-fixed-right {
    box-shadow: -6px 0 6px -4px rgba(0, 0, 0, 0.2) !important; }

.expended-teacher-course-hours .ant-table > table {
  position: relative; }

.expended-teacher-course-hours .ant-table table {
  border-collapse: collapse; }

.expended-teacher-course-hours .ant-table td, .expended-teacher-course-hours .ant-table th {
  border: 1px solid #e9e9e9;
  padding: 8px; }
