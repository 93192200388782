.dashboard-container {
  background-color: #F6F7FB; }
  .dashboard-container p {
    margin-bottom: 0;
    font-family: PingFangSC-Medium,PingFang SC; }
  .dashboard-container img {
    background-image: none; }
  .dashboard-container .ant-col-6 {
    width: 24% !important; }

.dashboard-head .user-name {
  font-size: 34px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85); }

.dashboard-head .greetings {
  font-size: 16px;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.45); }

.dashboard-head .data-info {
  text-align: right; }

.dashboard-head .update-time {
  position: relative;
  top: -24px;
  color: rgba(0, 0, 0, 0.45); }
  .dashboard-head .update-time .date {
    color: rgba(0, 0, 0, 0.65); }

.summary-fields {
  text-align: right; }

.summary-title {
  font-size: 16px;
  color: #3A3E46; }

.class-count {
  margin-left: 10px;
  font-size: 16px;
  color: #4070CD; }

.summary-item {
  border-radius: 8px;
  display: flex !important;
  justify-content: start;
  align-items: center; }
  .summary-item .svg-icon {
    margin-right: 16px;
    width: 52px;
    height: 52px;
    border-radius: 50%; }
    .summary-item .svg-icon img {
      margin: 10px;
      width: 32px;
      text-align: center;
      vertical-align: middle; }
  .summary-item .count {
    font-size: 28px; }
  .summary-item .title {
    font-size: 14px; }

.task-pie {
  height: 755px;
  border-radius: 8px;
  background-color: #fff; }
  .task-pie h3 {
    margin: 20px 0 0 20px;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 600; }

.animation {
  height: 755px !important;
  transition: 1s all ease-in-out; }
  .animation .data-item {
    text-align: center;
    margin: 5% 0; }

.over-view {
  height: 277px;
  border-radius: 8px;
  background-color: #fff; }
  .over-view h3 {
    padding: 20px 0 0 20px;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 600; }
  .over-view .data-item {
    text-align: center;
    margin-bottom: 20px; }
    .over-view .data-item .value {
      font-size: 16px;
      color: #1d222a;
      text-align: center; }
    .over-view .data-item .title {
      color: rgba(0, 0, 0, 0.65);
      text-align: center; }
  .over-view .data-item__content {
    position: relative; }
  .over-view .data-item__divider {
    position: absolute;
    top: 2px;
    right: 0;
    height: 32px;
    color: #ECF2FF; }

.correct-rate {
  position: relative;
  height: 458px;
  margin-top: 20px;
  border-radius: 8px;
  background-color: #fff;
  top: 0; }
  .correct-rate h3 {
    padding: 20px 0 0 20px;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 600; }

.no-data {
  opacity: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 20;
  animation: fade 1s ease-in-out 1 forwards; }

@keyframes fade {
  to {
    opacity: 1; } }

.height-animation {
  visibility: hidden;
  height: 0 !important;
  transition: 1s all ease-in-out;
  z-index: -1;
  top: -500px; }

.correct-tabs {
  margin-top: 24px;
  background-color: #fff;
  transition: all 1s ease-out; }

.practice {
  padding: 0 24px; }
  .practice .subject-select {
    width: 200px;
    display: flex;
    justify-self: end; }

.tags {
  margin-top: 36px;
  display: flex;
  border-bottom: 1px solid #ccc; }
  .tags .tixing {
    margin-left: 20px;
    color: rgba(0, 0, 0, 0.65);
    padding-bottom: 12px;
    margin-right: 40px;
    cursor: pointer;
    font-size: 16px;
    width: 60px;
    text-align: center; }
  .tags .active {
    color: #4070CD;
    border-bottom: 1px solid #4070CD; }

.subject-text {
  margin: 24px;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.65); }
