.area-code-container {
  margin-top: 18px; }
  .area-code-container .ant-input-group-wrapper {
    display: inline; }
  .area-code-container .ant-input {
    margin-top: 0 !important;
    padding-left: 10px !important;
    outline: none; }
  .area-code-container .ant-input-group {
    border: 1px solid #d9d9d9; }
  .area-code-container .ant-input-group-addon {
    margin-left: 0; }
    .area-code-container .ant-input-group-addon .area-code-select-container {
      width: 72px;
      position: relative;
      font-size: 14px; }
      .area-code-container .ant-input-group-addon .area-code-select-container .country_title span {
        margin-right: 8px; }
      .area-code-container .ant-input-group-addon .area-code-select-container .country_title .anticon {
        font-size: 12px; }
      .area-code-container .ant-input-group-addon .area-code-select-container .area-code-select {
        width: 298px;
        height: 50px;
        position: absolute;
        left: 0;
        top: -12px; }
        .area-code-container .ant-input-group-addon .area-code-select-container .area-code-select .ant-select-selection {
          height: 100%;
          position: relative; }
          .area-code-container .ant-input-group-addon .area-code-select-container .area-code-select .ant-select-selection .ant-select-arrow {
            user-select: none;
            position: absolute;
            left: 60px;
            top: 15px;
            width: 20px; }

.form {
  margin-top: 0; }
  .form .input-collection {
    border: 1px solid #d9d9d9;
    border-radius: 4px; }
    .form .input-collection .password-container {
      margin-top: 10px; }
    .form .input-collection .otehrs-password {
      margin-top: 0; }
    .form .input-collection .ant-select-arrow {
      font-size: 18px; }

.teacher-form .input-collection, .student-form .input-collection {
  border: none; }
  .teacher-form .input-collection > div, .student-form .input-collection > div {
    border: 1px solid #d9d9d9; }

.no-border {
  height: 50px; }
  .no-border .ant-input-group {
    height: 40px; }
    .no-border .ant-input-group .ant-input-group-addon {
      border-radius: 0;
      border: 0;
      border-right: 1px solid #e9e9e9; }
    .no-border .ant-input-group .ant-input {
      float: right;
      height: 49px;
      border-radius: 0;
      border: 0; }

.is-index {
  margin-top: 0;
  height: 51px;
  padding-left: 0;
  background: #fff;
  border-bottom: 1px solid #e9e9e9; }
  .is-index .ant-input-group {
    border: none; }
  .is-index .ant-input-group-addon {
    background: #fff; }

.vip-only {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px; }
  .vip-only .ant-input-group-wrapper .ant-input-group .ant-input-group-addon {
    border-top-left-radius: 4px; }
  .vip-only .ant-input-group-wrapper .ant-input-group .ant-input {
    border-top-right-radius: 4px; }

.ant-select-dropdown .ant-select-dropdown-menu .ant-select-dropdown-menu-item .code {
  display: inline-block;
  width: 78px;
  padding-left: 6px; }

.ant-select-dropdown .ant-select-dropdown-menu .ant-select-dropdown-menu-item .list-item {
  position: absolute;
  top: 0;
  padding: 7px 0 7px 12px;
  border-left: 1px solid #e9e9e9;
  width: 200px;
  overflow-x: scroll; }
  .ant-select-dropdown .ant-select-dropdown-menu .ant-select-dropdown-menu-item .list-item .prefix {
    margin-right: 5px; }
