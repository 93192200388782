.component-common-percent-ring {
  position: relative;
  width: 16px;
  height: 16px;
  box-shadow: 0 0 3px #fff;
  border-radius: 100%; }
  .component-common-percent-ring > div {
    position: absolute; }
  .component-common-percent-ring > div:first-of-type {
    top: 0;
    left: 0;
    width: 8px;
    height: 16px; }
  .component-common-percent-ring > div:nth-of-type(2) {
    top: 0;
    left: 0;
    width: 8px;
    height: 16px; }
  .component-common-percent-ring > div:last-of-type {
    top: 2px;
    left: 2px;
    width: 12px;
    height: 12px;
    background-color: #fff;
    border-radius: 100%; }

.component-common-percent-ring-low {
  background-color: #39f; }
  .component-common-percent-ring-low > div:first-of-type {
    top: 0;
    left: 0;
    background-color: #ddd;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px; }
  .component-common-percent-ring-low > div:nth-of-type(2) {
    top: 0;
    left: 0;
    background-color: #ddd;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    transform-origin: 100% 50%; }

.component-common-percent-ring-high {
  background-color: #ddd; }
  .component-common-percent-ring-high > div:first-of-type {
    top: 0;
    left: 8px;
    background-color: #39f;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px; }
  .component-common-percent-ring-high > div:nth-of-type(2) {
    top: 0;
    left: 8px;
    background-color: #39f;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    transform-origin: 0 50%; }
