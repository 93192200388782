.step-container {
  display: flex;
  justify-content: center; }
  .step-container .step {
    height: 48px;
    width: 140px;
    position: relative;
    opacity: 0.4;
    cursor: pointer; }
    .step-container .step .line {
      position: absolute;
      top: 9px;
      width: 100%;
      height: 2px;
      background: #39f; }
    .step-container .step .name {
      position: absolute;
      left: 46px;
      bottom: 0;
      font-size: 14px;
      color: #39f; }
    .step-container .step .order {
      position: absolute;
      left: 65px;
      width: 20px;
      height: 20px;
      color: white;
      background: #39f;
      border-radius: 99px;
      text-align: center; }
  .step-container .active {
    opacity: 1; }
