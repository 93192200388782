.smart-adaptation-preparing-subject {
  font-family: PingFangSC;
  font-size: 16px;
  font-weight: 600;
  display: inline-block; }
  .smart-adaptation-preparing-subject > .title {
    width: 100%;
    margin-bottom: 21px;
    border-radius: 4px;
    position: relative;
    font-family: PingFangSC;
    font-size: 16px;
    font-weight: normal;
    cursor: default; }
    .smart-adaptation-preparing-subject > .title img {
      width: 24px;
      height: 24px;
      border-radius: 100%;
      transform: translateY(6px);
      margin-right: 4px; }
  .smart-adaptation-preparing-subject > .score {
    height: 38px;
    margin-right: 60px;
    text-align: center;
    line-height: 28px;
    color: #c3ccd1;
    position: relative; }
    .smart-adaptation-preparing-subject > .score .title {
      margin-bottom: 20px;
      text-align: center;
      color: #29323a;
      font-size: 16px; }
    .smart-adaptation-preparing-subject > .score .ant-input-number input {
      text-align: center;
      font-size: large;
      color: #aaa; }
    .smart-adaptation-preparing-subject > .score img {
      width: 24px;
      height: 24px;
      border-radius: 100%;
      transform: translateY(6px);
      margin-right: 4px;
      margin-top: -15px; }
  .smart-adaptation-preparing-subject .ant-input-number-disabled input {
    background-color: #fefefe; }

.smart-adaptation-preparing-subject:last-of-type > .score {
  margin-right: 0; }
