.student-list-container .filter-container {
  display: flex;
  justify-content: space-between; }
  .student-list-container .filter-container .filter-left {
    display: flex;
    flex-wrap: wrap;
    font-size: 12px; }
  .student-list-container .filter-container .filter-item {
    margin-bottom: 16px; }
  .student-list-container .filter-container .filter-item:not(:last-of-type) {
    margin-right: 20px; }
  .student-list-container .filter-container .filter-right .operation-btn {
    margin-right: 10px; }

.student-list-container .table-container .ant-table-tbody > tr > td {
  padding: 16px 16px !important;
  word-break: break-word; }

.student-add .ant-modal-body {
  padding: 16px 48px;
  font-size: 12px; }
  .student-add .ant-modal-body .modal-item {
    display: flex;
    margin-bottom: 20px;
    align-items: center; }
    .student-add .ant-modal-body .modal-item label {
      margin-right: 10px;
      width: 15%; }
    .student-add .ant-modal-body .modal-item .ant-input {
      width: 80%; }
  .student-add .ant-modal-body .tips {
    text-align: center;
    color: red; }
