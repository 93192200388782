.common-enhanced-date-picker {
  display: inline-flex;
  line-height: 30px;
  margin-right: 20px; }
  .common-enhanced-date-picker .ant-calendar-picker {
    margin: 0 15px; }
  .common-enhanced-date-picker .left,
  .common-enhanced-date-picker .right {
    cursor: pointer;
    font-size: 12px; }
