.trial-time-table-container {
  position: relative;
  width: 660px;
  margin: 12px auto 0; }
  .trial-time-table-container .header {
    height: 32px;
    position: relative;
    display: flex;
    justify-content: stretch; }
    .trial-time-table-container .header > div {
      flex-basis: 100%;
      text-align: center;
      line-height: 32px;
      font-size: 12px;
      color: #29323a; }
    .trial-time-table-container .header .empty {
      width: 100px; }
  .trial-time-table-container .header:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 200%;
    height: 200%;
    border: 1px solid #c3ccd1;
    -webkit-transform-origin: 0 0;
    -moz-transform-origin: 0 0;
    -ms-transform-origin: 0 0;
    -o-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: scale(0.5, 0.5);
    -ms-transform: scale(0.5, 0.5);
    -o-transform: scale(0.5, 0.5);
    transform: scale(0.5, 0.5);
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .trial-time-table-container .body {
    position: relative; }
    .trial-time-table-container .body .time-values {
      display: inline-block; }
      .trial-time-table-container .body .time-values .item {
        position: relative;
        width: 100px;
        height: 32px;
        line-height: 32px;
        text-align: center; }
      .trial-time-table-container .body .time-values .item::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 200%;
        height: 200%;
        border: 1px solid #c3ccd1;
        -webkit-transform-origin: 0 0;
        -moz-transform-origin: 0 0;
        -ms-transform-origin: 0 0;
        -o-transform-origin: 0 0;
        transform-origin: 0 0;
        -webkit-transform: scale(0.5, 0.5);
        -ms-transform: scale(0.5, 0.5);
        -o-transform: scale(0.5, 0.5);
        transform: scale(0.5, 0.5);
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box; }
    .trial-time-table-container .body .time-boxes {
      float: right;
      height: 320px;
      width: calc(100% - 100px);
      display: flex;
      justify-content: stretch; }
      .trial-time-table-container .body .time-boxes .row-container {
        flex-basis: 100%; }
        .trial-time-table-container .body .time-boxes .row-container > div {
          position: relative;
          height: 32px; }
        .trial-time-table-container .body .time-boxes .row-container > div:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 200%;
          height: 200%;
          border: 1px solid #c3ccd1;
          -webkit-transform-origin: 0 0;
          -moz-transform-origin: 0 0;
          -ms-transform-origin: 0 0;
          -o-transform-origin: 0 0;
          transform-origin: 0 0;
          -webkit-transform: scale(0.5, 0.5);
          -ms-transform: scale(0.5, 0.5);
          -o-transform: scale(0.5, 0.5);
          transform: scale(0.5, 0.5);
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box; }
        .trial-time-table-container .body .time-boxes .row-container > div:last-of-type:before {
          border-bottom: 2px solid #c3ccd1; }
        .trial-time-table-container .body .time-boxes .row-container .white {
          background: #fff; }
        .trial-time-table-container .body .time-boxes .row-container .gray {
          background: #eaeff2;
          cursor: not-allowed; }
        .trial-time-table-container .body .time-boxes .row-container .blue {
          background: #39f; }
      .trial-time-table-container .body .time-boxes .row-container:last-of-type > div:before {
        border-right: 2px solid #c3ccd1; }
  .trial-time-table-container .body:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 200%;
    height: 200%;
    border: 1px solid #c3ccd1;
    -webkit-transform-origin: 0 0;
    -moz-transform-origin: 0 0;
    -ms-transform-origin: 0 0;
    -o-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: scale(0.5, 0.5);
    -ms-transform: scale(0.5, 0.5);
    -o-transform: scale(0.5, 0.5);
    transform: scale(0.5, 0.5);
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }

.trial-time-table-container:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 200%;
  height: 200%;
  border: 1px solid #c3ccd1;
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-transform: scale(0.5, 0.5);
  -ms-transform: scale(0.5, 0.5);
  -o-transform: scale(0.5, 0.5);
  transform: scale(0.5, 0.5);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border: 2px solid #c3ccd1; }
