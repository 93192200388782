.container-student-course > .change {
  margin-bottom: 20px; }
  .container-student-course > .change .component-common-form-select .text {
    background: #fff;
    height: 40px;
    line-height: 40px; }
    .container-student-course > .change .component-common-form-select .text .option {
      padding: 0 12px; }
    .container-student-course > .change .component-common-form-select .text .fa {
      margin-top: 12px;
      margin-right: 12px; }
  .container-student-course > .change .component-common-form-select .list {
    top: 45px; }

.container-student-course > .start {
  margin-bottom: 25px;
  padding: 20px;
  background: #fff;
  border-radius: 2px;
  box-shadow: rgba(0, 0, 0, 0.0470588) 0 1px 1px 0;
  text-align: center; }
  .container-student-course > .start:before, .container-student-course > .start:after {
    content: ' ';
    display: table;
    clear: both; }
  .container-student-course > .start .btn {
    width: 150px;
    height: 40px;
    margin: 0 auto;
    margin-top: 15px;
    background: #12AEF4;
    line-height: 40px;
    color: #fff;
    border-radius: 2px;
    cursor: pointer;
    transition: background .15s; }
    .container-student-course > .start .btn:hover {
      background: #2ab6f5; }

.container-student-course .loading-position {
  margin-top: 180px; }

.container-student-course .load-more {
  margin-top: 20px;
  display: block;
  text-align: center; }

.ad-container {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 1001;
  transform: translate(-50%, -50%); }
  .ad-container .ad-image img {
    display: block; }

.warfare-icon {
  width: 80px;
  position: fixed;
  top: 20%;
  right: 2%;
  z-index: 1000;
  cursor: pointer; }
  .warfare-icon img {
    display: block;
    width: 80px;
    background-image: none; }

.ad-close {
  position: relative;
  left: 96%;
  top: 24px; }

.warfare-close {
  position: relative;
  left: 80%;
  top: 21px; }
