.error-bondary-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%; }
  .error-bondary-container .error-info {
    margin-top: -50px;
    text-align: center; }
    .error-bondary-container .error-info img {
      width: 140px;
      height: 140px;
      background: transparent; }
    .error-bondary-container .error-info p {
      margin-top: 12px; }
      .error-bondary-container .error-info p a {
        color: #385DAE; }
