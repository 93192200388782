.course-card-wrapper {
  position: relative;
  margin-top: 18px;
  display: inline-block;
  width: 240px;
  height: 222px;
  border-radius: 4px;
  cursor: pointer; }
  .course-card-wrapper .hot {
    width: 40px;
    height: 20px;
    position: absolute;
    right: -12px;
    top: -5px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAAAoCAMAAABevo0zAAAAzFBMVEUAAADyQjbyQTT/b0zxQTXxQjXxQTTwQzbyQTTwQjTxQjXxQjTxQjXxQjTwQTTyQTTyQzbyRTjxQTX2RjXyRDfyQjXwQTT////70c77y8f3pJ384d/wRjr+/f3xSz/5tbD+9/f839z0dm3719T5vbn4pqD1ioLzb2XyXlTxVEj98fD6wbz5saz4rKb3mpP2kor1gnr0fXTzaV796+r71NH6x8P5urXyZVrxUUX++vr95eP3pZ/3nZb1hXz+8/P96ef829nxWEz2job0eG+3bn1AAAAAFnRSTlMAeCcE++zFnU712NOxj4liEzuoHU86sdexAQAAAiRJREFUSMetlutS4kAQhbkIiojX3TMBkkAiQSCKIFfB6+77v9N20gwddsqUVc73I3USug6p7ulTKey5adRr+DEVbVcu1mCDqja8hh2OC0wRliiyXwm2OGHDBmxRYsM6LHFU5hEfwRKntlt4xoa/YItz89Cg5zjOkOWAZCdV/p+43R3dRiskRI7AJZoLNjyG4HpKdcFMlFLL5Nm2qxiv49L9Rh3Qw57fbFiF8EQVt2Ai0mNg/aGEGPA9JXCJ5pINKxD6VNEBsyAdwg8UMY8e4+dETLFuJcyTf07V4P9ouESGB6p7BDMjPUSHrm8TEGGX5EL3Whea0XCFDA7V3bFckxxRD4iQnyyVNDjQhWY0XCDDG9X5LO9INtHL9OBV7X91PS3NaDiH8JmMsskEpB/8pHErMFNF8JlakXr/IhrOIPxVh/QTjw2yb+juh7f4IhpOIbyoQ1ZbPotMi25mMrzWN6IhTg5DkyH57N7T9R47PuTGITn5RjS0qW7AckAyABvqJzLZkS40o+EkZ/F6aQ82mSM1ZzmUA2RGQ+7iheloQLhJ27wnpIRSaEZD7uK5szQTJuE4UMQLmLEUCldmNJiLh6mnhC3MxTOjIW/xiH5X271PAXPxcqNh3m63/V07SS55AFHgkXn86kIMuTAnGvJxP33kYESDFSQaLCHRYAmJBjvY/2qo2/5qaEg02KFk/VNTosEG14UdVdigVixrw4oFt3rjpqD5BwCtDK9iZ/KeAAAAAElFTkSuQmCC);
    background-size: 100%; }
  .course-card-wrapper img {
    display: block;
    width: 240px;
    height: 140px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px; }
  .course-card-wrapper .texts {
    height: 82px;
    background: #f6f8f9;
    border-radius: 4px;
    overflow: hidden; }
    .course-card-wrapper .texts .title {
      margin: 16px 0 12px 16px;
      font-size: 14px;
      color: #29323a;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
    .course-card-wrapper .texts .sub-content {
      display: flex;
      justify-content: space-between;
      padding: 0 12px 0 16px;
      font-size: 12px;
      color: #818c96; }
      .course-card-wrapper .texts .sub-content .left {
        margin-left: 5px; }
        .course-card-wrapper .texts .sub-content .left span {
          margin-right: 5px; }
        .course-card-wrapper .texts .sub-content .left .free {
          color: #EF8200; }
      .course-card-wrapper .texts .sub-content .right {
        margin-right: 9px; }
        .course-card-wrapper .texts .sub-content .right .percent {
          margin-left: 8px;
          display: inline-block;
          width: 30px;
          height: 12px;
          line-height: 12px;
          border-radius: 6px;
          background: #39f;
          color: #fff;
          text-align: center; }
          .course-card-wrapper .texts .sub-content .right .percent > span {
            display: inline-block;
            font-size: 12px;
            color: #fff;
            transform: scale(0.7); }
        .course-card-wrapper .texts .sub-content .right .banke-status {
          width: 40px;
          height: 12px; }
        .course-card-wrapper .texts .sub-content .right .banke-2 {
          background: #F04134; }
        .course-card-wrapper .texts .sub-content .right .banke-3 {
          background: #25B756; }
        .course-card-wrapper .texts .sub-content .right .banke-4 {
          background: #C3CCD1; }
  .course-card-wrapper .banke-texts {
    background: #fff; }
