.container {
  margin: 24px; }
  .container .ant-table-small {
    border: none; }
  .container .ant-table.ant-table-bordered > .ant-table-container {
    border: none; }
  .container .ant-table-thead > tr > th, .container .ant-table-tbody > tr > td {
    background-color: #fff;
    overflow-wrap: anywhere; }
  .container .search-header {
    display: flex;
    justify-content: space-between; }
  .container h2 {
    margin-top: 24px;
    text-align: center; }
  .container .export-excel {
    text-align: right; }
  .container .cepingName {
    height: 30px;
    text-align: left; }
  .container .right {
    color: green; }
  .container .wrong {
    color: red; }
