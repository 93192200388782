.container-school-absence-list > .tab {
  background: #fff;
  border-radius: 2px;
  box-shadow: rgba(0, 0, 0, 0.0470588) 0 1px 1px 0;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center; }
  .container-school-absence-list > .tab a {
    display: block;
    text-align: center;
    background: #fafafa;
    line-height: 50px;
    border-left: 1px solid #eee;
    cursor: pointer;
    transition: background .15s;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -moz-box-flex: 1;
    -moz-flex: 1;
    -ms-flex: 1;
    flex: 1; }
    .container-school-absence-list > .tab a:first-child {
      border-left: none; }
    .container-school-absence-list > .tab a:hover {
      background: #f7f7f7; }
    .container-school-absence-list > .tab a .fa {
      margin-right: 5px; }
  .container-school-absence-list > .tab .active {
    color: #3ec9f6;
    background: #fff; }
    .container-school-absence-list > .tab .active:hover {
      background: #fff; }

.container-school-absence-list .component-common-filter {
  margin-top: 25px; }

.container-school-absence-list .component-common-list {
  margin-top: 25px; }

.container-school-absence-list .component-common-pagination {
  margin-top: 25px; }
