.component-teacher-form {
  padding: 25px 100px;
  background: #fff;
  border-radius: 2px;
  box-shadow: rgba(0, 0, 0, 0.0470588) 0 1px 1px 0; }
  .component-teacher-form:before, .component-teacher-form:after {
    content: ' ';
    display: table;
    clear: both; }
  .component-teacher-form:first-child {
    margin-top: 0; }
  .component-teacher-form > .row {
    margin-top: 15px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center; }
    .component-teacher-form > .row .item {
      margin-left: 18px;
      -webkit-box-flex: 1;
      -webkit-flex: 1;
      -moz-box-flex: 1;
      -moz-flex: 1;
      -ms-flex: 1;
      flex: 1; }
      .component-teacher-form > .row .item:first-child {
        margin-left: 0; }
      .component-teacher-form > .row .item .component-common-form-input-form {
        display: inline-block;
        width: 200px; }
      .component-teacher-form > .row .item .tt {
        float: left;
        margin-right: 10px;
        line-height: 35px; }
        .component-teacher-form > .row .item .tt .btn {
          float: right;
          margin-left: 10px;
          font-weight: normal;
          cursor: pointer;
          transition: color .15s; }
          .component-teacher-form > .row .item .tt .btn:hover {
            color: #3ec9f6; }
  .component-teacher-form .buttons .button {
    display: inline-block;
    width: 80px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    border-radius: 2px;
    background: #e0e0e0;
    cursor: pointer;
    transition: background .15s;
    font-size: 13px; }
    .component-teacher-form .buttons .button:hover {
      background: #d6d6d6; }
  .component-teacher-form .buttons .submit {
    margin-left: 18px;
    color: #fff;
    background: #39f; }
    .component-teacher-form .buttons .submit:hover {
      background: #1f8fff; }
