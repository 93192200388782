.component-common-form-option {
  position: relative;
  padding: 0px 12px;
  padding-left: 36px;
  height: 40px;
  line-height: 40px;
  border-top: 1px solid #eee;
  transition: background .15s;
  font-size: 13px;
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden; }
  .component-common-form-option:before, .component-common-form-option:after {
    content: ' ';
    display: table;
    clear: both; }
  .component-common-form-option:first-child {
    border: none; }
  .component-common-form-option:hover {
    background: #f5f5f5; }
  .component-common-form-option .icon {
    margin-right: 8px;
    color: #333; }
  .component-common-form-option .active {
    position: absolute;
    top: 13px;
    left: 12px;
    color: #999; }
