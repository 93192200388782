.quality-inspection-management-container .filters {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }
  .quality-inspection-management-container .filters .filter-item {
    margin: 0 16px 18px 0; }

.quality-inspection-management-container .second-panel {
  display: flex;
  justify-content: space-between; }
