.container-school-staff {
  position: relative; }
  .container-school-staff .bread {
    position: absolute;
    left: 0;
    top: 0;
    padding-left: 32px;
    line-height: 50px;
    width: 100%;
    height: 50px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px; }
    .container-school-staff .bread a {
      color: #333;
      font-size: 12px; }
  .container-school-staff .main {
    margin-top: 10px !important; }
    .container-school-staff .main .title {
      margin-top: 50px;
      font-weight: 600;
      padding-bottom: 10px;
      font-size: 14px;
      border-bottom: 1px solid #ddd; }
    .container-school-staff .main .content {
      display: flex;
      flex-wrap: wrap;
      margin-top: 10px; }
      .container-school-staff .main .content .form-item {
        display: flex;
        align-items: center;
        flex-grow: 0;
        flex-shrink: 0;
        width: 35%;
        min-width: 300px;
        margin: 5px 0;
        font-size: 12px; }
        .container-school-staff .main .content .form-item .form-text {
          display: flex;
          justify-content: flex-end;
          width: 120px;
          flex-shrink: 0;
          line-height: 28px;
          padding-right: 10px;
          font-size: 12px; }
        .container-school-staff .main .content .form-item .character label {
          height: 30px; }
        .container-school-staff .main .content .form-item > span {
          line-height: 28px;
          font-size: 12px; }
        .container-school-staff .main .content .form-item .check-box, .container-school-staff .main .content .form-item .mechanism {
          background: #f7f7f7; }
          .container-school-staff .main .content .form-item .check-box label, .container-school-staff .main .content .form-item .mechanism label {
            height: 30px;
            padding-left: 15px; }
      .container-school-staff .main .content .form-item-full {
        width: 90% !important; }
        .container-school-staff .main .content .form-item-full .ant-checkbox-group-item {
          font-size: 12px; }
      .container-school-staff .main .content .ant-checkbox-group {
        padding: 4px; }
      .container-school-staff .main .content .ant-radio-group {
        line-height: 28px; }
  .container-school-staff .buttons {
    display: flex;
    justify-content: center;
    margin-top: 10px !important; }
