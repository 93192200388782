.smart-adaptation-study-resource-container {
  display: flex;
  justify-content: center; }
  .smart-adaptation-study-resource-container .smart-adaptation-study-resource {
    width: 1096px;
    margin-bottom: 40px; }
    .smart-adaptation-study-resource-container .smart-adaptation-study-resource .ant-menu {
      height: 56px;
      line-height: 56px;
      border-bottom: 0; }
      .smart-adaptation-study-resource-container .smart-adaptation-study-resource .ant-menu .ant-menu-item {
        height: 55px;
        margin-right: 67px;
        padding: 0;
        width: 48px;
        color: #818c96;
        font-size: 16px; }
      .smart-adaptation-study-resource-container .smart-adaptation-study-resource .ant-menu .ant-menu-item:first-of-type {
        margin: 0 67px 0 calc(50% - 139px); }
      .smart-adaptation-study-resource-container .smart-adaptation-study-resource .ant-menu .ant-menu-item:last-of-type {
        margin-right: 0; }
      .smart-adaptation-study-resource-container .smart-adaptation-study-resource .ant-menu .ant-menu-item-selected {
        color: #29323a !important;
        font-weight: bold;
        border-bottom: 4px solid #39f !important; }
