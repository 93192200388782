.component-platform-modal-practice {
  padding: 25px; }
  .component-platform-modal-practice .buttons {
    margin-top: 18px;
    text-align: center; }
    .component-platform-modal-practice .buttons span {
      display: inline-block;
      width: 80px;
      height: 30px;
      text-align: center;
      line-height: 30px;
      border-radius: 2px;
      background: #e0e0e0;
      cursor: pointer;
      transition: background .15s;
      margin-left: 18px;
      font-size: 13px;
      color: #fff;
      background: #39f; }
      .component-platform-modal-practice .buttons span:first-child {
        margin-left: 0; }
      .component-platform-modal-practice .buttons span:hover {
        background: #1f8fff; }
