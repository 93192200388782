.teaching-arranging {
  position: relative; }
  .teaching-arranging > * {
    background: #fff;
    box-sizing: border-box;
    min-height: 100%; }
  .teaching-arranging .bread-crumb {
    line-height: 1em;
    font-weight: 600;
    font-size: 16px;
    padding: 50px 0 20px 0;
    margin-bottom: 20px;
    border-bottom: 1px solid #eee; }
  .teaching-arranging .back {
    position: absolute;
    top: 0;
    left: 0;
    height: 50px;
    width: 100%;
    line-height: 50px;
    padding-left: 32px;
    margin-top: 0 !important;
    text-align: left;
    background: #f7f7f7;
    font-size: 12px;
    cursor: pointer; }
  .teaching-arranging .content h3 {
    padding: 0 !important;
    border: none;
    padding-bottom: 15px; }
    .teaching-arranging .content h3 span {
      height: 20px;
      line-height: 20px;
      border-left: 5px solid #039fe5;
      padding-left: 20px;
      margin-bottom: 10px; }
  .teaching-arranging .main {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex; }
    .teaching-arranging .main .menu-wrapper {
      flex: 0 0 170px; }
      .teaching-arranging .main .menu-wrapper > :first-child {
        font-size: 14px;
        font-weight: 600;
        display: block;
        padding: 10px 0; }
    .teaching-arranging .main .schedule-wrapper {
      flex: 5;
      padding-left: 20px; }
    .teaching-arranging .main .oper-wrapper {
      overflow: hidden;
      line-height: 40px;
      heihgt: 40px;
      position: relative;
      margin-bottom: 10px; }
      .teaching-arranging .main .oper-wrapper a {
        height: 28px;
        line-height: 28px;
        padding: 0 10px;
        margin-top: 5px;
        border: 1px solid #39f;
        border-radius: 4px;
        font-size: 12px;
        color: #39f; }
      .teaching-arranging .main .oper-wrapper > div {
        float: right; }
  .teaching-arranging .student-base-info div,
  .teaching-arranging .student-base-info span {
    font-size: 12px; }
  .teaching-arranging .student-base-info > h3 {
    height: 30px;
    line-height: 30px;
    padding-left: 0.5em; }
    .teaching-arranging .student-base-info > h3 span {
      height: 20px !important;
      line-height: 20px !important;
      font-weight: 600 !important; }
    .teaching-arranging .student-base-info > h3 > :first-child {
      font-size: 14px;
      font-weight: 500; }
  .teaching-arranging > .content {
    margin-top: -5px; }
    .teaching-arranging > .content > * {
      margin-bottom: 25px; }
  .teaching-arranging .requirement {
    margin-bottom: 20px; }
    .teaching-arranging .requirement span {
      font-size: 12px; }
  .teaching-arranging .oper-wrapper a {
    float: left;
    margin-right: 40px;
    text-decoration: none;
    color: #39f;
    cursor: pointer;
    display: inline-block;
    min-width: 80px;
    text-align: center; }
    .teaching-arranging .oper-wrapper a:hover {
      color: #3ec9f6; }
  .teaching-arranging button {
    margin-left: 40px;
    border-radius: 100px;
    border: 1px solid #355AA0;
    background: #355AA0;
    display: inline-block;
    padding: 0.5em 1em;
    vertical-align: middle;
    line-height: 1em;
    min-width: 100px;
    cursor: pointer;
    outline: none;
    color: #fff;
    border-radius: 4px; }
    .teaching-arranging button:hover {
      background: #039be0; }
  .teaching-arranging .submit {
    width: 50px;
    height: 28px;
    color: #fff;
    background: #355AA0; }
  .teaching-arranging .clear-change {
    background: #fff;
    border: 1px solid #D9D9D9;
    color: #282828; }
    .teaching-arranging .clear-change:hover {
      background: #fff; }
  .teaching-arranging .desc-info {
    margin-top: 20px; }
    .teaching-arranging .desc-info > span {
      font-size: 12px; }
      .teaching-arranging .desc-info > span:first-child {
        font-size: 14px;
        font-weight: 600; }
  .teaching-arranging .subject-menu > ul {
    border: 1px solid #eee;
    overflow: auto;
    height: 700px;
    margin-top: 10px;
    height: 1100px; }
    .teaching-arranging .subject-menu > ul > li.ant-menu-submenu-open {
      background: #355AA0;
      color: #fff;
      font-size: larger; }
      .teaching-arranging .subject-menu > ul > li.ant-menu-submenu-open > ul > li.ant-menu-submenu-open > div {
        background: #355AA0;
        box-shadow: 0 0 0 3px #fff inset;
        color: #fff; }
  .teaching-arranging .remark-row {
    margin-top: 25px;
    height: 200px; }
    .teaching-arranging .remark-row label {
      flex: 1;
      display: block;
      border-left: 5px solid #039fe5;
      padding-left: 10px;
      font-weight: 500; }
    .teaching-arranging .remark-row textarea {
      display: block;
      margin-top: 10px;
      width: 98%;
      margin-left: 2%;
      border-radius: 4px;
      min-height: 120px;
      border: 1px solid #e6e6e6;
      line-height: 1.5;
      padding: 5px;
      color: #666;
      font-size: 12px; }
  .teaching-arranging .course-schedule {
    border: 1px solid #eee; }
    .teaching-arranging .course-schedule .duration span {
      font-weight: 600; }
    .teaching-arranging .course-schedule [role='cell'] {
      font-weight: 600; }
    .teaching-arranging .course-schedule li {
      border-bottom: 1px solid #eee !important; }
  .teaching-arranging .schedule-submit {
    height: 100px;
    width: 100%;
    margin-top: 50px; }
    .teaching-arranging .schedule-submit > div {
      width: 300px;
      height: 50px;
      margin-left: 35%; }
      .teaching-arranging .schedule-submit > div .cancel {
        display: inline-block;
        background: #f04c5d;
        width: 80px;
        height: 28px;
        border-radius: 4px;
        color: #fff;
        line-height: 28px;
        margin: 7px 0 0 20px; }
      .teaching-arranging .schedule-submit > div > div {
        float: left; }
