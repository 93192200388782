.client-detail-container .logo-list-container .header {
  color: red; }

.client-detail-container .platform-container .header {
  padding: 15px 18px; }
  .client-detail-container .platform-container .header .operation {
    float: right;
    cursor: pointer;
    color: #39f; }

.client-detail-container .platform-container .header + div .head {
  height: 50px;
  line-height: 50px;
  background: #fafafa;
  border: 1px solid #e8e8e8; }

.client-detail-container .platform-container .header + div.two-blocks span {
  width: 50%; }

.client-detail-container .platform-container .header + div.three-blocks span {
  width: 33%; }

.client-detail-container .platform-container .header + div div {
  display: flex;
  justify-content: space-around;
  height: 35px;
  line-height: 35px;
  text-align: center;
  border-bottom: 1px solid #e9e9e9;
  font-size: 12px; }
  .client-detail-container .platform-container .header + div div .operation {
    cursor: pointer;
    color: #39f; }

.client-detail-container .role-list-container {
  margin-top: 25px; }

.client-detail-container .footer {
  text-align: center; }
  .client-detail-container .footer button {
    margin: 10px 15px;
    width: 80px; }
