.banke-detail .study-task .finish-situation {
  color: red;
  cursor: pointer; }

.banke-detail .study-task .year {
  margin-right: 15px; }

.banke-detail .study-task .task-durationTime {
  display: inline-block;
  margin-left: 15px; }
