.component-common-form-submit {
  margin-top: 25px;
  padding: 16px 18px;
  background: #fff;
  border-radius: 2px;
  text-align: center; }
  .component-common-form-submit:before, .component-common-form-submit:after {
    content: ' ';
    display: table;
    clear: both; }
  .component-common-form-submit > .buttons > .button {
    display: inline-block;
    width: 80px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    border-radius: 2px;
    background: #f04c5d;
    cursor: pointer;
    transition: background .15s;
    font-size: 12px;
    color: white;
    cursor: pointer; }
    .component-common-form-submit > .buttons > .button:hover {
      background: #eb142a; }
  .component-common-form-submit > .buttons > .submit {
    margin-left: 18px;
    color: #fff;
    background: #355AA0; }
    .component-common-form-submit > .buttons > .submit:hover {
      background: #305191; }
  .component-common-form-submit > .buttons > .back {
    margin-left: 18px;
    color: #000; }
    .component-common-form-submit > .buttons > .back:hover {
      background: #b1b1b1; }
