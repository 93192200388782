.oblate-modal .ant-modal-body {
  padding: 24px 48px; }

.modal .ant-modal-title {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
  color: #666666; }

.modal .ant-modal-footer .ant-btn {
  font-size: 14px;
  line-height: 1.5;
  color: #666; }

.modal .ant-modal-footer .ant-btn-primary {
  background-color: #39f;
  color: #fff;
  border: none; }

.clear-data .ant-modal-content {
  min-height: 228px;
  width: 416px; }
  .clear-data .ant-modal-content .clear-sign {
    display: inline-block;
    margin: 20px 0 0 100px; }
  .clear-data .ant-modal-content .ant-modal-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%; }
