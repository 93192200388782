.component-school-form-plan {
  margin-top: 25px; }
  .component-school-form-plan > .tab {
    border-radius: 2px 2px 0 0;
    overflow: hidden;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center; }
    .component-school-form-plan > .tab .item {
      text-align: center;
      border-bottom: 1px solid #eee;
      background: #fafafa;
      line-height: 40px;
      border-left: 1px solid #eee;
      cursor: pointer;
      transition: background .15s;
      -webkit-box-flex: 1;
      -webkit-flex: 1;
      -moz-box-flex: 1;
      -moz-flex: 1;
      -ms-flex: 1;
      flex: 1; }
      .component-school-form-plan > .tab .item:first-child {
        border-left: none; }
      .component-school-form-plan > .tab .item:hover {
        background: #f7f7f7; }
      .component-school-form-plan > .tab .item .fa {
        margin-right: 5px; }
    .component-school-form-plan > .tab .active {
      color: #39f;
      background: #fff; }
      .component-school-form-plan > .tab .active:hover {
        background: #fff; }
  .component-school-form-plan .component-common-form {
    margin-top: 0;
    border-radius: 0 0 2px 2px; }

.component-school-form-plan-active-task .component-student-form-schedule {
  display: none; }

.component-school-form-plan-active-task .component-school-form-pigai-history {
  display: none; }

.component-school-form-plan-active-schedule .component-platform-form-checklist {
  display: none; }

.component-school-form-plan-active-schedule .component-school-form-pigai-history {
  display: none; }

.component-school-form-plan-active-pigai .component-platform-form-checklist {
  display: none; }

.component-school-form-plan-active-pigai .component-student-form-schedule {
  display: none; }
