.component-common-modal {
  position: fixed;
  z-index: 200;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0; }
  .component-common-modal > .wrapper {
    height: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center; }
    .component-common-modal > .wrapper > .content {
      position: relative;
      z-index: 400;
      background: #fff;
      border-radius: 4px;
      box-shadow: rgba(0, 0, 0, 0.0470588) 0 1px 1px 0;
      transition: opacity .15s, margin-top .15s; }
      .component-common-modal > .wrapper > .content > .title {
        padding: 15px 18px;
        background: #fff;
        border-bottom: 1px solid #eee;
        font-weight: 600;
        font-size: 13px;
        color: #666;
        border-radius: 3px 3px 0 0; }
        .component-common-modal > .wrapper > .content > .title > i {
          padding: 14px;
          position: absolute;
          top: 0px;
          right: 0px;
          cursor: pointer;
          font-size: 16px;
          color: #999; }
      .component-common-modal > .wrapper > .content .component-school-modal-question-answer {
        font-size: 12px; }
        .component-common-modal > .wrapper > .content .component-school-modal-question-answer .info span {
          font-size: 12px; }
      .component-common-modal > .wrapper > .content > .buttons {
        padding: 15px 18px;
        background: #f9f9f9;
        border-top: 1px solid #eee;
        text-align: center;
        border-radius: 0 0 3px 3px; }
        .component-common-modal > .wrapper > .content > .buttons:before, .component-common-modal > .wrapper > .content > .buttons:after {
          content: ' ';
          display: table;
          clear: both; }
        .component-common-modal > .wrapper > .content > .buttons > span {
          display: inline-block;
          width: 80px;
          height: 30px;
          text-align: center;
          line-height: 30px;
          border-radius: 2px;
          background: #e0e0e0;
          cursor: pointer;
          transition: background .15s;
          font-size: 13px; }
          .component-common-modal > .wrapper > .content > .buttons > span:hover {
            background: #d6d6d6; }
        .component-common-modal > .wrapper > .content > .buttons > .confirm {
          margin-left: 30px;
          color: #fff;
          background: #355AA0; }
          .component-common-modal > .wrapper > .content > .buttons > .confirm:hover {
            background: #305191; }
    .component-common-modal > .wrapper > .mask {
      position: fixed;
      z-index: 300;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background: #000;
      transition: opacity .15s; }
