.component-common-form-radio {
  padding: 0 8px;
  height: 35px;
  line-height: 35px;
  font-size: 13px;
  cursor: pointer;
  transition: background .15s;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex; }
  .component-common-form-radio:hover {
    background: #f5f5f5; }
  .component-common-form-radio .fa {
    color: #333;
    font-size: 14px;
    vertical-align: middle;
    width: 18px;
    line-height: 38px; }
  .component-common-form-radio .title {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -moz-box-flex: 1;
    -moz-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: inline-block;
    margin-top: 2px;
    vertical-align: middle;
    overflow: hidden;
    text-overflow: ellipsis; }
