.container-student-practice {
  display: flex;
  flex-direction: column; }
  .container-student-practice > .header {
    display: flex;
    justify-content: flex-end;
    flex-shrink: 0; }
    .container-student-practice > .header a {
      display: inline-block;
      width: 80px;
      height: 28px;
      line-height: 28px;
      text-align: center;
      border-radius: 4px;
      background: #385dae;
      color: #fff;
      font-size: 12px; }
      .container-student-practice > .header a:hover {
        background: #7092dc; }
    .container-student-practice > .header .hide {
      display: none; }
  .container-student-practice > div:nth-child(2) {
    flex-grow: 1;
    flex-shrink: 0; }
  .container-student-practice > .main {
    display: flex;
    flex-direction: column;
    height: 100%; }
    .container-student-practice > .main > .filter {
      position: absolute;
      top: 0;
      left: 40px;
      margin-bottom: 20px; }
      .container-student-practice > .main > .filter .exams {
        list-style: none; }
        .container-student-practice > .main > .filter .exams > li {
          float: left;
          padding: 20px 0;
          margin-right: 32px;
          cursor: pointer; }
        .container-student-practice > .main > .filter .exams > .active {
          cursor: default;
          pointer-events: none;
          border-bottom: 2px solid #355AA0; }
    .container-student-practice > .main > .content {
      height: 100%; }
      .container-student-practice > .main > .content table {
        border: 0; }
        .container-student-practice > .main > .content table th {
          padding: 0; }
          .container-student-practice > .main > .content table th span {
            padding-left: 40px; }
        .container-student-practice > .main > .content table td {
          padding-left: 40px;
          border-bottom: 1px solid #eaeff2; }
        .container-student-practice > .main > .content table thead {
          margin-bottom: 20px; }
          .container-student-practice > .main > .content table thead tr th {
            height: 64px;
            background: #faf8f9;
            border-bottom: none; }
            .container-student-practice > .main > .content table thead tr th span {
              display: inline-block;
              width: 100%;
              background: #fff;
              height: 40px;
              line-height: 40px; }
        .container-student-practice > .main > .content table tbody tr {
          height: 64px; }
          .container-student-practice > .main > .content table tbody tr td .operation-btn {
            display: inline-block;
            padding-left: 8px;
            width: 100px;
            height: 32px;
            position: relative;
            line-height: 32px;
            border: 1px solid #c3ccd1;
            border-radius: 4px;
            text-align: left;
            color: #29323a;
            background: #fff; }
          .container-student-practice > .main > .content table tbody tr td .anticon-down {
            margin-right: 8px;
            float: right;
            line-height: 32px; }
          .container-student-practice > .main > .content table tbody tr td .status-container {
            display: flex;
            align-items: center; }
            .container-student-practice > .main > .content table tbody tr td .status-container .pre {
              display: inline-block;
              margin-right: 4px;
              width: 4px;
              height: 4px;
              border-radius: 9px; }
            .container-student-practice > .main > .content table tbody tr td .status-container .pre1, .container-student-practice > .main > .content table tbody tr td .status-container .pre3 {
              background: #5279cf;
              box-shadow: 0 0 3px 0 rgba(51, 153, 255, 0.5); }
            .container-student-practice > .main > .content table tbody tr td .status-container .pre4, .container-student-practice > .main > .content table tbody tr td .status-container .pre2 {
              background: #23cb5b;
              box-shadow: 0 0 3px 0 rgba(49, 212, 104, 0.5); }
          .container-student-practice > .main > .content table tbody tr:hover td {
            background: #fbfcfd; }
      .container-student-practice > .main > .content .ant-table-pagination {
        width: 100%;
        text-align: center; }
  .container-student-practice > .oops {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #666; }
