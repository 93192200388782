@charset "UTF-8";
.container-student-documents {
  display: flex;
  flex-direction: column; }
  .container-student-documents .filter {
    margin-bottom: 20px;
    position: absolute;
    top: 0;
    left: 40px; }
    .container-student-documents .filter .exams {
      list-style: none; }
      .container-student-documents .filter .exams > li {
        float: left;
        padding: 20px 0;
        cursor: pointer;
        margin-right: 32px; }
      .container-student-documents .filter .exams > .active {
        cursor: default;
        pointer-events: none;
        border-bottom: 2px solid #355AA0; }
  .container-student-documents .header {
    display: flex;
    justify-content: space-between;
    height: 28px;
    flex-shrink: 0; }
  .container-student-documents .main {
    display: flex;
    flex-direction: column; }
    .container-student-documents .main > .content {
      flex-grow: 1;
      flex-shrink: 0; }
      .container-student-documents .main > .content table {
        border: 0; }
        .container-student-documents .main > .content table th {
          padding: 0; }
          .container-student-documents .main > .content table th span {
            padding-left: 40px; }
        .container-student-documents .main > .content table td {
          padding-left: 40px;
          border-bottom: 1px solid #eaeff2; }
        .container-student-documents .main > .content table thead {
          margin-bottom: 20px; }
          .container-student-documents .main > .content table thead tr th {
            height: 64px;
            background: #faf8f9;
            border-bottom: none; }
            .container-student-documents .main > .content table thead tr th span {
              display: inline-block;
              width: 100%;
              background: #fff;
              height: 40px;
              line-height: 40px; }
        .container-student-documents .main > .content table tbody tr {
          height: 64px; }
          .container-student-documents .main > .content table tbody tr .table-click-link－button {
            display: inline-block;
            min-width: 80px;
            height: 28px;
            border-radius: 4px;
            line-height: 28px;
            text-align: center;
            background-color: #ffffff;
            border: solid 1px #5279cf;
            color: #5279cf;
            margin: 3px 10px 3px 0; }
          .container-student-documents .main > .content table tbody tr:hover td {
            background: #fbfcfd; }
      .container-student-documents .main > .content .ant-table-pagination {
        width: 100%;
        text-align: center; }
  .container-student-documents > .oops {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #666; }
  .container-student-documents .dropdown {
    background-color: #fff;
    border-radius: 3px;
    border: 1px solid #ddd;
    color: #355aa0; }
    .container-student-documents .dropdown > li {
      cursor: pointer;
      padding: 5px 10px;
      text-align: center; }
    .container-student-documents .dropdown > li.active {
      color: #fff;
      background-color: #355AA0;
      cursor: default;
      pointer-events: none; }
