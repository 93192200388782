.container-school__student-manage .ant-collapse-header {
  background-color: #999 !important; }
  .container-school__student-manage .ant-collapse-header :hover {
    background-color: #aaa !important; }
  .container-school__student-manage .ant-collapse-header :active {
    background-color: #888 !important; }

.container-school__student-manage .ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked {
  background-color: #bdf; }

.container-school__student-manage .panel-header > div {
  color: #fff;
  font-weight: 500; }

.container-school__student-manage .panel-header .operate-btn {
  width: 73px;
  text-align: center; }

.container-school__student-manage .ant-table-body table th {
  background-color: #f7f7f7; }

.container-school__student-manage .buttons {
  display: flex;
  padding: 8px 30px;
  justify-content: center; }
  .container-school__student-manage .buttons .cancel {
    margin-right: 16px; }

.container-school__student-manage .form-item-value {
  color: #282828;
  line-height: 26px; }
