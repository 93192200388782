.container-course-student > div {
  height: 100%; }

.container-course-student .select {
  width: 100%; }
  .container-course-student .select > div {
    margin-top: 0 !important; }

.container-course-student .content {
  width: 100%; }

.container-course-student .courseList {
  float: left; }
  .container-course-student .courseList .courseListBox {
    height: 222px;
    width: 240px;
    position: relative;
    border-radius: 4px;
    overflow: hidden; }
  .container-course-student .courseList dl {
    height: 100%;
    width: 100%;
    margin-bottom: 20px; }
    .container-course-student .courseList dl dt {
      height: 140px;
      width: 240px; }
      .container-course-student .courseList dl dt img {
        height: 100%;
        width: 100%; }
    .container-course-student .courseList dl dd {
      height: 82px;
      padding: 0 20px;
      background: #fff; }
      .container-course-student .courseList dl dd .courseName {
        padding: 16px 0px;
        margin-bottom: 0;
        font-weight: 600;
        color: #29313a;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 14px; }
      .container-course-student .courseList dl dd .courseDetail span {
        color: #818c97; }
      .container-course-student .courseList dl dd .courseDetail .productValidity {
        display: inline-block;
        width: 105px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 12px; }
      .container-course-student .courseList dl dd .courseDetail .statusProgress {
        float: right;
        font-size: 12px; }
      .container-course-student .courseList dl dd .courseDetail .progress {
        margin-left: 5px;
        text-align: center;
        line-height: 12px;
        display: inline-block;
        height: 12px;
        width: 26px;
        background: #39f;
        border-radius: 8px; }
        .container-course-student .courseList dl dd .courseDetail .progress span {
          display: inline-block;
          font-size: 12px;
          color: #fff;
          transform: scale(0.7); }
  .container-course-student .courseList .mask {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: 4px; }
    .container-course-student .courseList .mask:hover .maskBox {
      display: block; }
    .container-course-student .courseList .mask .maskBox {
      position: relative;
      height: 100%;
      width: 100%;
      display: none; }
      .container-course-student .courseList .mask .maskBox .courseMask {
        background: #000;
        opacity: 0.4;
        height: 100%;
        width: 100%; }
      .container-course-student .courseList .mask .maskBox .courseOperation {
        width: 100%;
        position: absolute;
        top: 80px;
        left: 0;
        color: #fff;
        display: flex;
        padding: 0 30px; }
        .container-course-student .courseList .mask .maskBox .courseOperation .operation {
          flex: 1;
          text-align: center;
          cursor: pointer; }
          .container-course-student .courseList .mask .maskBox .courseOperation .operation:hover .iconFather {
            background: #f6f8f9; }
          .container-course-student .courseList .mask .maskBox .courseOperation .operation .iconFather {
            height: 40px;
            width: 40px;
            display: inline-block;
            background: #fff;
            border-radius: 25px;
            text-align: center; }
            .container-course-student .courseList .mask .maskBox .courseOperation .operation .iconFather .anticon {
              color: #385dae;
              line-height: 40px;
              font-size: 16px; }
          .container-course-student .courseList .mask .maskBox .courseOperation .operation .operationName {
            margin-top: 10px;
            font-size: 12px;
            font-weight: 500; }

@media screen and (min-width: 1441px) {
  .container-course-student .courseList {
    margin: 0 40px 30px 0; } }

@media screen and (min-width: 1367px) and (max-width: 1440px) {
  .container-course-student .courseList {
    margin: 0 56px 30px 0; }
    .container-course-student .courseList:nth-child(4n) {
      margin-right: 0; } }

@media screen and (min-width: 1281px) and (max-width: 1366px) {
  .container-course-student .courseList {
    margin: 0 40px 30px 0; } }

@media screen and (max-width: 1280px) {
  .container-course-student .select {
    margin-left: -8px; }
  .container-course-student .content {
    margin-left: -8px; }
  .container-course-student .courseList {
    width: 230px;
    border-radius: 0 4px 4px 0;
    overflow: hidden;
    margin: 0 32px 30px -8px; }
    .container-course-student .courseList dl, .container-course-student .courseList dd, .container-course-student .courseList dt {
      width: 230px !important;
      margin: 0; }
    .container-course-student .courseList .mask {
      width: 230px; }
    .container-course-student .courseList .courseMask {
      border-radius: 4px; } }

@media screen and (width: 1280px) {
  .container-course-student .courseList:nth-child(4n) {
    margin-right: 0; } }

@media screen and (width: 1366px) {
  .container-course-student .courseList:nth-child(4n) {
    margin-right: 0; } }

@media screen and (width: 1440px) {
  .container-course-student .courseList:nth-child(4n) {
    margin-right: 0; } }

@media screen and (width: 1920px) {
  .container-course-student .courseList:nth-child(6n) {
    margin-right: 0; } }

.beginModal {
  width: 400px !important; }
  .beginModal .ant-modal-content {
    width: 400px;
    height: 218px;
    margin-right: 0; }
    .beginModal .ant-modal-content .ant-modal-header {
      border-bottom: none;
      text-align: center;
      background: #f7f9fb; }
    .beginModal .ant-modal-content .ant-modal-body {
      text-align: center;
      padding-top: 35px; }
      .beginModal .ant-modal-content .ant-modal-body .beginSureExpress {
        font-size: 14px; }
    .beginModal .ant-modal-content .ant-modal-footer {
      border-top: none;
      margin-top: 15px;
      text-align: center; }
      .beginModal .ant-modal-content .ant-modal-footer .ant-btn:first-child:hover {
        border-color: #385dae !important; }
        .beginModal .ant-modal-content .ant-modal-footer .ant-btn:first-child:hover span {
          color: #385dae !important; }
      .beginModal .ant-modal-content .ant-modal-footer .ant-btn-primary {
        background: #385dae !important;
        border-color: #385dae !important; }

.ant-select-dropdown-menu-item {
  font-size: 12px; }
