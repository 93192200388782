.common-modal-form {
  position: fixed;
  z-index: 200;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0; }
  .common-modal-form .wrapper {
    height: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center; }
    .common-modal-form .wrapper > .mask {
      position: fixed;
      z-index: 300;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background: #000;
      transition: opacity .15s; }
  .common-modal-form .form {
    position: relative;
    z-index: 400;
    background: #fff;
    border-radius: 2px;
    box-shadow: rgba(0, 0, 0, 0.0470588) 0 1px 1px 0;
    transition: opacity .15s, margin-top .15s; }
    .common-modal-form .form > .title {
      padding: 15px 18px;
      background: #f3f3f3;
      border-bottom: 1px solid #eee;
      font-weight: bold;
      font-size: 13px;
      border-radius: 3px 3px 0 0; }
    .common-modal-form .form > .confirm {
      padding: 20px;
      padding-bottom: 0; }
      .common-modal-form .form > .confirm .name {
        color: #FF0000; }
    .common-modal-form .form .item {
      margin-top: 18px;
      padding: 0 18px;
      width: 400px; }
      .common-modal-form .form .item:first-child {
        margin-top: 0; }
      .common-modal-form .form .item .tt {
        display: block;
        margin-bottom: 5px; }
      .common-modal-form .form .item .component-common-form-input input {
        display: block;
        border-radius: 2px;
        width: 100%;
        padding: 0 8px; }
      .common-modal-form .form .item .component-common-form-input .fa {
        display: none; }
      .common-modal-form .form .item .component-common-form-select .text,
      .common-modal-form .form .item > .component-common-form-datetime .text {
        border: 1px solid #d9d9d9;
        border-radius: 2px;
        background: #f9f9f9; }
        .common-modal-form .form .item .component-common-form-select .text .option,
        .common-modal-form .form .item > .component-common-form-datetime .text .option {
          padding: 0 8px; }
        .common-modal-form .form .item .component-common-form-select .text .fa-angle-down,
        .common-modal-form .form .item > .component-common-form-datetime .text .fa-angle-down {
          float: right;
          margin-top: 10px;
          margin-right: 10px; }
      .common-modal-form .form .item .date-interval {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        -moz-justify-content: center;
        justify-content: center; }
        .common-modal-form .form .item .date-interval:before, .common-modal-form .form .item .date-interval:after {
          content: ' ';
          display: table;
          clear: both; }
        .common-modal-form .form .item .date-interval .component-common-form-datetime {
          display: block;
          float: left;
          -webkit-box-flex: 1;
          -webkit-flex: 1;
          -moz-box-flex: 1;
          -moz-flex: 1;
          -ms-flex: 1;
          flex: 1; }
          .common-modal-form .form .item .date-interval .component-common-form-datetime .text {
            border: 1px solid #d9d9d9;
            border-radius: 2px;
            background: #f9f9f9; }
          .common-modal-form .form .item .date-interval .component-common-form-datetime .option {
            padding: 0 8px; }
          .common-modal-form .form .item .date-interval .component-common-form-datetime .fa-angle-down {
            float: right;
            margin-top: 10px;
            margin-right: 10px; }
        .common-modal-form .form .item .date-interval .split {
          display: block;
          float: left;
          width: 30px;
          line-height: 35px;
          text-align: center; }
    .common-modal-form .form .buttons {
      margin-top: 20px;
      padding: 15px 18px;
      background: #f9f9f9;
      border-top: 1px solid #eee;
      text-align: center;
      border-radius: 0 0 3px 3px; }
      .common-modal-form .form .buttons:before, .common-modal-form .form .buttons:after {
        content: ' ';
        display: table;
        clear: both; }
      .common-modal-form .form .buttons .button {
        display: inline-block;
        width: 80px;
        height: 30px;
        text-align: center;
        line-height: 30px;
        border-radius: 2px;
        background: #e0e0e0;
        cursor: pointer;
        transition: background .15s;
        font-size: 13px; }
        .common-modal-form .form .buttons .button:hover {
          background: #d6d6d6; }
      .common-modal-form .form .buttons .submit {
        margin-left: 30px;
        color: #fff;
        background: #355AA0; }
        .common-modal-form .form .buttons .submit:hover {
          background: #305191; }
