@charset "UTF-8";
.component-common-suggest {
  display: inline-block; }
  .component-common-suggest > * {
    display: inline-block;
    vertical-align: middle;
    font-size: 14px; }
  .component-common-suggest label {
    width: 150px;
    text-align: right;
    margin-right: 30px; }
    .component-common-suggest label:after {
      content: '\FF1A';
      position: relative;
      top: -2px; }
  .component-common-suggest > div {
    box-sizing: border-box;
    text-align: left;
    position: relative; }
    .component-common-suggest > div input[type=text] {
      width: 230px;
      box-sizing: border-box;
      padding: 0 2px;
      min-height: 30px; }
  .component-common-suggest .suggest-list-item span:not(:last-child) {
    margin-right: 10px;
    min-width: 60px;
    display: inline-block; }
  .component-common-suggest .suggest-list-item span:last-child {
    display: inline-block;
    min-width: 100px; }
  .component-common-suggest ul {
    background: #fff;
    max-height: 300px;
    min-width: 100%;
    overflow: auto;
    position: absolute;
    top: 30px;
    left: 0;
    text-align: center;
    z-index: 200;
    border: 1px solid #ccc; }
    .component-common-suggest ul li {
      cursor: pointer;
      padding: 4px 4px; }
      .component-common-suggest ul li:hover {
        background: #eaf8fe; }
      .component-common-suggest ul li.selected {
        background: #26c9e8; }
      .component-common-suggest ul li.onEnter {
        background: #eaf8fe !important; }
  .component-common-suggest .suggest-icon {
    position: absolute;
    top: 50%;
    right: 10px; }
    .component-common-suggest .suggest-icon > * {
      position: relative;
      height: 100%;
      display: block;
      margin-top: -50%; }
