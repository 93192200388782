.optContainer {
  text-align: right; }
  .optContainer .addStudent {
    margin-bottom: 20px;
    margin-left: 20px; }

.addStudentModal {
  width: 520px;
  max-height: 493px; }

.ant-modal-body .studentListContainer {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 1px solid #E2E2E2;
  border-bottom-color: #fff;
  max-height: 194px;
  overflow-y: scroll; }
  .ant-modal-body .studentListContainer .listItem {
    height: 32px;
    border-bottom: 1px solid #E2E2E2;
    line-height: 32px; }
    .ant-modal-body .studentListContainer .listItem .col {
      text-align: left;
      display: inline-block;
      box-sizing: border-box;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden; }
