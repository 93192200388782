.filter-operation-container {
  margin-bottom: 32px; }
  .filter-operation-container label {
    font-size: 12px;
    color: #666; }
  .filter-operation-container button {
    font-weight: normal; }
  .filter-operation-container .finished-course-hours {
    float: right;
    margin: 5px 18px; }
    .filter-operation-container .finished-course-hours .red {
      margin: 0 4px;
      color: red; }
  .filter-operation-container .enter-room {
    width: 100px;
    background: #fff !important;
    float: right;
    color: #000; }
    .filter-operation-container .enter-room:hover {
      color: #39f;
      border-color: #39f !important; }
  .filter-operation-container .ant-select-selection {
    font-size: 12px; }
  .filter-operation-container .middle-filter-label {
    margin-left: 24px; }
  .filter-operation-container .operation-btn {
    float: right;
    height: 28px;
    border-radius: 4px;
    background-color: #39f;
    color: #fff;
    border: none;
    font-size: 14px;
    text-align: center;
    line-height: 28px; }

.table-container .ant-table-content {
  border: none !important; }
  .table-container .ant-table-content .ant-table-body table td {
    padding: 8px; }
  .table-container .ant-table-content .ant-table-body table thead {
    background-color: #f7f7f7; }
    .table-container .ant-table-content .ant-table-body table thead tr th {
      font-size: 12px;
      line-height: 1.5;
      color: #666;
      border-bottom: 1px solid #e9e9e9; }
    .table-container .ant-table-content .ant-table-body table thead tr .center {
      text-align: center !important; }
    .table-container .ant-table-content .ant-table-body table thead tr .padding-left {
      padding-left: 16px; }
    .table-container .ant-table-content .ant-table-body table thead tr .center-td {
      text-align: center !important; }
  .table-container .ant-table-content .ant-table-body table tbody tr td {
    font-size: 12px;
    line-height: 1.5;
    color: #4c4c4c;
    text-align: left !important; }
    .table-container .ant-table-content .ant-table-body table tbody tr td .table-click-link {
      margin-right: 8px;
      color: #39f; }
    .table-container .ant-table-content .ant-table-body table tbody tr td .table-click-link:last-child {
      margin-right: 0; }
  .table-container .ant-table-content .ant-table-body table tbody tr .center-td {
    text-align: center !important; }
  .table-container .ant-table-content .ant-table-body table tbody tr .ellipsis {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  .table-container .ant-table-content .ant-table-body table tbody tr .center {
    text-align: center !important; }
  .table-container .ant-table-content .ant-table-body table tbody tr .padding-left {
    padding-left: 16px; }
  .table-container .ant-table-content .ant-table-body table tbody tr .flex-center-img {
    display: flex;
    align-items: center; }
  .table-container .ant-table-content .ant-table-body table tbody tr .left-img {
    padding-left: 18px;
    padding-top: 12px; }
