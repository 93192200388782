.course-package-container .ant-spin {
  width: 100%;
  height: 400px;
  text-align: center; }
  .course-package-container .ant-spin .ant-spin-dot {
    top: 130px;
    display: inline-block;
    width: 48px;
    height: 48px; }
    .course-package-container .ant-spin .ant-spin-dot i {
      width: 30px;
      height: 30px; }

.course-package-container .link-container {
  margin-bottom: 20px;
  font-size: 12px; }
  .course-package-container .link-container .link {
    cursor: pointer; }
    .course-package-container .link-container .link:hover {
      color: #29323a; }
  .course-package-container .link-container .line {
    padding: 0 10px; }
  .course-package-container .link-container .link,
  .course-package-container .link-container .line {
    color: #818c96; }
  .course-package-container .link-container .detail {
    color: #29323a; }

.course-package-container .course-package-inner-container {
  display: flex;
  padding-bottom: 40px; }
  .course-package-container .course-package-inner-container > div {
    display: inline-block; }
  .course-package-container .course-package-inner-container .left {
    width: 24%; }
    .course-package-container .course-package-inner-container .left .course-list-container {
      height: 100%;
      background: #eaeff2; }
      .course-package-container .course-package-inner-container .left .course-list-container .back {
        display: block;
        overflow: hidden;
        padding: 0 12px;
        height: 49px;
        line-height: 48px;
        background: #282828;
        color: #bbb;
        cursor: pointer; }
      .course-package-container .course-package-inner-container .left .course-list-container .header {
        line-height: 34px;
        font-size: 12px;
        word-break: break-all;
        color: #29323a;
        padding: 40px 0;
        text-align: center; }
        .course-package-container .course-package-inner-container .left .course-list-container .header .progress {
          margin-bottom: 8px; }
          .course-package-container .course-package-inner-container .left .course-list-container .header .progress .ant-progress-inner .ant-progress-circle-path {
            stroke: #25b756 !important; }
    .course-package-container .course-package-inner-container .left .course-list .course-list-item {
      padding: 0 10px 0 22px;
      display: flex;
      align-items: center;
      overflow: hidden;
      cursor: pointer;
      position: relative;
      height: 72px;
      line-height: 72px;
      color: #29323a;
      font-weight: 600; }
      .course-package-container .course-package-inner-container .left .course-list .course-list-item .anticon-question {
        margin-right: 12px;
        font-size: 32px; }
      .course-package-container .course-package-inner-container .left .course-list .course-list-item .trangle {
        position: absolute;
        right: 0;
        top: 25px;
        width: 0;
        height: 0;
        border-top: 15px solid transparent;
        border-bottom: 15px solid transparent;
        border-right: 15px solid #fff; }
      .course-package-container .course-package-inner-container .left .course-list .course-list-item img {
        width: 32px;
        height: 32px;
        border-radius: 100px;
        margin-right: 12px; }
      .course-package-container .course-package-inner-container .left .course-list .course-list-item .name {
        width: 70%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; }
    .course-package-container .course-package-inner-container .left .course-list .selected {
      padding-left: 18px;
      background: #fff;
      border-left: 4px solid #385dae; }
  .course-package-container .course-package-inner-container .right {
    width: 76%;
    background: #fff; }
    .course-package-container .course-package-inner-container .right header {
      overflow: hidden;
      padding: 0 18px;
      height: 60px;
      line-height: 60px;
      border-bottom: 1px solid #eee; }
      .course-package-container .course-package-inner-container .right header .name {
        float: left;
        font-size: 14px;
        font-weight: 600; }
      .course-package-container .course-package-inner-container .right header .ant-btn {
        float: right;
        margin-top: 16px; }
    .course-package-container .course-package-inner-container .right .avaliable-teachers-container .title {
      padding: 24px 0 12px;
      border-bottom: 1px solid #d0dce7;
      font-size: 16px;
      color: #505050; }
    .course-package-container .course-package-inner-container .right .avaliable-teachers-container .teacher-blocks {
      margin-top: 40px;
      padding: 0 40px; }
      .course-package-container .course-package-inner-container .right .avaliable-teachers-container .teacher-blocks .teacher-block {
        position: relative;
        display: inline-block;
        vertical-align: top;
        box-sizing: content-box;
        cursor: pointer;
        text-align: center;
        margin-right: 40px;
        width: 93px;
        height: 98px;
        border: 1px solid #eaeff2; }
        .course-package-container .course-package-inner-container .right .avaliable-teachers-container .teacher-blocks .teacher-block img {
          display: block;
          margin: 12px auto 8px;
          width: 48px;
          height: 48px;
          border-radius: 50%; }
      .course-package-container .course-package-inner-container .right .avaliable-teachers-container .teacher-blocks .selected {
        border: 2px solid #274a96;
        width: 91px;
        height: 96px; }
        .course-package-container .course-package-inner-container .right .avaliable-teachers-container .teacher-blocks .selected img:last-child {
          position: absolute;
          right: 0;
          bottom: 0;
          margin: 0;
          width: 20px;
          height: 20px;
          background: #fff;
          border-radius: 0; }
    .course-package-container .course-package-inner-container .right .avaliable-teachers-container .btn-container {
      display: inline-block;
      margin-top: 71px; }
      .course-package-container .course-package-inner-container .right .avaliable-teachers-container .btn-container .ant-btn {
        width: 80px;
        height: 28px; }
    .course-package-container .course-package-inner-container .right .course-detail-block {
      padding: 40px 40px 0 40px; }
      .course-package-container .course-package-inner-container .right .course-detail-block .title {
        background: #f6f8f9;
        font-size: 12px;
        height: 36px;
        line-height: 36px;
        color: #505050;
        cursor: pointer; }
        .course-package-container .course-package-inner-container .right .course-detail-block .title .name {
          display: inline-block;
          padding-left: 12px;
          color: #29323a; }
        .course-package-container .course-package-inner-container .right .course-detail-block .title .arrow {
          float: right;
          margin-right: 20px;
          color: #818c96; }
      .course-package-container .course-package-inner-container .right .course-detail-block .course-detail-list .course-detail-item {
        display: flex;
        align-items: center;
        margin-top: 25px; }
        .course-package-container .course-package-inner-container .right .course-detail-block .course-detail-list .course-detail-item > * {
          display: inline-block;
          font-size: 12px; }
        .course-package-container .course-package-inner-container .right .course-detail-block .course-detail-list .course-detail-item .anticon {
          width: 5%;
          color: #c3ccd1; }
        .course-package-container .course-package-inner-container .right .course-detail-block .course-detail-list .course-detail-item .wacthing {
          display: inline-block;
          height: 12px;
          width: 12px;
          background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAqZJREFUSA2lls1rE0EUwN9s9yOpaSmIYGPASmuMBQ3NyUP/AQm0eO9FtAcFC6WHnrSoJw896EEPFbx4lwQKPdVQohGtgeClF0GhLLQ14MfJbrPP9yZks+nObpI6EDLzPn5vPt68HQER7UOtlnKOxKwAyCPiOAiRlOaIthDiKwKsGzoWrmWzu2EY8g22cnUnCa7zEMG9SdqBoEWHpCFAewWasTKdy9gdGhoEApSrtRlwxWsEHDpuHDUWIP6AhnPTuWzRb6f5B++2vywAijf9wpkhfchXMnxQbwVy5gxH7Ajqs+2pS2fjgsAbrZXIAM09P9w5ycxVUZvbZWb4TJqzlQfa356rwC2ZnCgxeSxkKjrwjfrKbIlZJpweGQZ7vw60fezTa2sYBoxpnOfkoYQzaWQoARdSSZi6fBESp+K9wtlugNkaHUK+F6/BeAyylyZg7NwoaMLLjUhXZmvyhkaatZWUIZA6ewamJtMwnBhsK0J6zNa86x9ipBLHYxZcSY/T1o2q1G0ZlRZ9s1TmWxLaclcnYeJ8KqDn1Vi6DqWtCriuG9BLAe2RTh2uH2m1hVrKwMp2DUrvP4XDm662LpCrIvYcYP9HHYobb8HeO1BH90mZrQuB65Te131yZZdnXf5Yha3K526z9vyZrZuGUfjrHD2lE1fehZ+/fsP3XRs2Nsuwd1D3nLt16IwapqEXZEI/Wn2xRgFud3PqR08BXj5YujMva5FlmiuyQPVDiLBlFjPZRAZYvneLPoE4R+OQfIugBVUus5jJKq/231+6W6RlLZLsf4IQXCwyqxU3UFQerz6fQTzZJ5Nn7od3rKAVkQ0sy8zwIdGv0ZKH/bMN27LPcTj7BFbgBz15tpY6dJxZWlEeBT1bAJrPFrr9fIk4zznNlxfmQ58t/wBsWPO8MaahtwAAAABJRU5ErkJggg==);
          background-size: contain; }
        .course-package-container .course-package-inner-container .right .course-detail-block .course-detail-list .course-detail-item .name {
          width: 37%;
          padding-right: 6px;
          color: #818c96;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap; }
          .course-package-container .course-package-inner-container .right .course-detail-block .course-detail-list .course-detail-item .name .item {
            width: 200px; }
        .course-package-container .course-package-inner-container .right .course-detail-block .course-detail-list .course-detail-item .duration {
          width: 12%;
          color: #818c96; }
        .course-package-container .course-package-inner-container .right .course-detail-block .course-detail-list .course-detail-item .operations {
          width: 45%; }
          .course-package-container .course-package-inner-container .right .course-detail-block .course-detail-list .course-detail-item .operations a {
            margin-left: 8px;
            float: right;
            width: 80px;
            height: 28px;
            line-height: 28px;
            text-align: center;
            border-radius: 4px;
            background-color: #385dae;
            color: #fff; }
            .course-package-container .course-package-inner-container .right .course-detail-block .course-detail-list .course-detail-item .operations a:hover {
              background: #7092dc; }
          .course-package-container .course-package-inner-container .right .course-detail-block .course-detail-list .course-detail-item .operations .free {
            background-color: #f79725; }
    .course-package-container .course-package-inner-container .right .course-detail-block:last-child {
      padding: 40px; }
