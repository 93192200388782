.teacher-comments > div > * {
  background: #fff;
  margin-bottom: 35px; }
  .teacher-comments > div > *:first-child {
    padding: 0 25px 25px 0; }

.teacher-comments .select-container {
  border-bottom: 1px solid #eee; }

.teacher-comments table {
  width: 100%;
  text-align: center;
  border: 0 none;
  border-radius: 4px !important;
  collapse: collapse; }
  .teacher-comments table td {
    line-height: 20px;
    padding: 10px;
    border-bottom: 1px solid #e6e6e6; }
  .teacher-comments table th:nth-child(3),
  .teacher-comments table td:nth-child(3) {
    padding-left: 20px;
    text-align: left !important; }
  .teacher-comments table .content {
    text-align: left;
    text-indent: 2em; }

.teacher-comments .overview {
  margin-bottom: 30px;
  font-size: 14px;
  font-weight: 500; }
  .teacher-comments .overview strong {
    margin: 0 8px;
    font-weight: normal;
    color: #FC6238; }
