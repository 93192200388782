.container-platform-config-category .component-common-list {
  margin-top: 25px;
  font-size: 12px; }
  .container-platform-config-category .component-common-list label {
    font-size: 12px; }
  .container-platform-config-category .component-common-list .row {
    position: relative; }
    .container-platform-config-category .component-common-list .row .item {
      padding-left: 3px;
      padding-right: 3px; }
      .container-platform-config-category .component-common-list .row .item .button {
        margin-left: 0; }
      .container-platform-config-category .component-common-list .row .item:first-child {
        padding-left: 15px; }
      .container-platform-config-category .component-common-list .row .item:last-child {
        text-align: center; }
      .container-platform-config-category .component-common-list .row .item .out {
        position: absolute;
        left: 20px;
        height: 30px;
        width: 50px;
        display: inline-block;
        background-size: cover;
        opacity: 0.2; }

.container-platform-config-category .staff-operatons a {
  margin-right: 8px; }

.container-platform-config-category .component-common-pagination {
  margin-top: 25px; }

.container-platform-config-category .filter {
  position: relative;
  padding: 0 4px 20px 0;
  display: flex;
  flex-wrap: wrap;
  background-color: #fff;
  border-bottom: 1px solid #eee; }

.container-platform-config-category .filter-item {
  display: flex;
  flex-wrap: wrap;
  margin-right: 20px;
  padding: 0; }
  .container-platform-config-category .filter-item:last-child {
    right: 0;
    top: 0;
    margin-right: 0; }
  .container-platform-config-category .filter-item > label {
    padding: 7px 0;
    display: inline-block; }
  .container-platform-config-category .filter-item > div {
    padding: 3px; }
  .container-platform-config-category .filter-item .ant-radio-button-wrapper {
    font-size: 12px; }

.container-platform-config-category .add-teacher {
  position: absolute; }

.container-platform-config-category table td {
  font-size: 12px; }

.classroom-timetable .filter-item:last-child {
  position: absolute; }

.classroom-timetable table td {
  height: 100px !important;
  min-width: 80px;
  font-size: 12px; }

.classroom-timetable table tbody tr td:not(:first-child) {
  padding: 0 !important; }
  .classroom-timetable table tbody tr td:not(:first-child) > span {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    padding: 8px;
    color: #000 !important; }

.classroom-timetable table .orange-bg {
  padding: 0 2px;
  margin-right: 3px;
  border-radius: 2px;
  background: #F08039;
  color: #fff; }

.classroom-timetable table .bg-green {
  cursor: pointer;
  background: #E4FFE7;
  border: 1px solid #9DDEA2; }

.classroom-timetable table .bg-red {
  background: #FFDAD9;
  border: 1px solid #FFBEBC; }

.classroom-timetable table .bg-yellow {
  background: #FFF1B2;
  border: 1px solid #FFBEBC; }

.classroom-timetable table .bg-gray {
  background: #E9E9E9;
  border: 1px solid #C6C6C6; }

.teacher-saturation .filter-item:last-child {
  position: none; }
