.studyHistory .my-students-nav {
  padding: 20px 0;
  background: #fff; }
  .studyHistory .my-students-nav .menu-tab {
    height: 28px;
    border-bottom: 1px solid #e9e9e9; }
  .studyHistory .my-students-nav span {
    padding: 8px;
    cursor: pointer; }
  .studyHistory .my-students-nav span:hover {
    color: #39f;
    border-bottom: 2px solid #39f; }
  .studyHistory .my-students-nav .active {
    color: #355AA0;
    border-bottom: 2px solid #355AA0; }

.studyHistory .studySelect .state {
  margin-right: 5px; }

.studyHistory .studySelect .subject {
  margin-left: 20px; }

.studyHistory .historyList {
  margin-top: 30px; }
  .studyHistory .historyList .section-panel-header {
    display: flex;
    align-items: center; }
  .studyHistory .historyList .done {
    background: rgba(29, 161, 126, 0.25);
    color: #1da17e; }
  .studyHistory .historyList .undo {
    background: rgba(195, 204, 209, 0.25);
    color: #c3ccd1; }
  .studyHistory .historyList .doing {
    background: rgba(51, 153, 255, 0.25);
    color: #39f; }
  .studyHistory .historyList .section-panel-progress {
    display: flex;
    height: 20px;
    border-radius: 10px;
    align-items: center;
    padding: 0 10px;
    margin-right: 4px; }
  .studyHistory .historyList .section-panel-progress-number {
    background-color: #fff;
    height: 16px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 0 5px;
    margin-left: 3px; }
  .studyHistory .historyList .section-panel-slice-fraction {
    background-color: #fff; }
  .studyHistory .historyList .section-panel-icon {
    width: 20px;
    height: 20px;
    background: #fe9009;
    margin: 0 20px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff; }
    .studyHistory .historyList .section-panel-icon.custom {
      background: #1da17e; }
  .studyHistory .historyList .section-menu-header {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: relative; }
    .studyHistory .historyList .section-menu-header .line {
      border-left: 1px solid #C3CCD1;
      position: absolute;
      left: 7px;
      height: 100%;
      z-index: 1; }
    .studyHistory .historyList .section-menu-header .index {
      position: absolute;
      top: 15px;
      z-index: 999;
      width: 16px;
      height: 16px;
      margin-right: 12px;
      line-height: 14px;
      border-radius: 99px;
      border: 1px solid #C3CCD1;
      background: #EAEFF2;
      text-align: center;
      color: #29323A; }
    .studyHistory .historyList .section-menu-header .name {
      margin-left: 28px;
      font-size: 12px;
      color: #29323a; }
  .studyHistory .historyList .ant-collapse {
    border: none; }
    .studyHistory .historyList .ant-collapse .ant-collapse-item {
      border: none;
      background: #fff; }
      .studyHistory .historyList .ant-collapse .ant-collapse-item .ant-collapse-header {
        height: 47px;
        line-height: 47px;
        padding: 0 0 0 40px;
        background: #fff;
        border-bottom: none; }
        .studyHistory .historyList .ant-collapse .ant-collapse-item .ant-collapse-header .section-menu-header {
          height: 47px;
          line-height: 47px; }
  .studyHistory .historyList .collapse {
    border: 1px solid #eaeff2; }
    .studyHistory .historyList .collapse > .collapsePanel {
      border-bottom: 1px solid #eaeff2; }
      .studyHistory .historyList .collapse > .collapsePanel > .ant-collapse-header {
        height: 46px; }
      .studyHistory .historyList .collapse > .collapsePanel > .ant-collapse-content {
        border-top: 1px solid #eaeff2; }
      .studyHistory .historyList .collapse > .collapsePanel .ant-collapse .ant-collapse-item {
        width: 100%;
        position: relative; }
        .studyHistory .historyList .collapse > .collapsePanel .ant-collapse .ant-collapse-item .ant-collapse-content {
          overflow: visible;
          margin-left: 47px;
          border-left: 1px solid #C3CCD1;
          border-top: none; }
          .studyHistory .historyList .collapse > .collapsePanel .ant-collapse .ant-collapse-item .ant-collapse-content .ant-collapse-content-box {
            margin-left: -39px; }
          .studyHistory .historyList .collapse > .collapsePanel .ant-collapse .ant-collapse-item .ant-collapse-content .sliceInfo {
            height: 36px;
            line-height: 36px;
            margin-left: 15px; }
            .studyHistory .historyList .collapse > .collapsePanel .ant-collapse .ant-collapse-item .ant-collapse-content .sliceInfo .component-common-percent-ring {
              display: inline-block; }
            .studyHistory .historyList .collapse > .collapsePanel .ant-collapse .ant-collapse-item .ant-collapse-content .sliceInfo .sliceName {
              margin-left: 15px;
              color: #818c96; }
            .studyHistory .historyList .collapse > .collapsePanel .ant-collapse .ant-collapse-item .ant-collapse-content .sliceInfo .duration {
              float: right;
              color: #818c96; }
  .studyHistory .historyList .load-area {
    margin-top: 30px;
    text-align: center; }

.studyHistory .spin {
  width: 100%;
  text-align: center; }
  .studyHistory .spin .ant-spin {
    margin-top: 100px; }

.studyHistory .practiceHistory .student-filter-container {
  background: #f7f7f7;
  margin-bottom: 0;
  height: 50px;
  line-height: 28px;
  border: 1px solid #e9e9e9;
  border-bottom: none; }

.studyHistory .practiceHistory table td {
  padding-left: 20px; }

.studyHistory .practiceHistory .watchReport {
  margin-right: 10px; }
