.student-vip-course-list > * {
  margin-bottom: 25px;
  background-color: #fff;
  padding: 20px; }
  .student-vip-course-list > * > h4 {
    font-weight: 500;
    margin-bottom: 10px; }
    .student-vip-course-list > * > h4 + span {
      margin-right: 20px; }

.student-vip-course-list table {
  text-align: center; }

.student-vip-course-list .ant-table-thead > tr > th {
  text-align: center; }

.task-add {
  font-size: 14px;
  color: #666; }
  .task-add .ant-modal-content {
    width: 600px;
    border-radius: 2px; }
    .task-add .ant-modal-content .ant-modal-header {
      padding: 15px 18px;
      border-bottom: 1px solid #eee;
      font-weight: 700;
      font-size: 13px;
      border-radius: 3px 3px 0 0; }
    .task-add .ant-modal-content .ant-form-item {
      margin-bottom: 0; }
      .task-add .ant-modal-content .ant-form-item > div {
        text-align: left;
        padding: 0 0 4px 0; }
        .task-add .ant-modal-content .ant-form-item > div > label {
          font-size: 14px; }
          .task-add .ant-modal-content .ant-form-item > div > label::after {
            content: ''; }
        .task-add .ant-modal-content .ant-form-item > div .ant-input-number {
          width: 100%; }
        .task-add .ant-modal-content .ant-form-item > div .ant-time-picker {
          width: 100%; }
          .task-add .ant-modal-content .ant-form-item > div .ant-time-picker .ant-time-picker-input {
            width: 100%; }
      .task-add .ant-modal-content .ant-form-item .ant-checkbox-wrapper {
        font-size: 14px; }
    .task-add .ant-modal-content .ant-modal-body {
      padding: 16px 48px;
      font-size: 12px; }
      .task-add .ant-modal-content .ant-modal-body .modal-line label {
        margin-right: 10px; }
  .task-add .ant-modal-footer > button {
    width: 65px;
    border-radius: 4px; }
    .task-add .ant-modal-footer > button + button {
      margin-left: 18px; }
  .task-add .choose-slice {
    width: 100%;
    height: 220px;
    background: #F7F7F7;
    overflow: scroll;
    padding: 15px; }
    .task-add .choose-slice > div {
      cursor: pointer; }
      .task-add .choose-slice > div > div:first-child {
        height: 26px; }
      .task-add .choose-slice > div > div:nth-child(2) {
        float: right; }
        .task-add .choose-slice > div > div:nth-child(2) > div {
          height: 26px;
          padding-right: 15px; }
          .task-add .choose-slice > div > div:nth-child(2) > div input {
            float: right;
            margin-top: 7px; }
  .task-add .choose-course {
    width: 100%;
    height: 180px;
    background: #F7F7F7;
    overflow: auto; }
    .task-add .choose-course .tree-node {
      display: flex;
      justify-content: space-between; }
      .task-add .choose-course .tree-node > span:nth-child(2) > i {
        margin-left: 10px; }
    .task-add .choose-course .tree-node-1 {
      width: 420px; }
    .task-add .choose-course .tree-node-2 {
      width: 420px; }
    .task-add .choose-course .ant-tree li {
      padding: 0 !important; }
      .task-add .choose-course .ant-tree li .ant-tree-node-content-wrapper {
        padding: 0 !important; }
  .task-add .choose-practice {
    width: 100%;
    height: 180px;
    background: #F7F7F7;
    overflow: auto; }
    .task-add .choose-practice .tree-node {
      display: flex;
      justify-content: space-between; }
    .task-add .choose-practice .tree-node-1 {
      width: 355px; }
    .task-add .choose-practice .tree-node-2 {
      width: 335px; }
    .task-add .choose-practice .ant-tree li {
      padding: 0 !important; }
      .task-add .choose-practice .ant-tree li .ant-tree-node-content-wrapper {
        padding: 0 !important; }
  .task-add .choose-slice-practice {
    width: 100%;
    height: 180px;
    background: #F7F7F7;
    overflow: auto;
    padding: 15px; }
    .task-add .choose-slice-practice li {
      position: relative;
      line-height: 26px;
      padding-right: 5px;
      height: 26px;
      overflow: hidden; }
      .task-add .choose-slice-practice li > p > span {
        float: right; }
      .task-add .choose-slice-practice li > p > label {
        width: 240px;
        display: inline-block;
        line-height: 26px;
        text-overflow: ellipsis;
        overflow: hidden;
        height: 26px;
        white-space: nowrap; }
      .task-add .choose-slice-practice li > label {
        width: 240px;
        display: inline-block;
        line-height: 26px;
        text-overflow: ellipsis;
        overflow: hidden;
        height: 26px;
        white-space: nowrap; }
        .task-add .choose-slice-practice li > label > i {
          margin-right: 10px;
          padding-top: 8px; }
      .task-add .choose-slice-practice li > span {
        padding-left: 5px;
        float: right; }
      .task-add .choose-slice-practice li input {
        margin-left: 5px;
        margin-top: 5px; }

.new-task-add .show {
  display: block; }

.new-task-add .hide {
  display: none; }

.new-task-add .ant-modal-content .ant-form-item {
  margin-bottom: 24px; }
  .new-task-add .ant-modal-content .ant-form-item > div > label {
    font-size: 12px; }
  .new-task-add .ant-modal-content .ant-form-item .ant-form-item-control .count {
    float: left; }
  .new-task-add .ant-modal-content .ant-form-item .ant-form-item-control .check-all {
    float: right; }
    .new-task-add .ant-modal-content .ant-form-item .ant-form-item-control .check-all .ant-checkbox-wrapper {
      margin-right: 8px; }
  .new-task-add .ant-modal-content .ant-form-item .ant-form-item-control .orange {
    padding: 0 3px;
    color: #FC6238; }

.new-task-add .ant-modal-content .ant-form-item:last-child,
.new-task-add .ant-modal-content .ant-form-item:nth-last-child(2) {
  margin-bottom: 0; }

.new-task-add .ant-modal-content .ant-modal-body {
  padding: 24px 48px;
  font-size: 12px; }

.new-task-add .tree-list {
  width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.diy-task-add .ant-modal-content .ant-form-item {
  margin-bottom: 24px; }
  .diy-task-add .ant-modal-content .ant-form-item .minute {
    position: absolute;
    top: -10px;
    left: 275px; }

.diy-task-add .ant-modal-content .ant-form-item:last-child,
.diy-task-add .ant-modal-content .ant-form-item:nth-last-child(2) {
  margin-bottom: 24px; }

.tree-img {
  display: inline-block;
  height: 14px;
  width: 14px;
  margin-top: 5px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABGdBTUEAALGPC/xhBQAAAs5JREFUSA21Vk1oU0EQnt33NIL2JLQ0ij9Ei2K1J0VbRPEi1p8QQW2xGtSKWmukVy9BPUh78yAeDP7hWY2gNyFII/hzUPBglUB/YhvFHtKkIS/mvXVnk2w36XtJ6s+D0tmZb77ZnZ2dCYEq3+7eFyuNXzkvY2w/h3kIY26EM0Im+b8YIeS5a9HicCTUGUe93UfslB2nw26WN68yBqcYgGaHKek4gUkI3CO6Foze9WLgsm9egA5/+JDFrEd81w1lyBoLfpoUJbQn+sD7TIVSddHufxowmflkoeTIgT7oixwqpzwB7hwBwKAsqAquSyZgaUTzlU4iAmDOrbz1+U92bhdUpEunG/BOxG4LF7qwnO9oa4LtW5rs+EW6kBONRJSikR2tVS0qU1vLcrh1ZadQBW+/g5dvvqlmIfPUmC7XkjVU1HmNUlS9fXvWQmI6A5H3k6BRAheObFLNUsYNI7fO846PqK7Pf7AFznPC8akUBIaiYORMCEdGHX2RW+dWjyNCMZw4UCBH1armBrjWtxXOXX+lIGxFDy09f1tzUdnTuR76js6lIpkyYOj+h2ouwobcNWv+OCe/2NUqyZLpHFwajEJsYkbqqgm02LhsMd371kF/BXlgcBi+jidt8ZVK5MYTxCoNuO7a64FA92ZpmpnNwWVO/mWsPvKiY4xiy5UsinDGt1GuUpw8wNMysjByQG4d+7lhZG86PTRBzksy8TMDoeAuWNG4VAZWhf4bwxCLz92LeGicW/Si9pOP71gMelWHba2NoGkEJhJpiH+fhdXNy8DtQI5+H0emIZPNSwr+BkOvHx4+i+8AcFiQvHVMbXZvP/2QYBTGptLir0zpsMBmR3QaRLMoU9H1+LAA3modfOpXcw4xeIrTTb4D7N/cMPBXQQrkA6VZgLsSd6Bu77+OTAwkTsKHBV4SVoIa3E5GDGIpDpiKeYz4eSdQSf7Fz5bff1szsQMaG80AAAAASUVORK5CYII=) no-repeat;
  background-size: contain;
  cursor: pointer; }

.tree-img-question-type {
  position: relative;
  top: 2px;
  left: 5px; }
