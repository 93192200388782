.info-box {
  display: flex;
  padding: 20px 30px 20px 20px;
  background: #FFFFFF;
  border-radius: 4px 4px 0px 0px;
  border-bottom: 1px solid #ccc; }
  .info-box:last-child {
    border-bottom: none; }
  .info-box .teacher-info {
    display: flex;
    width: 356px;
    align-items: center; }
    .info-box .teacher-info > img {
      display: block;
      width: 68px;
      height: 68px;
      border-radius: 100px;
      overflow: hidden;
      background-image: none;
      border: 1px solid #FFEAEFF2; }
  .info-box .slider {
    flex: 1;
    overflow: hidden; }
  .info-box .operator {
    margin-left: 30px;
    padding: 11px 6px;
    width: 30px;
    color: #5279CFFF;
    font-size: 12px;
    font-weight: 500;
    line-height: 17px;
    text-align: center;
    cursor: pointer;
    background: #5279CF1A;
    border-radius: 4px;
    border: 1px solid #5279CFFF; }

.teacher-info .detail-info {
  margin: 0 34px 0 20px;
  width: 234px; }
  .teacher-info .detail-info .title {
    margin-bottom: 8px;
    font-size: 18px;
    font-weight: 600;
    color: #29323A;
    height: 18px;
    line-height: 18px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; }
  .teacher-info .detail-info .status-box {
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    height: 20px;
    font-size: 12px;
    line-height: 16px; }
    .teacher-info .detail-info .status-box .status-info {
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding: 2px;
      margin-right: 4px;
      width: 77px;
      font-weight: 500;
      background: #25B756;
      border-radius: 2px; }
      .teacher-info .detail-info .status-box .status-info span {
        height: 16px; }
        .teacher-info .detail-info .status-box .status-info span:first-child {
          color: #FFFFFF; }
        .teacher-info .detail-info .status-box .status-info span:last-child {
          width: 35px;
          text-align: center;
          background: #FFFFFF;
          color: #29323A;
          font-weight: 400;
          border-radius: 2px; }
    .teacher-info .detail-info .status-box .status-data {
      font-weight: 400; }
  .teacher-info .detail-info .desc {
    height: 36px;
    line-height: 18px;
    font-size: 12px;
    font-weight: 400;
    color: #818C96;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; }
