.goods-management .filter .filter-item label {
  font-size: 12px;
  color: #666;
  margin-right: 5px;
  padding-top: 10px; }

.goods-management .goods-add {
  font-size: 24px;
  color: red; }

.goods-management .number {
  width: 100%;
  color: red; }
