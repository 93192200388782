.component-common-form-select {
  position: relative;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none; }
  .component-common-form-select .text {
    border: 1px solid #eee;
    height: 35px;
    border-radius: 35px;
    line-height: 34px;
    cursor: pointer;
    overflow: hidden;
    font-size: 13px; }
    .component-common-form-select .text .title {
      padding: 0 10px;
      padding-left: 16px;
      display: inline-block;
      background: #f7f7f7;
      border-right: 1px solid #eee; }
    .component-common-form-select .text .option {
      display: inline-block;
      padding: 0 15px;
      padding-right: 6px; }
    .component-common-form-select .text .fa-angle-down {
      margin-right: 15px;
      vertical-align: middle;
      color: #999; }
  .component-common-form-select .list {
    display: none;
    position: absolute;
    z-index: 300;
    top: 40px;
    left: 0px;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 2px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    opacity: 0;
    transition: opacity .05s;
    max-height: 180px; }
  .component-common-form-select .mask {
    position: fixed;
    z-index: 200;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0; }

.component-common-form-select-form .text {
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  background: #f9f9f9; }
  .component-common-form-select-form .text .option {
    padding: 0 8px; }
  .component-common-form-select-form .text .fa-angle-down {
    float: right;
    margin-top: 10px;
    margin-right: 10px; }
