.common-loading {
  margin: 0 auto;
  margin-top: 8px;
  width: 85px;
  text-align: center; }

.common-loading > div {
  width: 15px;
  height: 15px;
  margin-left: 5px;
  background-color: #A6A6A6;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both; }

.common-loading .bounce1 {
  -webkit-animation-delay: -0.48s;
  animation-delay: -0.48s; }

.common-loading .bounce2 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s; }

.common-loading .bounce3 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s; }

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0); }
  40% {
    -webkit-transform: scale(1); } }

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1); } }
