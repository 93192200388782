.ant-table {
  border-radius: 0 !important;
  color: #333 !important; }
  .ant-table table {
    border: 1px solid #e9e9e9;
    border-bottom: none; }

.ant-table-body td {
  background-color: #fff;
  color: #4c4c4c; }

.ant-table-body th {
  border-bottom: 1px solid #ddd;
  font-weight: normal;
  color: #666 !important; }

.ant-table-body .antd-table-operation a {
  margin: 0 3px; }

.ant-table-body .antd-table-operation a:hover {
  color: #3ec9f6;
  text-shadow: 0 0 3px #ddd; }

.ant-table-placeholder .ant-empty-image img {
  background: none; }

.ant-table-scroll table .ant-table-fixed-columns-in-body {
  visibility: visible; }

.clearfix {
  background: none !important; }

.ant-select-disabled, .ant-input[disabled], .ant-input-number-input[disabled] {
  color: #666 !important; }

.component-common-panel .ant-menu {
  color: #333 !important; }
  .component-common-panel .ant-menu .ant-menu-item-selected {
    background-color: #343C44 !important; }
    .component-common-panel .ant-menu .ant-menu-item-selected > a {
      pointer-events: none;
      color: #fff !important; }

.component-common-panel .student-panel-container .ant-menu-item-selected {
  background-color: #385dae !important; }
  .component-common-panel .student-panel-container .ant-menu-item-selected > a {
    color: #385dae !important; }

.ant-select-dropdown {
  color: #333 !important;
  font-size: 14px; }

.ant-select-selection {
  color: #333 !important; }

.ant-confirm .ant-modal-body {
  padding: 20px !important; }
  .ant-confirm .ant-modal-body .ant-confirm-content {
    margin-left: 0; }

.ant-select-dropdown-menu-item {
  color: #333 !important;
  font-size: 14px; }

.ant-menu-horizontal > .ant-menu-item-selected {
  color: #355AA0 !important;
  border-bottom-color: #355AA0 !important; }

.ant-radio-button-wrapper-checked {
  border-color: #39f !important;
  color: #39f !important; }

.ant-pagination-item-active,
.ant-switch-checked,
.ant-btn-primary {
  background-color: #355AA0 !important;
  border-color: #355AA0 !important; }

.ant-btn-primary[disabled] {
  color: #fff !important; }

.normal-disable-btn {
  background-color: #f5f5f5 !important;
  border-color: #d9d9d9 !important; }
  .normal-disable-btn i, .normal-disable-btn span {
    color: rgba(0, 0, 0, 0.25) !important; }

.ant-calendar-selected-day .ant-calendar-date {
  background-color: #355AA0 !important; }

.title-overlay-container .ant-tooltip-content .ant-tooltip-inner {
  max-width: 400px;
  word-wrap: break-word; }

.starred-range-picker .starred-calendar-date {
  position: relative; }
  .starred-range-picker .starred-calendar-date .trangle {
    position: absolute;
    top: -1px;
    right: -1px;
    width: 0;
    height: 0;
    border-top: 10px solid red;
    border-left: 10px solid transparent; }

.ant-modal-width-800 .ant-modal {
  width: 800px !important; }

.ant-table-center .ant-table-thead > tr > th {
  text-align: center; }

.ant-table-left .ant-table-thead > tr > th {
  text-align: left; }
