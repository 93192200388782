.demo-add-task-container .ant-modal .ant-modal-content .ant-modal-header {
  color: #333;
  border-bottom: 0; }

.demo-add-task-container .ant-modal .ant-modal-content .ant-modal-body {
  padding: 30px 40px 20px; }
  .demo-add-task-container .ant-modal .ant-modal-content .ant-modal-body .demo-add-task-content .header {
    display: flex; }
    .demo-add-task-container .ant-modal .ant-modal-content .ant-modal-body .demo-add-task-content .header .left {
      margin-right: 40px; }
  .demo-add-task-container .ant-modal .ant-modal-content .ant-modal-body .demo-add-task-content .task-time {
    margin: 20px 0; }
    .demo-add-task-container .ant-modal .ant-modal-content .ant-modal-body .demo-add-task-content .task-time .red {
      color: #f63e3e; }
  .demo-add-task-container .ant-modal .ant-modal-content .ant-modal-body .demo-add-task-content .tables {
    height: 300px;
    overflow: scroll; }
    .demo-add-task-container .ant-modal .ant-modal-content .ant-modal-body .demo-add-task-content .tables .ant-spin {
      width: 100%;
      height: 100%;
      text-align: center;
      border-radius: 4px;
      padding: 30px 50px;
      margin: 90px 0; }
    .demo-add-task-container .ant-modal .ant-modal-content .ant-modal-body .demo-add-task-content .tables .table-block {
      padding: 0 20px 20px; }
      .demo-add-task-container .ant-modal .ant-modal-content .ant-modal-body .demo-add-task-content .tables .table-block > p {
        margin-bottom: 10px;
        color: #333;
        font-weight: 600; }
      .demo-add-task-container .ant-modal .ant-modal-content .ant-modal-body .demo-add-task-content .tables .table-block .ant-table-wrapper .ant-table-body table .ant-table-thead tr th {
        background: #fff; }
        .demo-add-task-container .ant-modal .ant-modal-content .ant-modal-body .demo-add-task-content .tables .table-block .ant-table-wrapper .ant-table-body table .ant-table-thead tr th span {
          color: #333;
          font-weight: 600; }
      .demo-add-task-container .ant-modal .ant-modal-content .ant-modal-body .demo-add-task-content .tables .table-block .ant-table-wrapper .ant-table-body table .ant-table-tbody tr td:not(.ant-table-selection-column) {
        text-align: left; }
        .demo-add-task-container .ant-modal .ant-modal-content .ant-modal-body .demo-add-task-content .tables .table-block .ant-table-wrapper .ant-table-body table .ant-table-tbody tr td:not(.ant-table-selection-column) .ellipsis {
          width: 250px;
          display: inline-block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap; }
  .demo-add-task-container .ant-modal .ant-modal-content .ant-modal-body footer {
    margin-top: 30px; }

.demo-add-task-container .ant-modal .ant-modal-content .ant-modal-footer {
  border-top: 0;
  text-align: center;
  padding-bottom: 30px; }
  .demo-add-task-container .ant-modal .ant-modal-content .ant-modal-footer > button {
    width: 80px;
    height: 28px;
    font-size: 12px; }
    .demo-add-task-container .ant-modal .ant-modal-content .ant-modal-footer > button + button {
      margin-left: 40px; }
