.modal_task_add_custom .ant-form-item {
  margin-bottom: 0 !important; }

.modal-task-add-custom-learning-plan .text-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }

.modal-task-add-custom-learning-plan .mr20 {
  margin-right: 20px; }

.modal-task-add-custom-learning-plan .mr15 {
  margin-right: 15px; }

.modal-task-add-custom-learning-plan .mr-3 {
  margin-right: 3px; }

.modal-task-add-custom-learning-plan .flex1 {
  flex: 1; }

.modal-task-add-custom-learning-plan .tree-container {
  height: 230px;
  background: #F7F7F7;
  overflow-y: auto;
  overflow-x: hidden; }
  .modal-task-add-custom-learning-plan .tree-container .empty-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px; }

.modal-task-add-custom-learning-plan .task-group-item.task-preview {
  width: 14px;
  height: 14px;
  cursor: pointer; }

.modal-task-add-custom-learning-plan .task-group-item.task-preview.no-preview {
  cursor: default; }

.modal-task-add-custom-learning-plan .ant-form-item {
  margin-bottom: 0 !important; }

.modal-task-add-custom-learning-plan .course {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABGdBTUEAALGPC/xhBQAAAm5JREFUSA21Vk1IVUEUPjP32gvCIKJCk6geSUi6cRNKv7sMetSqP5LCdmW4DoqotkK7yIehRpvAfNHPok0b2wXRRkteRYgbXfRD4Mt773S+0Xnc25v787IGHnPnzHe+c2bm/DxBCeNg3/OWytKvglLqKMPyQqlmwJUQczyVhRDPcg1rSq+KPbOQ24awCbsvlJqV599Qis4rIseGMTIm8IWg+8J1rk8OF2A4MmoMdPeWjgUqeMBeN0aQKQs+zQ8p5NnJkcKTMFSGF129E/2+8h/XSw4O6EAXHGHO6gngOQCkKGI0DM70LShwhHPcnEQbwJ0HXjD9N57bjOrrcuVuvIn2dvlB67tzG7GRwVFwYi10KFYWP8dFS8vmdbS/s4kevfxIS15gOFJnvho/l1u7Xeo4TwjFzrZNdPlUO43cPERtOzekEhsAHAa35OMgiVLHjq3r6d61A3Tp5B7KNWSLA3ADmU9lXwE4UtCZnl00euswdbRuzKKWlyb9s6ANZltTI929uo+unG43IusM7mxntahzKNLeji3kOtVUsqCIXF24lGq17sYI/UDRwxczVByfIs/n54wZ4HZ5r8y/zAbKs9/p9tAbmvr0NYY2Ii67KLn82kciYsvC8wMae/qBhiemE70Oq4LbRT2vVBbvxCXa3PxPevt+gQbH3tHMl29h/cRvnWjMrV+o69z4EF9rX6JGnZsc0cXXoycu6ihCs0CBqpMjFg4ucAKgDeiqx82CuNTGamXdYA7deFa6WzUPUL95Y2BVRpbJB0wvgE81WfJfWyYs6pNws8AjIRIgSxrAACvRYP7ox9CrOUGY7F/8bfkN+mUDAAG5lOgAAAAASUVORK5CYII=) no-repeat;
  background-size: contain; }

.modal-task-add-custom-learning-plan .exercise {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABGdBTUEAALGPC/xhBQAAAs5JREFUSA21Vk1oU0EQnt33NIL2JLQ0ij9Ei2K1J0VbRPEi1p8QQW2xGtSKWmukVy9BPUh78yAeDP7hWY2gNyFII/hzUPBglUB/YhvFHtKkIS/mvXVnk2w36XtJ6s+D0tmZb77ZnZ2dCYEq3+7eFyuNXzkvY2w/h3kIY26EM0Im+b8YIeS5a9HicCTUGUe93UfslB2nw26WN68yBqcYgGaHKek4gUkI3CO6Foze9WLgsm9egA5/+JDFrEd81w1lyBoLfpoUJbQn+sD7TIVSddHufxowmflkoeTIgT7oixwqpzwB7hwBwKAsqAquSyZgaUTzlU4iAmDOrbz1+U92bhdUpEunG/BOxG4LF7qwnO9oa4LtW5rs+EW6kBONRJSikR2tVS0qU1vLcrh1ZadQBW+/g5dvvqlmIfPUmC7XkjVU1HmNUlS9fXvWQmI6A5H3k6BRAheObFLNUsYNI7fO846PqK7Pf7AFznPC8akUBIaiYORMCEdGHX2RW+dWjyNCMZw4UCBH1armBrjWtxXOXX+lIGxFDy09f1tzUdnTuR76js6lIpkyYOj+h2ouwobcNWv+OCe/2NUqyZLpHFwajEJsYkbqqgm02LhsMd371kF/BXlgcBi+jidt8ZVK5MYTxCoNuO7a64FA92ZpmpnNwWVO/mWsPvKiY4xiy5UsinDGt1GuUpw8wNMysjByQG4d+7lhZG86PTRBzksy8TMDoeAuWNG4VAZWhf4bwxCLz92LeGicW/Si9pOP71gMelWHba2NoGkEJhJpiH+fhdXNy8DtQI5+H0emIZPNSwr+BkOvHx4+i+8AcFiQvHVMbXZvP/2QYBTGptLir0zpsMBmR3QaRLMoU9H1+LAA3modfOpXcw4xeIrTTb4D7N/cMPBXQQrkA6VZgLsSd6Bu77+OTAwkTsKHBV4SVoIa3E5GDGIpDpiKeYz4eSdQSf7Fz5bff1szsQMaG80AAAAASUVORK5CYII=) no-repeat;
  background-size: contain; }

.modal-task-add-custom-learning-plan .doc {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABGdBTUEAALGPC/xhBQAAAyxJREFUSA21Vk1MU0EQnt1XqEBIJIBiBQUJsYUQKOgFOHAFDQTQoAmKIJWDiQkXo4kJ8WT0ZuLFQECNBy+KJVGvHMSDUUEhAv6iUTAieiioSN9bZ5a+x9u2FESdQ9/s7Dczu/OzUwYxqLLtbubC4q9aIcQehOUyIVwEF4xN4ec1Y+yOMy7eP9Bd/YHk0YhFE5a3+l0iqJ8VAloEgBYNY8rQgM4Y9DKH1jnYU0uOFYpwUN7srzGEcR1PnawgV1ngbQKc8abBq7X9dii3L8qab5/Qhd73p8bJBumQLtmw27RuQCcnAAhQnNrBa+IZGBrT6sybSAcUcyNojK/n5NGcynA5uJty4iBAKKExY565KQm2u5JB1w2YnArAp9kf0WxLGR2UbOLCx2QpLvycXKladuWnw/HGAnDnpCgGn72cha6bY/Do+YwiNxcYGt3p3JCtbSncfwg97jU37N+jdW444yuFtJQEu1jym1MToao8Cwys5eGJ2Yh9FHBdGG+0zKJGukpeOKK5Zicca8hXxNMz8zD3fRGSk+KlHGMNpXjDYNCApy+iOmGUg1zFCi4oLO0NHkV8rmcI+gcmpaymMhtOt3qt/fZ9+TDy6is8GftiyUJMLjfb39zRNAYnjxQDnc6kUVQ2jZOM+BHMgUmEPdXiBdK1E9mOqHmChAMDGJZwolCthXjo4bKwQV3A+d5h6kxLVuJOgx1bl6s4B/kST7q1T9gLV4axhJd1aJNsY5IPVCOvJPnj53lsaIDSkBGHxqGqYhu40hKhwpsBHU1FkOCULUR24DKW67377yWv/DD2UMsqPrgRj0tOFBoaX0qYF09PMY5zcNkL1A/Em9TdNwa9/glzqXwZ5xdXbbTdBengq/dAYV6qojz+9htcujEKjyMrR+Iwl7LRZNrLDt/qMgS0KRbCFhmpCfKp0DiDd9NzQGGMRQjrfnCt3icDScOCBY1GekNWUqK3J9b7Y9fDkAaYg3eSTAaTXj0aFoBPrR24Lh5tyMETmm5Wtuj9xo2Ov3KyZLzDnAV0QLX1UPBfRyZ5lDfBYUFJokogWSwiDGE5DZiweUx6ETewG/sXf1t+A9V+R15VCKMdAAAAAElFTkSuQmCC) no-repeat;
  background-size: contain; }

.modal-task-add-custom-learning-plan .task-group-item.task-practice {
  width: 48px; }

.modal-task-add-custom-learning-plan .center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%; }

.modal-task-add-custom-learning-plan .tree-title-box {
  display: flex;
  width: 410px;
  box-sizing: border-box;
  padding-right: 20px; }
  .modal-task-add-custom-learning-plan .tree-title-box > span {
    width: 310px; }

.modal-task-add-custom-learning-plan .header {
  padding-right: 0; }

.modal-task-add-custom-learning-plan .task-info-container {
  width: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .modal-task-add-custom-learning-plan .task-info-container > strong {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center; }

.learning-plan-popover-content {
  max-width: 600px;
  font-size: 12px;
  font-weight: 600; }
