.time-slots-table-container .head {
  display: flex;
  height: 50px;
  line-height: 50px;
  background: #fafafa;
  border: 1px solid #e8e8e8; }

.time-slots-table-container .course-span {
  display: flex;
  justify-content: space-around;
  height: 35px;
  line-height: 35px;
  text-align: center;
  border-bottom: 1px solid #e9e9e9;
  font-size: 12px; }

.time-slots-table-container span {
  text-align: center; }
  .time-slots-table-container span a {
    margin: 0 4px; }

.time-slots-table-container.eight-blocks span {
  width: 12.5%; }

.time-slots-table-container.nine-blocks span {
  width: 11%; }
