.component-platform-content-course-outline {
  margin-top: 25px; }
  .component-platform-content-course-outline > .title {
    font-weight: bold; }
  .component-platform-content-course-outline > .section {
    margin-top: 15px;
    padding-top: 12px;
    border-top: 1px solid #eee; }
    .component-platform-content-course-outline > .section > .title {
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      -moz-justify-content: center;
      justify-content: center; }
    .component-platform-content-course-outline > .section > .slice {
      position: relative;
      border-left: 3px solid #eee;
      padding: 6px 0;
      padding-left: 10px;
      font-size: 13px;
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      -moz-justify-content: center;
      justify-content: center; }
      .component-platform-content-course-outline > .section > .slice:nth-child(2) {
        margin-top: 12px; }
      .component-platform-content-course-outline > .section > .slice > .circle {
        display: block;
        position: absolute;
        background: #80bfff;
        width: 9px;
        height: 9px;
        border-radius: 20px;
        left: -6px;
        top: 10px;
        border: 1px solid #fff; }
      .component-platform-content-course-outline > .section > .slice > .type {
        margin-left: 3px; }
      .component-platform-content-course-outline > .section > .slice > input {
        width: 300px;
        font-size: 13px;
        margin-left: 15px; }
  .component-platform-content-course-outline .btn {
    float: right;
    margin-left: 10px;
    font-weight: normal;
    cursor: pointer;
    transition: color .15s;
    color: #39f; }
    .component-platform-content-course-outline .btn:hover {
      color: #3ec9f6; }
  .component-platform-content-course-outline input {
    height: 18px;
    border: none;
    font-size: 14px;
    padding: 0;
    background: #fff;
    transition: background .15s;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -moz-box-flex: 1;
    -moz-flex: 1;
    -ms-flex: 1;
    flex: 1; }
    .component-platform-content-course-outline input:hover, .component-platform-content-course-outline input:focus {
      background: #f5f5f5; }
  .component-platform-content-course-outline > .empty {
    margin-top: 10px; }
