.schedule-container .filter-container {
  display: flex;
  justify-content: space-between; }
  .schedule-container .filter-container .filter-left {
    display: flex;
    font-size: 12px; }
    .schedule-container .filter-container .filter-left .ant-radio-group {
      font-size: 12px; }
    .schedule-container .filter-container .filter-left .filter-item:not(:last-of-type) {
      margin-right: 25px; }

.schedule-container .schedule-choose {
  background-color: #fff;
  border-radius: 2px;
  margin-bottom: 24px; }
  .schedule-container .schedule-choose label {
    line-height: 34px; }
  .schedule-container .schedule-choose .ant-select-enabled {
    margin-left: 0 !important; }
  .schedule-container .schedule-choose a {
    line-height: 28px;
    color: #fff;
    background: #355AA0;
    padding: 0 15px;
    border-radius: 4px;
    height: 28px; }
  .schedule-container .schedule-choose .enterRoom {
    background: #fff;
    border: 1px solid #ccc;
    color: #000; }

.schedule-container .component-common-form-select-organizations {
  margin-left: 16px; }

.schedule-container .component-common-form-select-teachers {
  margin-left: 16px; }

.schedule-container .schedule-calendar {
  position: relative;
  background: #fff;
  margin-bottom: 24px;
  border-radius: 2px; }
  .schedule-container .schedule-calendar .title {
    font-size: 16px;
    font-weight: 500;
    border-bottom: 1px solid #e6e6e6;
    padding: 8px 16px; }
  .schedule-container .schedule-calendar .content {
    padding: 8px;
    display: flex;
    justify-content: center;
    overflow: scroll; }
