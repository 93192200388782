.component-common-form-checkbox {
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  background: #f9f9f9;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none; }
  .component-common-form-checkbox .component-common-form-radio {
    border-left: 1px solid #e9e9e9;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -moz-box-flex: 1;
    -moz-flex: 1;
    -ms-flex: 1;
    flex: 1; }
    .component-common-form-checkbox .component-common-form-radio:first-child {
      border-left: 0; }
  .component-common-form-checkbox .row {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -moz-box-flex: 1;
    -moz-flex: 1;
    -ms-flex: 1;
    flex: 1;
    border-left: 1px solid #e9e9e9; }
    .component-common-form-checkbox .row:first-child {
      border-left: 0; }
    .component-common-form-checkbox .row .component-common-form-radio {
      border: none; }
