.teacher-calendar-container .teacher-calendar-choose {
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.0470588);
  padding-bottom: 16px;
  margin-bottom: 24px;
  display: flex; }
  .teacher-calendar-container .teacher-calendar-choose .ant-select-enabled {
    margin-left: 0 !important; }

.teacher-calendar-container .component-common-form-select-organizations {
  margin-left: 16px; }

.teacher-calendar-container .component-common-form-select-teachers {
  margin-left: 16px; }

.teacher-calendar-container .teacher-calendar-calendar {
  background: #fff;
  margin-bottom: 24px;
  border-radius: 2px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.0470588); }
  .teacher-calendar-container .teacher-calendar-calendar .clear {
    margin-left: 10px; }
  .teacher-calendar-container .teacher-calendar-calendar .introduce-schedule {
    font-size: 12px; }
    .teacher-calendar-container .teacher-calendar-calendar .introduce-schedule + a {
      margin: 10px 0;
      display: inline-block; }
  .teacher-calendar-container .teacher-calendar-calendar .title {
    font-size: 12px;
    padding: 8px 0px;
    font-weight: normal; }
  .teacher-calendar-container .teacher-calendar-calendar .content {
    padding: 8px 0;
    width: 100%;
    overflow: hidden; }
    .teacher-calendar-container .teacher-calendar-calendar .content .oprator button {
      background: #039be0;
      color: #fff;
      border: 1px solid #039fe5;
      padding: 0 18px;
      border-radius: 35px;
      overflow: hidden;
      cursor: pointer;
      transition: background .15s;
      font-size: 13px;
      margin-left: 16px; }
    .teacher-calendar-container .teacher-calendar-calendar .content .oprator button:focus {
      outline: inherit; }
