.con-school__group-course .filter {
  background-color: #fff;
  display: flex;
  flex-wrap: wrap; }

.con-school__group-course .create-class {
  position: absolute;
  right: 10px; }

.con-school__group-course .filter-item {
  display: flex;
  flex-wrap: wrap;
  margin-right: 20px;
  padding: 0; }
  .con-school__group-course .filter-item > label {
    padding: 7px 0;
    display: inline-block; }
  .con-school__group-course .filter-item > div {
    padding: 3px; }
  .con-school__group-course .filter-item:last-child .ant-input-search {
    outline: none; }
  .con-school__group-course .filter-item:last-child .ant-input-group-addon {
    background: #fff; }

.con-school__group-course .class-schedule table tr {
  height: 45px; }

.con-school__group-course .ant-table-content table th,
.con-school__group-course .ant-table-content table td {
  text-align: left !important; }

.con-school__group-course .container-main {
  background-color: #fff; }

.con-school__group-course .operation > a {
  margin-right: 7px; }
  .con-school__group-course .operation > a :hover {
    color: #3ec9f6; }

.con-school__group-course .ant-pagination {
  margin-top: 20px;
  float: right; }

.con-school__group-course__create-modal {
  max-height: 400px;
  overflow-y: scroll; }
  .con-school__group-course__create-modal .create-item {
    display: flex;
    margin: 8px; }
    .con-school__group-course__create-modal .create-item > div:first-child {
      padding-right: 10px;
      display: flex;
      width: 100px;
      justify-content: flex-end;
      align-self: center;
      flex-shrink: 0; }
  .con-school__group-course__create-modal .create-subjects {
    background: #f7f7f7;
    padding: 6px 0;
    margin: 0 10px; }
    .con-school__group-course__create-modal .create-subjects .create-item > div {
      width: 90px; }

.con-school__group-course__edit-modal {
  max-height: 400px;
  overflow-y: scroll; }
  .con-school__group-course__edit-modal .edit-item {
    display: flex;
    margin: 20px 8px; }
    .con-school__group-course__edit-modal .edit-item > div:first-child {
      padding-right: 10px;
      display: flex;
      width: 100px;
      justify-content: flex-end;
      align-self: center;
      flex-shrink: 0; }
  .con-school__group-course__edit-modal .edit-subjects {
    background: #f7f7f7;
    padding: 6px 0;
    margin: 0 32px; }
    .con-school__group-course__edit-modal .edit-subjects .edit-item > div {
      width: 67px; }

.choose-member .ant-checkbox-group-item {
  margin-bottom: 24px;
  width: 130px; }

.choose-member .ant-checkbox-group {
  margin: 16px 0 0 32px; }

.opreation-dp .ant-modal-content {
  height: 228px; }
  .opreation-dp .ant-modal-content .publish-delete .is-sure {
    margin-left: 40px;
    font-weight: 500;
    font-size: 14px;
    margin-top: 40px; }
    .opreation-dp .ant-modal-content .publish-delete .is-sure i {
      margin-right: 16px;
      color: #fc6238;
      font-size: 24px;
      opacity: 0.6; }
  .opreation-dp .ant-modal-content .publish-delete .get-back {
    margin-left: 80px;
    color: #666;
    font-size: 12px;
    margin-top: 10px; }
  .opreation-dp .ant-modal-content .ant-modal-footer {
    position: absolute;
    bottom: 0;
    width: 100%; }
