.componet-school__student-manage-course-request-notice {
  margin-top: 16px;
  display: flex;
  flex-direction: column; }
  .componet-school__student-manage-course-request-notice .ant-collapse-content {
    padding: 0; }
  .componet-school__student-manage-course-request-notice .title {
    width: 250px;
    background-color: #eee;
    text-align: center;
    height: 28px;
    line-height: 28px; }
  .componet-school__student-manage-course-request-notice .required::before {
    content: '*';
    color: #fc6238;
    position: relative;
    font-weight: 600;
    top: 3px; }
  .componet-school__student-manage-course-request-notice .ant-collapse-header {
    padding-left: 0 !important; }
    .componet-school__student-manage-course-request-notice .ant-collapse-header > i {
      display: none !important; }
  .componet-school__student-manage-course-request-notice .ant-checkbox-wrapper,
  .componet-school__student-manage-course-request-notice .ant-checkbox-wrapper:not(:last-child) {
    line-height: 16px;
    margin-right: 2px; }
  .componet-school__student-manage-course-request-notice .ant-checkbox-wrapper + span,
  .componet-school__student-manage-course-request-notice .ant-checkbox + span {
    padding-left: 2px;
    margin-right: 8px; }
  .componet-school__student-manage-course-request-notice .zl-calendar .header .oprator .nextWeek,
  .componet-school__student-manage-course-request-notice .zl-calendar .header .oprator .prevWeek {
    transform: none;
    -webkit-transform: none;
    cursor: pointer;
    width: 16px; }
    .componet-school__student-manage-course-request-notice .zl-calendar .header .oprator .nextWeek :hover,
    .componet-school__student-manage-course-request-notice .zl-calendar .header .oprator .prevWeek :hover {
      border: 1px solid #f7f7f7;
      color: #aaa; }
    .componet-school__student-manage-course-request-notice .zl-calendar .header .oprator .nextWeek :active,
    .componet-school__student-manage-course-request-notice .zl-calendar .header .oprator .prevWeek :active {
      border: 1px solid #eee;
      color: #666; }
  .componet-school__student-manage-course-request-notice .panel-header {
    display: flex;
    justify-content: space-between;
    padding: 0 0 0 20px; }
  .componet-school__student-manage-course-request-notice .student-info-panel:not(:last-of-type) {
    padding-left: 20px;
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid #ddd; }
  .componet-school__student-manage-course-request-notice .student-info-title {
    margin-bottom: 12px;
    border-left: 4px solid #39f;
    font-weight: 500;
    padding-left: 15px; }
  .componet-school__student-manage-course-request-notice .student-info-content {
    display: flex;
    flex-wrap: wrap; }
  .componet-school__student-manage-course-request-notice .form-item-full,
  .componet-school__student-manage-course-request-notice .form-item {
    display: flex;
    padding: 12px 0;
    width: 35%;
    font-size: 12px; }
    .componet-school__student-manage-course-request-notice .form-item-full .ant-input-sm,
    .componet-school__student-manage-course-request-notice .form-item .ant-input-sm {
      width: 200px; }
  .componet-school__student-manage-course-request-notice .form-item-full {
    width: 100%; }
  .componet-school__student-manage-course-request-notice .form-text {
    flex-shrink: 0;
    width: 84px;
    text-align: right;
    margin-right: 10px;
    line-height: 26px; }
    .componet-school__student-manage-course-request-notice .form-text .ant-select-selection {
      font-size: 12px; }
  .componet-school__student-manage-course-request-notice .teacher-calendar-calendar {
    background: #fff;
    margin-bottom: 24px;
    border-radius: 2px; }
    .componet-school__student-manage-course-request-notice .teacher-calendar-calendar .title {
      font-size: 16px;
      font-weight: 500;
      border-bottom: 1px solid #e6e6e6;
      padding: 8px 16px; }
    .componet-school__student-manage-course-request-notice .teacher-calendar-calendar .content {
      display: flex;
      justify-content: center;
      overflow: scroll; }
      .componet-school__student-manage-course-request-notice .teacher-calendar-calendar .content .oprator button {
        background: #039be0;
        color: #fff;
        border: 1px solid #039fe5;
        padding: 0 18px;
        border-radius: 35px;
        overflow: hidden;
        cursor: pointer;
        transition: background .15s;
        font-size: 13px;
        margin-left: 16px; }
      .componet-school__student-manage-course-request-notice .teacher-calendar-calendar .content .oprator button:focus {
        outline: inherit; }
  .componet-school__student-manage-course-request-notice .course-request-info > *:not(:last-child) {
    margin-bottom: 15px; }
