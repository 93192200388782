.auto-close-alert-container {
  overflow: hidden;
  transition: transform 0.3s ease;
  position: fixed;
  top: 20px;
  left: 40%;
  transform: translateX(-50%);
  z-index: 2000; }

.auto-close-alert-container.enter {
  animation: slideIn 0.3s forwards; }

.auto-close-alert-container.exit {
  animation: slideOut 0.3s forwards; }

@keyframes slideIn {
  from {
    transform: translateY(-100%);
    opacity: 0; }
  to {
    transform: translateY(0);
    opacity: 1; } }

@keyframes slideOut {
  from {
    transform: translateY(0);
    opacity: 1; }
  to {
    transform: translateY(-100%);
    opacity: 0; } }

.ant-alert-error {
  background-color: #fff2f0;
  border: 1px solid #ffccc7; }
  .ant-alert-error .ant-alert-icon {
    color: #ff4d4f !important; }
  .ant-alert-error .ant-alert-content {
    color: red; }

.ant-alert-success {
  background-color: #f6ffed;
  border: 1px solid #b7eb8f; }
  .ant-alert-success .ant-alert-icon {
    color: #52c41a !important; }
  .ant-alert-success .ant-alert-content {
    color: green; }

.ant-alert {
  box-sizing: border-box;
  margin: 0;
  color: #000000d9;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  display: flex;
  align-items: center;
  padding: 8px 15px;
  word-wrap: break-word;
  border-radius: 2px; }
  .ant-alert .ant-alert-icon {
    margin-right: 8px; }
  .ant-alert .anticon {
    display: inline-flex;
    align-items: center;
    color: inherit;
    font-style: normal;
    line-height: 0;
    text-align: center;
    text-transform: none;
    vertical-align: -0.125em;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased; }
