.mobile-modal-mask {
  height: 100%;
  position: relative;
  background: rgba(0, 0, 0, 0.5); }
  .mobile-modal-mask .mobile-modal {
    position: absolute;
    top: 30%;
    left: 50%;
    width: 280px;
    background: #fff;
    overflow: hidden;
    text-align: center;
    border-radius: 10px;
    transform: translateX(-50%);
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1); }
    .mobile-modal-mask .mobile-modal .mobile-modal-content {
      padding: 24px;
      color: #2E3236;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px; }
    .mobile-modal-mask .mobile-modal .mobile-modal-button {
      color: #3399FF;
      font-size: 14px;
      font-weight: 500;
      line-height: 52px; }
      .mobile-modal-mask .mobile-modal .mobile-modal-button:active {
        background: rgba(0, 0, 0, 0.05); }
