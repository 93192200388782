.component-common-form-textarea textarea {
  display: block;
  border-radius: 2px;
  border: 1px solid #d9d9d9;
  width: 100%;
  height: 90px;
  padding: 8px;
  font-size: 13px;
  resize: none; }
  .component-common-form-textarea textarea:focus {
    border: 1px solid #ccc; }
