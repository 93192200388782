.no-teaching-plan {
  padding: 40px;
  text-align: center; }

.archives-teaching-plan .teaching-plan-header {
  padding: 14px 20px;
  background: #FAFAFA;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px; }
  .archives-teaching-plan .teaching-plan-header .teaching-plan-header-icon {
    font-weight: normal;
    font-size: 12px; }

.archives-teaching-plan .teaching-plan-list {
  padding: 0 20px 30px; }
  .archives-teaching-plan .teaching-plan-list .teaching-plan-list-item {
    padding: 20px 0;
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 17px; }
    .archives-teaching-plan .teaching-plan-list .teaching-plan-list-item .list-row {
      white-space: nowrap;
      margin-right: 100px; }
      .archives-teaching-plan .teaching-plan-list .teaching-plan-list-item .list-row:last-child {
        margin-right: 0; }
    .archives-teaching-plan .teaching-plan-list .teaching-plan-list-item .list-row.name {
      width: 150px;
      flex-shrink: 1;
      overflow: hidden;
      text-overflow: ellipsis; }
    .archives-teaching-plan .teaching-plan-list .teaching-plan-list-item .list-row.submitter {
      width: 85px;
      text-align: center; }
