.pt_daily_container .pt-daily-back {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 50px;
  line-height: 50px;
  padding-left: 32px;
  background: #f7f7f7;
  cursor: pointer; }
  .pt_daily_container .pt-daily-back a {
    color: #282828; }

.pt_daily_container .pt_daily_header {
  padding: 10px 0;
  margin-bottom: 20px;
  background: #fff; }
  .pt_daily_container .pt_daily_header .watch-ptdaily {
    width: 100%;
    border-bottom: 1px solid #ccc;
    padding-bottom: 5px; }
    .pt_daily_container .pt_daily_header .watch-ptdaily span {
      font-size: 16px;
      font-weight: 600; }
  .pt_daily_container .pt_daily_header .get-excal {
    position: relative;
    width: 100%;
    margin-top: 15px; }
    .pt_daily_container .pt_daily_header .get-excal a {
      position: absolute;
      right: 0;
      display: inline-block;
      height: 26px;
      line-height: 26px;
      width: 100px;
      background: #355AA0;
      color: #fff;
      text-align: center;
      border-radius: 3px; }

.pt_daily_container .ptdaily_header {
  margin-bottom: 30px; }

.pt_daily_container .title {
  margin: 20px 0; }

.pt_daily_container .pt-table tr th, .pt_daily_container .pt-table tr td {
  border-right: 1px solid #eee; }
  .pt_daily_container .pt-table tr th:last-child, .pt_daily_container .pt-table tr td:last-child {
    border-right: none; }

.pt_daily_container .pt-table tr td:nth-child(2) {
  color: #999; }

.pt_daily_container table tr .ant-row {
  margin-bottom: 0; }
  .pt_daily_container table tr .ant-row textarea {
    outline: none;
    resize: none; }

.pt_daily_container .component-common-form-submit .button {
  width: 72px;
  height: 26px;
  border-radius: 3px;
  line-height: 26px; }

.pt_daily_container .component-common-form-submit .cancel {
  background: #fff;
  color: #666;
  border: solid 1px #d9d9d9; }

.pt_daily_container .component-common-form-submit .cancel:hover {
  background: #fff; }

.confirm-modal .confirm-info {
  color: red; }
