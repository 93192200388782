.banke-report {
  text-align: center; }
  .banke-report .back {
    text-align: left;
    font-size: 12px;
    cursor: pointer; }
  .banke-report .week-index {
    text-align: center; }
  .banke-report .header {
    position: relative;
    height: 50px;
    line-height: 50px; }
    .banke-report .header .status {
      position: absolute;
      top: 0;
      right: 20px;
      margin-right: 100px;
      font-size: 12px; }
    .banke-report .header .daily-name {
      font-size: 16px;
      font-weight: 600; }
  .banke-report .export-btn {
    position: absolute;
    right: 0;
    top: 0; }
  .banke-report header {
    text-align: center; }
  .banke-report table input {
    background: none;
    border: none; }
  .banke-report textarea {
    border: none;
    resize: none; }
  .banke-report textarea[disabled] {
    background: none; }
  .banke-report footer {
    margin-top: 40px;
    text-align: center; }
    .banke-report footer button {
      width: 80px;
      height: 28px;
      margin: 0 10px; }
    .banke-report footer .submit {
      color: #fff;
      background: #5279CF; }

.weekly-date .ant-modal-body {
  text-align: center; }
  .weekly-date .ant-modal-body .previous, .weekly-date .ant-modal-body .next {
    cursor: pointer; }
  .weekly-date .ant-modal-body .previous {
    margin-right: 15px; }
  .weekly-date .ant-modal-body .next {
    margin-left: 15px; }
