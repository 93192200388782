.container-school-contract-teacher .back {
  padding: 15px 15px;
  background: #fff;
  border-radius: 2px;
  box-shadow: rgba(0, 0, 0, 0.0470588) 0 1px 1px 0;
  color: #39f;
  cursor: pointer;
  transition: color .15s; }
  .container-school-contract-teacher .back:before, .container-school-contract-teacher .back:after {
    content: ' ';
    display: table;
    clear: both; }
  .container-school-contract-teacher .back:hover {
    color: #3ec9f6; }

.container-school-contract-teacher .title {
  margin-top: 25px;
  padding: 15px 15px;
  background: #fff;
  border-radius: 2px;
  box-shadow: rgba(0, 0, 0, 0.0470588) 0 1px 1px 0; }
  .container-school-contract-teacher .title:before, .container-school-contract-teacher .title:after {
    content: ' ';
    display: table;
    clear: both; }

.container-school-contract-teacher .component-common-list {
  margin-top: 25px; }

.container-school-contract-teacher .btn {
  float: right;
  line-height: 22px;
  margin-left: 15px;
  font-weight: normal;
  cursor: pointer;
  transition: color .15s;
  color: #39f; }
  .container-school-contract-teacher .btn:hover {
    color: #3ec9f6; }

.container-school-contract-teacher .empty {
  margin-top: 10px; }
