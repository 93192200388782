.wechat-account-container {
  cursor: pointer; }
  .wechat-account-container .popover-content {
    display: flex;
    align-items: center;
    line-height: 20px;
    margin: 19px 0; }
    .wechat-account-container .popover-content .follow-icon {
      width: 14px;
      margin-right: 4px;
      background: transparent; }

.wechat-account-modal .ant-modal-header {
  text-align: center;
  background: #F6F9FB; }

.wechat-account-content {
  position: relative;
  color: #29323A; }
  .wechat-account-content .title-popover {
    font-size: 14px;
    line-height: 20px;
    margin: 8px auto 12px;
    text-align: center; }
  .wechat-account-content .qrcode {
    display: block;
    margin: 0 auto; }
  .wechat-account-content .qrcode-popover {
    width: 100px;
    height: 100px;
    margin-bottom: 20px; }
  .wechat-account-content .qrcode-modal {
    width: 180px;
    height: 180px;
    margin-top: 16px;
    margin-bottom: 40px; }
  .wechat-account-content .tips {
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: pre-wrap; }
  .wechat-account-content .tips-popover {
    font-size: 12px;
    line-height: 17px;
    margin: 0 5px 16px; }
    .wechat-account-content .tips-popover:last-child {
      margin-bottom: 5px; }
  .wechat-account-content .tips-modal {
    font-size: 14px;
    line-height: 20px;
    margin: 0 auto 20px; }
  .wechat-account-content .tips-icon {
    background: transparent; }
  .wechat-account-content .tips-icon-popover {
    width: 20px;
    margin-right: 8px; }
  .wechat-account-content .tips-icon-modal {
    width: 28px;
    margin-right: 12px; }
  .wechat-account-content .checkbox-modal {
    margin-top: 40px;
    text-align: center; }
  .wechat-account-content .inset-modal {
    background: transparent;
    position: absolute;
    top: 91px;
    right: 53px;
    width: 104px; }
