.loading-mask {
  margin: 0;
  overflow: auto;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  background: #000;
  opacity: 0.5; }
  .loading-mask .ant-spin-spinning {
    display: block;
    text-align: center;
    padding-top: 25%; }
    .loading-mask .ant-spin-spinning .ant-spin-dot {
      display: inline-block; }
