.component-school-form-plan-manual {
  margin-top: 25px; }
  .component-school-form-plan-manual > .title {
    font-weight: bold; }
  .component-school-form-plan-manual .btn {
    float: right;
    line-height: 22px;
    margin-left: 15px;
    font-weight: normal;
    cursor: pointer;
    transition: color .15s;
    color: #39f; }
    .component-school-form-plan-manual .btn:hover {
      color: #3ec9f6; }
  .component-school-form-plan-manual .empty {
    margin-top: 10px; }
  .component-school-form-plan-manual .component-common-list {
    margin-top: 25px; }
