.studios .component-common-list .row .item {
  white-space: normal;
  word-wrap: break-word;
  overflow: visible; }

.live-classroom-modal .live-classroom-detail-item {
  margin-bottom: 24px;
  font-size: 12px; }
  .live-classroom-modal .live-classroom-detail-item label {
    width: 100px;
    display: inline-block;
    text-align: right;
    color: #666; }
  .live-classroom-modal .live-classroom-detail-item span {
    color: #282828; }

.live-classroom-modal .live-classroom-detail-item:last-child {
  margin-bottom: 0; }
