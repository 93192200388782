.common-daily-schedule table th > span {
  display: block !important;
  text-align: center; }

.common-daily-schedule table th .column-header {
  display: flex;
  flex-direction: column; }

.common-daily-schedule table tr:hover {
  background-color: #fff !important; }

.common-daily-schedule table td {
  padding: 16px 1px !important;
  vertical-align: top; }
  .common-daily-schedule table td > div:not(:last-child) {
    margin-bottom: 8px; }

.common-daily-schedule div[role="cell"] {
  padding: 7px; }
  .common-daily-schedule div[role="cell"] .product-type,
  .common-daily-schedule div[role="cell"] .pre-arrange,
  .common-daily-schedule div[role="cell"] .cross,
  .common-daily-schedule div[role="cell"] .group-course,
  .common-daily-schedule div[role="cell"] .course-type {
    color: #fff;
    margin-right: 2px;
    padding: 0 2px;
    border-radius: 3px; }
  .common-daily-schedule div[role="cell"] .product-type {
    background-color: #396; }
  .common-daily-schedule div[role="cell"] .pre-arrange {
    background: #39f; }
  .common-daily-schedule div[role="cell"] .cross {
    background: #ee8042; }
  .common-daily-schedule div[role="cell"] .group-course {
    background-color: #999; }
  .common-daily-schedule div[role="cell"] .course-type {
    background-color: #7734ac; }

.common-daily-schedule .unhandled {
  color: #333 !important;
  background: #EAEFF2;
  color: #000;
  border: 1px solid #C3CCD1; }
  .common-daily-schedule .unhandled .numbers {
    background: #C3CCD1; }

.common-daily-schedule .unfinished {
  background: #FFDADA;
  color: #000 !important;
  border: 1px solid #FFBEBC; }
  .common-daily-schedule .unfinished .numbers {
    background: #FF9390; }

.common-daily-schedule .completed {
  background: #CBE9FF;
  color: #000 !important;
  border: 1px solid #AFDDFF; }
  .common-daily-schedule .completed .numbers {
    background: #49A9EE; }

.common-daily-schedule .unclickable {
  cursor: default; }

.common-daily-schedule .clickable {
  cursor: pointer; }
