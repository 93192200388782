.classroom-detail {
  min-height: 100%; }
  .classroom-detail .default-father-box {
    display: flex;
    margin-bottom: 20px; }
    .classroom-detail .default-father-box .default-time {
      display: inline-block;
      width: 90px;
      font-size: 12px;
      font-weight: normal; }
    .classroom-detail .default-father-box .time-box {
      width: 100%; }
      .classroom-detail .default-father-box .time-box ul {
        margin-left: 0;
        font-size: 12px; }
        .classroom-detail .default-father-box .time-box ul li span {
          display: inline-block;
          width: 88px; }
  .classroom-detail .add-default-time {
    margin-left: 81px;
    font-size: 12px;
    color: #39f; }
  .classroom-detail .hd {
    border-bottom: 1px solid #e6e6e6;
    line-height: 40px;
    overflow: hidden;
    padding-left: 30px;
    padding-left: 0;
    text-align: left;
    margin-top: 50px; }
    .classroom-detail .hd > :first-child {
      font-size: 16px;
      font-weight: 500; }
    .classroom-detail .hd > * {
      display: inline-block;
      vertical-align: middle; }
  .classroom-detail form {
    width: 100%;
    margin-top: 20px; }
  .classroom-detail .btns {
    padding: 16px 18px;
    margin: 20px 0;
    text-align: center; }
    .classroom-detail .btns > * {
      min-width: 80px;
      margin-right: 18px;
      border-radius: 2px;
      padding: 5px 15px; }
