.container-pt-daily-list-modal-students .ant-modal-content {
  height: 320px;
  overflow-x: scroll; }

.container-pt-daily-list-modal-students .head {
  width: 100%;
  padding: 10px 0 10px 48px; }

.container-pt-daily-list-modal-students .ant-modal-body {
  display: flex;
  flex-direction: column;
  align-items: flex-end; }
  .container-pt-daily-list-modal-students .ant-modal-body .ant-table-tbody {
    text-align: left; }
    .container-pt-daily-list-modal-students .ant-modal-body .ant-table-tbody th, .container-pt-daily-list-modal-students .ant-modal-body .ant-table-tbody td {
      width: 25%; }
