.smart-swiper-container {
  display: flex;
  justify-content: space-between;
  height: 90px; }
  .smart-swiper-container .hidden {
    visibility: hidden; }
  .smart-swiper-container .visible {
    visibility: visible; }
  .smart-swiper-container .arrow {
    color: #818C96;
    display: flex;
    justify-content: center;
    align-items: center; }
  .smart-swiper-container .slides-container {
    flex: 1;
    margin: 0 17px;
    overflow: hidden;
    display: flex;
    position: relative; }
    .smart-swiper-container .slides-container > div:first-child {
      display: flex;
      position: absolute;
      left: 0; }
      .smart-swiper-container .slides-container > div:first-child {
        transition: left 800ms; }
    .smart-swiper-container .slides-container .slide {
      flex-shrink: 0;
      padding: 25px 12px 26px 16px;
      margin-right: 10px;
      height: 90px;
      border: 1px solid #C3CCD1;
      border-radius: 4px;
      cursor: pointer;
      width: 147px;
      transition: visibility 300ms; }
      .smart-swiper-container .slides-container .slide > .outline-block {
        display: block; }
      .smart-swiper-container .slides-container .slide > :first-child {
        font-size: 14px;
        font-weight: 600;
        color: #2A303C; }
        .smart-swiper-container .slides-container .slide > :first-child > * {
          display: inline-block;
          vertical-align: middle; }
      .smart-swiper-container .slides-container .slide > :last-child {
        color: #818C96;
        font-size: 12px;
        margin-top: 6px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis; }
      .smart-swiper-container .slides-container .slide a {
        display: inline-block;
        width: 22px;
        height: 14px;
        background: url("//media8.smartstudy.com/zhikewang/images/msk/msk_list_play_btn.png") 50%/cover no-repeat;
        box-shadow: 0 5px 10px 0 rgba(51, 153, 255, 0.3);
        margin-left: 7px;
        vertical-align: middle; }
      .smart-swiper-container .slides-container .slide:last-child {
        margin-right: 0; }
