.smart-adaptation-study-practices {
  padding-top: 60px; }
  .smart-adaptation-study-practices .smart-adaptation-study-practices-title {
    font-size: 16px;
    color: #29323a;
    text-align: center;
    font-weight: 500; }
  .smart-adaptation-study-practices .smart-adaptation-study-practices-links {
    text-align: center;
    overflow: hidden;
    padding-bottom: 40px; }
    .smart-adaptation-study-practices .smart-adaptation-study-practices-links .link-block {
      position: relative;
      margin-top: 39px;
      display: inline-block; }
      .smart-adaptation-study-practices .smart-adaptation-study-practices-links .link-block .hot {
        width: 40px;
        height: 20px;
        position: absolute;
        right: 9px;
        top: 12px;
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAAAoCAMAAABevo0zAAAAzFBMVEUAAADyQjbyQTT/b0zxQTXxQjXxQTTwQzbyQTTwQjTxQjXxQjTxQjXxQjTwQTTyQTTyQzbyRTjxQTX2RjXyRDfyQjXwQTT////70c77y8f3pJ384d/wRjr+/f3xSz/5tbD+9/f839z0dm3719T5vbn4pqD1ioLzb2XyXlTxVEj98fD6wbz5saz4rKb3mpP2kor1gnr0fXTzaV796+r71NH6x8P5urXyZVrxUUX++vr95eP3pZ/3nZb1hXz+8/P96ef829nxWEz2job0eG+3bn1AAAAAFnRSTlMAeCcE++zFnU712NOxj4liEzuoHU86sdexAQAAAiRJREFUSMetlutS4kAQhbkIiojX3TMBkkAiQSCKIFfB6+77v9N20gwddsqUVc73I3USug6p7ulTKey5adRr+DEVbVcu1mCDqja8hh2OC0wRliiyXwm2OGHDBmxRYsM6LHFU5hEfwRKntlt4xoa/YItz89Cg5zjOkOWAZCdV/p+43R3dRiskRI7AJZoLNjyG4HpKdcFMlFLL5Nm2qxiv49L9Rh3Qw57fbFiF8EQVt2Ai0mNg/aGEGPA9JXCJ5pINKxD6VNEBsyAdwg8UMY8e4+dETLFuJcyTf07V4P9ouESGB6p7BDMjPUSHrm8TEGGX5EL3Whea0XCFDA7V3bFckxxRD4iQnyyVNDjQhWY0XCDDG9X5LO9INtHL9OBV7X91PS3NaDiH8JmMsskEpB/8pHErMFNF8JlakXr/IhrOIPxVh/QTjw2yb+juh7f4IhpOIbyoQ1ZbPotMi25mMrzWN6IhTg5DkyH57N7T9R47PuTGITn5RjS0qW7AckAyABvqJzLZkS40o+EkZ/F6aQ82mSM1ZzmUA2RGQ+7iheloQLhJ27wnpIRSaEZD7uK5szQTJuE4UMQLmLEUCldmNJiLh6mnhC3MxTOjIW/xiH5X271PAXPxcqNh3m63/V07SS55AFHgkXn86kIMuTAnGvJxP33kYESDFSQaLCHRYAmJBjvY/2qo2/5qaEg02KFk/VNTosEG14UdVdigVixrw4oFt3rjpqD5BwCtDK9iZ/KeAAAAAElFTkSuQmCC);
        background-size: 100%; }
      .smart-adaptation-study-practices .smart-adaptation-study-practices-links .link-block a {
        display: block;
        text-align: center;
        color: #29323a;
        text-decoration: none;
        font-size: 16px; }
      .smart-adaptation-study-practices .smart-adaptation-study-practices-links .link-block .img-link {
        margin-bottom: 15px;
        width: 160px;
        height: 160px;
        background-size: 100%;
        transition: all .2s ease; }
        .smart-adaptation-study-practices .smart-adaptation-study-practices-links .link-block .img-link:hover {
          transform: scale(1.1); }
      .smart-adaptation-study-practices .smart-adaptation-study-practices-links .link-block .img-link-tpo {
        background-image: url(//dev-static.smartstudy.com/apollon/assets/images/tpo-cover.fa33251.png); }
      .smart-adaptation-study-practices .smart-adaptation-study-practices-links .link-block .img-link-tpoplus {
        background-image: url(//dev-static.smartstudy.com/apollon/assets/images/tpo-plus-cover.7dcead6.png); }
      .smart-adaptation-study-practices .smart-adaptation-study-practices-links .link-block .img-link-og {
        background-image: url(//dev-static.smartstudy.com/apollon/assets/images/og-cover.2ebecdd.png); }
      .smart-adaptation-study-practices .smart-adaptation-study-practices-links .link-block .img-link-words {
        background-image: url(//dev-static.smartstudy.com/apollon/assets/images/words-cover.e4b2206.png); }
      .smart-adaptation-study-practices .smart-adaptation-study-practices-links .link-block .img-link-recall-jijing {
        background-image: url(//dev-static.smartstudy.com/apollon/assets/images/recall-jijing-cover.fdbdf5b.png); }
      .smart-adaptation-study-practices .smart-adaptation-study-practices-links .link-block .img-link-eg {
        background-image: url(//dev-static.smartstudy.com/apollon/assets/images/eg-cover.9b92d5b.png); }
      .smart-adaptation-study-practices .smart-adaptation-study-practices-links .link-block .img-link-cambradge {
        background-image: url(//dev-static.smartstudy.com/apollon/assets/images/cambridge-cover.f257cab.png); }
      .smart-adaptation-study-practices .smart-adaptation-study-practices-links .link-block .img-link-ielts_alternation {
        background-image: url(//dev-static.smartstudy.com/apollon/assets/images/ielts_alternation-cover.17a033b.png); }
      .smart-adaptation-study-practices .smart-adaptation-study-practices-links .link-block .img-link-jijing {
        background-image: url(//dev-static.smartstudy.com/apollon/assets/images/jijing-cover.28482f4.png); }
      .smart-adaptation-study-practices .smart-adaptation-study-practices-links .link-block .img-link-official-150 {
        background-image: url(//dev-static.smartstudy.com/apollon/assets/images/gre-150.0146027.png); }
      .smart-adaptation-study-practices .smart-adaptation-study-practices-links .link-block .img-link-official-issue {
        background-image: url(//dev-static.smartstudy.com/apollon/assets/images/gre-issue.9ac7cdc.png); }
      .smart-adaptation-study-practices .smart-adaptation-study-practices-links .link-block .img-link-argument {
        background-image: url(//dev-static.smartstudy.com/apollon/assets/images/gre-argue.584e4a3.png); }
      .smart-adaptation-study-practices .smart-adaptation-study-practices-links .link-block .img-link-sanqian {
        background-image: url(//dev-static.smartstudy.com/apollon/assets/images/gre-sanqian.bb0c17d.png); }
      .smart-adaptation-study-practices .smart-adaptation-study-practices-links .link-block .img-link-prep {
        background-image: url(//dev-static.smartstudy.com/apollon/assets/images/gmat-prep.85346fd.png); }
      .smart-adaptation-study-practices .smart-adaptation-study-practices-links .link-block .img-link-hongbaoshu {
        background-image: url(//dev-static.smartstudy.com/apollon/assets/images/gmat-hongbaoshu.248a774.png); }
