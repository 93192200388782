.student-filter-container {
  display: flex;
  margin: 20px 0;
  padding: 11px 0 11px 40px;
  background: #fff; }
  .student-filter-container .student-filter-block {
    color: #29323a;
    font-size: 12px;
    font-weight: 600; }
    .student-filter-container .student-filter-block .anticon {
      margin-left: 2px;
      color: #818c96; }
  .student-filter-container .ant-dropdown-trigger {
    cursor: pointer; }
