.loading-position {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

.line-scale-pulse-out {
  transform: scale(1);
  text-align: center; }

.line-scale-pulse-out > div {
  background-color: #355AA0;
  width: 4px;
  height: 35px;
  border-radius: 2px;
  margin: 2px;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  display: inline-block;
  -webkit-animation: lineScalePulseOut 0.9s -0.6s infinite cubic-bezier(0.85, 0.25, 0.37, 0.85);
  animation: lineScalePulseOut 0.9s -0.6s infinite cubic-bezier(0.85, 0.25, 0.37, 0.85); }

.line-scale-pulse-out.white-loading > div {
  background-color: #fff; }

.line-scale-pulse-out > div:nth-child(2), .line-scale-pulse-out > div:nth-child(4) {
  -webkit-animation-delay: -0.4s !important;
  animation-delay: -0.4s !important; }

.line-scale-pulse-out > div:nth-child(1), .line-scale-pulse-out > div:nth-child(5) {
  -webkit-animation-delay: -0.2s !important;
  animation-delay: -0.2s !important; }

@-webkit-keyframes lineScalePulseOut {
  0% {
    -webkit-transform: scaley(1);
    transform: scaley(1); }
  50% {
    -webkit-transform: scaley(0.4);
    transform: scaley(0.4); }
  100% {
    -webkit-transform: scaley(1);
    transform: scaley(1); } }

@keyframes line-scale-pulse-out {
  0% {
    -webkit-transform: scaley(1);
    transform: scaley(1); }
  50% {
    -webkit-transform: scaley(0.4);
    transform: scaley(0.4); }
  100% {
    -webkit-transform: scaley(1);
    transform: scaley(1); } }
