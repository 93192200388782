.container-school-structure-list {
  padding: 52px 32px 32px !important; }
  .container-school-structure-list .structure {
    background: #fff;
    border-radius: 2px; }
    .container-school-structure-list .structure:before, .container-school-structure-list .structure:after {
      content: ' ';
      display: table;
      clear: both; }
    .container-school-structure-list .structure .item {
      position: relative;
      margin-top: 25px; }
      .container-school-structure-list .structure .item:before, .container-school-structure-list .structure .item:after {
        content: ' ';
        display: table;
        clear: both; }
      .container-school-structure-list .structure .item .total-line {
        margin-bottom: 25px;
        padding-bottom: 25px;
        border-bottom: 1px solid #e9e9e9; }
        .container-school-structure-list .structure .item .total-line .name {
          font-weight: 600; }
      .container-school-structure-list .structure .item .children {
        padding-left: 22px;
        font-weight: 600;
        font-size: 12px; }
      .container-school-structure-list .structure .item .shorter-children {
        padding-left: 16px; }
      .container-school-structure-list .structure .item .circle {
        position: absolute;
        z-index: 10;
        width: 9px;
        height: 9px;
        background: #47c4fd;
        border-radius: 20px;
        top: 5px;
        border: 1px solid #fff;
        display: none; }
      .container-school-structure-list .structure .item .heavy-border-left {
        padding-left: 8px;
        border-left: 4px solid #39f;
        font-size: 14px; }
      .container-school-structure-list .structure .item .line {
        position: absolute;
        z-index: 9;
        left: -27px;
        height: 20px;
        width: 3px;
        background: #eee;
        display: none; }
      .container-school-structure-list .structure .item .line-horizontal {
        width: 100px;
        height: 3px;
        display: none; }
      .container-school-structure-list .structure .item .btn {
        float: right;
        font-weight: normal;
        font-size: 12px;
        cursor: pointer;
        transition: color .15s;
        margin-left: 10px;
        color: #39f; }
        .container-school-structure-list .structure .item .btn:hover {
          color: #3ec9f6; }
    .container-school-structure-list .structure .dashed-bottom {
      padding-bottom: 24px;
      border-bottom: 1px dashed #e9e9e9; }

.question-sure {
  padding: 30px 100px; }
  .question-sure .question-circle {
    color: #ffbf00;
    font-size: 20px;
    margin-right: 15px; }
  .question-sure .delete-sure {
    font-weight: 600;
    font-size: 16px; }
