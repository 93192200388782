.banke-basic-wrapper .banke-detail-basic {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  padding-left: 5%;
  padding-right: 5%;
  color: rgba(0, 0, 0, 0.65);
  font-size: 12px; }
  .banke-basic-wrapper .banke-detail-basic > * {
    align-self: stretch; }
  .banke-basic-wrapper .banke-detail-basic .caption {
    margin-top: 2em;
    margin-bottom: 10px;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: #e9e9e9;
    padding-top: 5px;
    padding-bottom: 5px;
    display: flex; }
    .banke-basic-wrapper .banke-detail-basic .caption > * {
      display: flex; }
    .banke-basic-wrapper .banke-detail-basic .caption .content {
      justify-content: space-between;
      align-items: center;
      width: 90%; }
      .banke-basic-wrapper .banke-detail-basic .caption .content > .left {
        font-size: 14px;
        color: #355aa0;
        flex: 1; }
      .banke-basic-wrapper .banke-detail-basic .caption .content > .middle {
        color: #6b6b6b;
        font-size: 14px;
        flex: 1; }
      .banke-basic-wrapper .banke-detail-basic .caption .content > .right-hidden {
        visibility: hidden;
        flex: 1; }
    .banke-basic-wrapper .banke-detail-basic .caption .btn-container {
      width: 10%; }
  .banke-basic-wrapper .banke-detail-basic .servicePt {
    align-self: flex-start;
    width: 15em; }
  .banke-basic-wrapper .banke-detail-basic .selectServicePT {
    margin-top: 5px; }
  .banke-basic-wrapper .banke-detail-basic .selectTeachPT {
    padding: 20px 0 0 10px; }
    .banke-basic-wrapper .banke-detail-basic .selectTeachPT .status {
      display: flex;
      margin-bottom: 20px; }
      .banke-basic-wrapper .banke-detail-basic .selectTeachPT .status h5 {
        width: 180px; }
    .banke-basic-wrapper .banke-detail-basic .selectTeachPT .total {
      display: inline-block;
      width: 80px;
      text-align: right; }
    .banke-basic-wrapper .banke-detail-basic .selectTeachPT .productInfo {
      display: flex;
      margin-bottom: 15px; }
      .banke-basic-wrapper .banke-detail-basic .selectTeachPT .productInfo > div {
        width: 300px; }
    .banke-basic-wrapper .banke-detail-basic .selectTeachPT .info {
      display: flex; }
      .banke-basic-wrapper .banke-detail-basic .selectTeachPT .info > div {
        display: flex;
        width: 300px;
        margin-bottom: 15px; }
        .banke-basic-wrapper .banke-detail-basic .selectTeachPT .info > div .subject {
          width: 85px;
          text-align: right; }
  .banke-basic-wrapper .banke-detail-basic .footer-btns {
    text-align: center; }
    .banke-basic-wrapper .banke-detail-basic .footer-btns button {
      width: 80px;
      height: 28px;
      margin: 0 10px; }
  .banke-basic-wrapper .banke-detail-basic .subjectHourTable {
    flex-direction: column;
    margin-top: 5px; }
    .banke-basic-wrapper .banke-detail-basic .subjectHourTable * {
      display: flex; }
    .banke-basic-wrapper .banke-detail-basic .subjectHourTable .row {
      width: 60%;
      height: 36px;
      justify-content: space-between; }
      .banke-basic-wrapper .banke-detail-basic .subjectHourTable .row .subject-container {
        flex: 1; }
        .banke-basic-wrapper .banke-detail-basic .subjectHourTable .row .subject-container .subject {
          margin-right: 5px; }
      .banke-basic-wrapper .banke-detail-basic .subjectHourTable .row .hour-container {
        flex: 1; }
  .banke-basic-wrapper .banke-detail-basic .ant-form .ant-form-item {
    font-size: 12px; }
    .banke-basic-wrapper .banke-detail-basic .ant-form .ant-form-item .ant-form-item-control .area-code-container {
      margin-top: 0;
      overflow: hidden; }
      .banke-basic-wrapper .banke-detail-basic .ant-form .ant-form-item .ant-form-item-control .area-code-container .ant-input {
        border-radius: 0;
        border: 0;
        border-left: 1px solid #ddd; }
        .banke-basic-wrapper .banke-detail-basic .ant-form .ant-form-item .ant-form-item-control .area-code-container .ant-input:focus {
          border-color: white; }
      .banke-basic-wrapper .banke-detail-basic .ant-form .ant-form-item .ant-form-item-control .area-code-container .ant-input-group-addon {
        padding: 0;
        border-radius: 0;
        border: 0; }
      .banke-basic-wrapper .banke-detail-basic .ant-form .ant-form-item .ant-form-item-control .area-code-container .area-code-select-container {
        width: 33px; }
        .banke-basic-wrapper .banke-detail-basic .ant-form .ant-form-item .ant-form-item-control .area-code-container .area-code-select-container .area-code-select {
          width: 280px;
          height: 28px;
          left: 3px;
          top: -9px; }
  .banke-basic-wrapper .banke-detail-basic .ant-form .required label:before {
    display: inline-block;
    margin-right: 4px;
    content: "*";
    line-height: 1;
    font-size: 18px;
    color: #f04134;
    vertical-align: middle; }
  .banke-basic-wrapper .banke-detail-basic .base_info_form .ant-form-item-label {
    text-align: left;
    vertical-align: middle;
    line-height: 39.9999px;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap; }
    .banke-basic-wrapper .banke-detail-basic .base_info_form .ant-form-item-label label {
      font-size: 12px;
      color: rgba(0, 0, 0, 0.65); }
