.banke-list .filter-label {
  font-size: 12px; }

.banke-list .ant-select {
  margin-top: 0 !important; }

.banke-list .add-button {
  float: right; }

.banke-list .ant-table-wrapper {
  margin-top: 40px; }
  .banke-list .ant-table-wrapper table {
    font-size: 12px; }
  .banke-list .ant-table-wrapper .operation-button a {
    margin-right: 10px; }

.banke-list .ant-table-thead > tr > th, .banke-list .ant-table-tbody > tr > td {
  padding: 16px 8px; }

.ant-modal-content {
  overflow: hidden; }

.banke-teachPT .ant-modal-body {
  padding: 40px; }
  .banke-teachPT .ant-modal-body > div {
    margin-bottom: 15px; }
    .banke-teachPT .ant-modal-body > div > span {
      display: inline-block;
      width: 50%; }

.banke-teachPT-arrange {
  height: 300px;
  overflow-y: scroll; }
  .banke-teachPT-arrange .total-hour {
    float: right;
    margin-right: 40px; }
  .banke-teachPT-arrange .banke-arrange-teachPT {
    margin-top: 15px; }
    .banke-teachPT-arrange .banke-arrange-teachPT label {
      display: inline-block;
      width: 70px; }
    .banke-teachPT-arrange .banke-arrange-teachPT .ant-select {
      width: 200px;
      margin-right: 15px; }
    .banke-teachPT-arrange .banke-arrange-teachPT .ant-input-number {
      width: 80px;
      margin-right: 10px; }

.container-school__teaching-req-list .ant-table-scroll thead tr th:last-child span, .banke-list .ant-table-scroll thead tr th:last-child span {
  display: none; }

.container-school__teaching-req-list .ant-table-scroll tbody tr td:last-child a, .banke-list .ant-table-scroll tbody tr td:last-child a {
  display: none; }

.ant-form-item-with-help {
  margin-bottom: 24px; }
