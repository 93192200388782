.component-school-modal-question-answer {
  height: 435px;
  width: 400px;
  background: #fff;
  border-radius: 0 0 4px 4px; }
  .component-school-modal-question-answer .list {
    position: relative;
    padding: 20px;
    height: 300px;
    border-bottom: 1px solid #eee;
    overflow: auto; }
    .component-school-modal-question-answer .list .item {
      position: relative;
      z-index: 20; }
      .component-school-modal-question-answer .list .item:last-child {
        border-bottom: none; }
      .component-school-modal-question-answer .list .item .info {
        width: 100px;
        padding: 20px;
        padding-bottom: 18px;
        background: #fff;
        text-align: center; }
        .component-school-modal-question-answer .list .item .info img {
          width: 32px;
          height: 32px;
          border-radius: 9999px;
          background: #fff; }
        .component-school-modal-question-answer .list .item .info span {
          display: block;
          margin-top: 10px;
          text-align: center;
          font-size: 14px; }
      .component-school-modal-question-answer .list .item .arrow {
        display: inline-block;
        height: 14px;
        width: 8px;
        border-radius: 4px; }
        .component-school-modal-question-answer .list .item .arrow img {
          width: 100%; }
      .component-school-modal-question-answer .list .item .teacherArrow {
        margin: 13px 0 0 -2px;
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAcCAYAAABoMT8aAAAAAXNSR0IArs4c6QAAAdpJREFUOBFjCKo6P7Wh4T8TA5mAieE/Q9blXxfWhfY95iTHDLDN//8z+P99/WZvXNt1YVINQXa65ZfPP4+F1lxUJMUQZAOA+v6r/f33/3ho7SVjYg1BMwBkxn/xf3/+HQypuuRJjCGYBoDMYPjP/Y/h76bgqgtJhAzBagBY038Glv///88NrrxQh88Q3AZAdQFd0whMK7OBaYUFm0EEDYC6JuXyzwsbY7svcqMbQpwBQF3/GRi8vr3/dyC24aIYsiFEGwDSBExwJt9+/j8eXHNNFWYISQaADWH4r/T/389j4dUXLUB8xqDK80DXkQ4YGRi+MzEzRZDsAphVQFs5//3750G2AUyMjHVr2wyzsMYtzBasNCPDH6B46po2gwUgeZIMYGRk/ALUE7K2zWAnSDMIEG0AIwPjCxYGRu+VbfrnIFohJFFhALT5Jiv7f0t0zUS5gJGR4Rg3D7vfoirNt8g2w9j4XcDIuJ6XTcAZl2aQITgNADp7qh6bfsiCBsUfMNuw0VgCkfE/EyNDJTCaOtdi04EmhmIA0NZfjIz/k9a0Gi5FU4eTCzcAGFifmJgYA1e3GOzDqRqLBNgAoM1PGRiYvVa36F7CogavENC7jFdZGZkt17aRrhlkMgA1FI06RPmaaAAAAABJRU5ErkJggg==) no-repeat;
        background-size: contain; }
      .component-school-modal-question-answer .list .item .studentArrow {
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAcCAYAAABoMT8aAAAAAXNSR0IArs4c6QAAAaRJREFUOBGdlCtPxEAQx3fKdWlBkoBCQAISAwIsmAMS1AkSHAEB4QOg7lucg4RvwMPhwPQcCoNDIDAIEtJ99Ogwc0cv5ejzVnS2M/P/7XR2uyLUtoOIjhh3hNpgaMwNQfyxGH0AQZSxAUFm6kKGpZN4Q1sbKMSFOpAhgEWIYhlN1LXWrlaF/AEMRDj3jeJR6952FUgGgCvBaYT4Tll7WAbJBLCIIA2M8VKZqF0EAd6FogSOgYALb9I9AYDeaG5uBelEFHhEO3TLn5b287wSgBNph3aUjR4IMsvvyagM6AsQ17SxXa1xaTwAqahhi7GIAjor6wyp1MRktbSlhipAZ7+RdtaZUy/o54ub9XqQWgHAafuePK1dQf8sgDj2pXvFvFoAEn+BAy3Pde9ZzKMO4H0CxK503aeBdPCsBAAQL0LKpgR4TYt5XgqgsgNPuntkP0bF/F68CyCuSbyVJy4BQMeXskVinbVy4vtXAQlQgHM+5ckzmsdJYq5NbmW2dDMbZcxBbnJWIAGQ+FNF0WZWTqHvF/BmjFkpTMwL0srPIeJ8XrzM/wPJesUWSswskAAAAABJRU5ErkJggg==) no-repeat;
        background-size: contain;
        transform: rotate(180deg); }
      .component-school-modal-question-answer .list .item .student-content, .component-school-modal-question-answer .list .item .teacher-content {
        border-radius: 4px;
        display: inline-block; }
      .component-school-modal-question-answer .list .item .content {
        margin: 20px 20px 20px 0;
        padding: 10px 20px 20px 20px;
        -webkit-box-flex: 1;
        -webkit-flex: 1;
        -moz-box-flex: 1;
        -moz-flex: 1;
        -ms-flex: 1;
        flex: 1;
        border: 1px solid #e9e9e9;
        border-radius: 6px;
        width: 308px;
        white-space: wrap;
        word-break: break-all; }
        .component-school-modal-question-answer .list .item .content .info {
          padding-bottom: 5px;
          margin-bottom: 5px;
          color: #333;
          font-size: 14px;
          border-bottom: 1px solid #eee; }
          .component-school-modal-question-answer .list .item .content .info .it {
            margin-left: 8px; }
            .component-school-modal-question-answer .list .item .content .info .it:first-child {
              margin-left: 0; }
    .component-school-modal-question-answer .list .student-reply-item {
      margin-bottom: 20px;
      display: flex;
      align-items: end; }
      .component-school-modal-question-answer .list .student-reply-item .info-left {
        margin-right: 8px;
        width: 32px;
        height: 32px;
        border-radius: 99px; }
      .component-school-modal-question-answer .list .student-reply-item .info-right {
        font-size: 12px;
        color: #818c96; }
        .component-school-modal-question-answer .list .student-reply-item .info-right .name {
          display: block;
          margin-bottom: 4px; }
        .component-school-modal-question-answer .list .student-reply-item .info-right .student-content {
          max-width: 273px;
          padding: 12px;
          color: #29323a;
          background-color: #f6f8f9; }
    .component-school-modal-question-answer .list .teacher-reply-item {
      margin-bottom: 20px;
      display: flex;
      justify-content: flex-end;
      align-items: end; }
      .component-school-modal-question-answer .list .teacher-reply-item .teacher-content {
        max-width: 273px;
        padding: 12px;
        color: #fff;
        background-color: #5279cf;
        border-radius: 4px; }
      .component-school-modal-question-answer .list .teacher-reply-item .info-right {
        margin-left: 8px;
        width: 32px;
        border-radius: 99px; }
    .component-school-modal-question-answer .list .left-bg {
      position: absolute;
      z-index: 10;
      width: 110px;
      height: 299px;
      background: #fff;
      left: 0; }
  .component-school-modal-question-answer .reply {
    padding: 20px;
    text-align: center;
    background: #fff;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center; }
    .component-school-modal-question-answer .reply .textarea textarea {
      width: 360px;
      height: 60px;
      border: 0;
      padding: 10px;
      resize: none;
      outline: none;
      background: #fff;
      border-radius: 3px;
      -webkit-box-flex: 1;
      -webkit-flex: 1;
      -moz-box-flex: 1;
      -moz-flex: 1;
      -ms-flex: 1;
      flex: 1; }
    .component-school-modal-question-answer .reply .textarea .ant-btn {
      float: right; }
  .component-school-modal-question-answer .replyBtn {
    padding: 10px;
    display: flex;
    flex-direction: column-reverse;
    border-top: 1px solid #e9e9e9; }
    .component-school-modal-question-answer .replyBtn .btn {
      margin-left: 385px;
      display: block;
      width: 74px;
      height: 32px;
      background: #355AA0;
      cursor: pointer;
      line-height: 32px;
      color: #fff;
      text-align: center;
      border-radius: 3px;
      transition: background .15s;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none; }
      .component-school-modal-question-answer .replyBtn .btn:hover {
        background: #3960ab; }
  .component-school-modal-question-answer .align-right {
    margin-right: 5px;
    margin-bottom: 4px;
    color: #818c96;
    float: right; }
