.smart-adaptation-resource-err {
  margin: calc(27% - 120px) auto;
  text-align: center; }
  .smart-adaptation-resource-err img {
    width: 80px;
    background: transparent; }
  .smart-adaptation-resource-err p {
    margin-top: 15px;
    font-size: 12px; }
  .smart-adaptation-resource-err button {
    margin: 14px;
    width: 80px; }
