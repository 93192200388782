.knowledge-type-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 51px;
  font-family: PingFangSC-Medium, PingFang SC;
  background: #FFFFFF;
  border-radius: 4px; }
  .knowledge-type-container .type-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 70px;
    font-size: 16px;
    font-weight: 400;
    color: #29323A;
    cursor: pointer; }
    .knowledge-type-container .type-info span {
      display: block; }
    .knowledge-type-container .type-info span:first-child {
      height: 22px;
      line-height: 22px;
      margin-bottom: 2px; }
    .knowledge-type-container .type-info span:last-child {
      width: 14px;
      visibility: hidden;
      border-bottom: 3px solid #FFFFFF; }
  .knowledge-type-container .selectedType {
    color: #5279CF; }
    .knowledge-type-container .selectedType span:first-child {
      font-size: 18px;
      font-weight: 500; }
    .knowledge-type-container .selectedType span:last-child {
      visibility: visible;
      border-bottom-color: #5279CF; }
