.component-common-form-datetime {
  position: relative;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none; }
  .component-common-form-datetime .text {
    border: 1px solid #eee;
    height: 35px;
    border-radius: 35px;
    line-height: 34px;
    cursor: pointer;
    overflow: hidden;
    font-size: 13px; }
    .component-common-form-datetime .text .title {
      padding: 0 15px;
      padding-left: 16px;
      display: inline-block;
      background: #f7f7f7;
      border-right: 1px solid #eee; }
    .component-common-form-datetime .text .option {
      display: inline-block;
      padding: 0 15px;
      padding-right: 6px; }
    .component-common-form-datetime .text .fa-angle-down {
      margin-right: 15px;
      vertical-align: middle;
      color: #999; }
  .component-common-form-datetime .content {
    position: absolute;
    z-index: 300;
    width: 247px;
    top: 40px;
    left: 0;
    background: #fff;
    border: 1px solid #D9D9D9;
    border-radius: 2px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); }
    .component-common-form-datetime .content .date {
      border-bottom: 1px solid #eee; }
      .component-common-form-datetime .content .date .header {
        height: 50px;
        line-height: 50px;
        border-bottom: 1px solid #eee;
        text-align: center; }
        .component-common-form-datetime .content .date .header:before, .component-common-form-datetime .content .date .header:after {
          content: ' ';
          display: table;
          clear: both; }
        .component-common-form-datetime .content .date .header .fa {
          vertical-align: text-bottom;
          line-height: 45px;
          font-size: 16px;
          cursor: pointer; }
        .component-common-form-datetime .content .date .header .fa-chevron-circle-left {
          float: left;
          margin-left: 10px; }
        .component-common-form-datetime .content .date .header .fa-chevron-circle-right {
          float: right;
          margin-right: 10px; }
        .component-common-form-datetime .content .date .header select {
          margin-left: 10px; }
          .component-common-form-datetime .content .date .header select:nth-child(2) {
            margin-left: 0; }
      .component-common-form-datetime .content .date .body .row {
        height: 35px;
        line-height: 34px; }
        .component-common-form-datetime .content .date .body .row:before, .component-common-form-datetime .content .date .body .row:after {
          content: ' ';
          display: table;
          clear: both; }
        .component-common-form-datetime .content .date .body .row:first-child span {
          border-top: 0;
          line-height: 35px; }
          .component-common-form-datetime .content .date .body .row:first-child span:hover {
            background: #fff;
            cursor: default; }
        .component-common-form-datetime .content .date .body .row span {
          display: block;
          float: left;
          width: 35px;
          text-align: center;
          border-top: 1px solid #eee;
          border-left: 1px solid #eee;
          font-size: 13px;
          transition: background .15s; }
          .component-common-form-datetime .content .date .body .row span:first-child {
            border-left: 0; }
          .component-common-form-datetime .content .date .body .row span:nth-child(6), .component-common-form-datetime .content .date .body .row span:nth-child(7) {
            color: #E03030; }
          .component-common-form-datetime .content .date .body .row span:hover {
            background: #f5f5f5;
            cursor: pointer; }
        .component-common-form-datetime .content .date .body .row .unavailable {
          color: #999; }
          .component-common-form-datetime .content .date .body .row .unavailable:nth-child(6), .component-common-form-datetime .content .date .body .row .unavailable:nth-child(7) {
            color: #999; }
          .component-common-form-datetime .content .date .body .row .unavailable:hover {
            background: #fff;
            cursor: default; }
        .component-common-form-datetime .content .date .body .row .active {
          background: #fff1cc; }
          .component-common-form-datetime .content .date .body .row .active:hover {
            background: #fff1cc; }
    .component-common-form-datetime .content .time {
      height: 50px;
      line-height: 50px;
      text-align: center;
      border-bottom: 1px solid #eee; }
      .component-common-form-datetime .content .time select {
        margin-left: 10px; }
        .component-common-form-datetime .content .time select:first-child {
          margin-left: 0; }
    .component-common-form-datetime .content .confirm {
      height: 40px;
      text-align: center;
      line-height: 40px;
      cursor: pointer;
      transition: background .15s;
      border-bottom: 1px solid #eee; }
      .component-common-form-datetime .content .confirm:hover {
        background: #f9f9f9; }
    .component-common-form-datetime .content .clear {
      height: 40px;
      text-align: center;
      line-height: 40px;
      cursor: pointer;
      transition: background .15s; }
      .component-common-form-datetime .content .clear:hover {
        background: #f9f9f9; }
  .component-common-form-datetime .wrapper {
    position: fixed;
    z-index: 200;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0; }

.component-common-form-datetime-form .text {
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  background: #f9f9f9; }
  .component-common-form-datetime-form .text .option {
    padding: 0 8px; }
  .component-common-form-datetime-form .text .fa-angle-down {
    float: right;
    margin-top: 10px;
    margin-right: 10px; }
