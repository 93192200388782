.student-base-info, .teacher-base-info {
  box-sizing: content-box;
  background: #fff; }
  .student-base-info h3, .teacher-base-info h3 {
    height: 19px;
    margin-bottom: 23px;
    padding-left: 8px;
    overflow: hidden;
    font-size: 14px;
    border-left: 4px solid #39f; }
  .student-base-info > div, .teacher-base-info > div {
    border-bottom: 1px solid #e9e9e9;
    overflow: hidden; }
    .student-base-info > div > div, .teacher-base-info > div > div {
      margin-bottom: 22px;
      float: left;
      width: 33.4%;
      line-height: 20px; }
  .student-base-info label, .teacher-base-info label {
    display: table-cell;
    width: 90px;
    text-align: right;
    font-size: 12px;
    color: #666; }
  .student-base-info label ~ span, .teacher-base-info label ~ span {
    display: table-cell;
    vertical-align: top;
    padding-left: 8px;
    font-size: 12px; }
  .student-base-info .content-show, .teacher-base-info .content-show {
    font-size: 12px;
    color: #282828; }

.teacher-base-info .item {
  display: inline-block; }

.teacher-base-info .item:not(:last-child) {
  margin-right: 20px; }

.default-usable-time, .unusable-time {
  background: #fff; }
  .default-usable-time > .hd, .unusable-time > .hd {
    text-align: center;
    border-bottom: 1px solid #e6e6e6;
    line-height: 40px;
    overflow: hidden;
    padding-left: 30px; }
    .default-usable-time > .hd > :first-child, .unusable-time > .hd > :first-child {
      font-size: 16px;
      font-weight: 500; }
    .default-usable-time > .hd > *, .unusable-time > .hd > * {
      display: inline-block;
      vertical-align: middle; }
    .default-usable-time > .hd .btn, .unusable-time > .hd .btn {
      float: right;
      margin-right: 30px;
      text-decoration: none;
      color: #39f;
      cursor: pointer;
      display: inline-block;
      min-width: 80px;
      text-align: center; }
      .default-usable-time > .hd .btn:hover, .unusable-time > .hd .btn:hover {
        color: #3ec9f6; }

.default-usable-time ul {
  margin-left: 30px;
  width: 300px; }

.default-usable-time li {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  line-height: 40px;
  text-align: center; }
  .default-usable-time li:hover {
    background: #eaf8fe; }
  .default-usable-time li > span {
    text-aligin: left; }
  .default-usable-time li .btn {
    flex: 3;
    text-align: right;
    text-decoration: none;
    color: #39f;
    cursor: pointer;
    display: inline-block;
    min-width: 80px;
    text-align: center; }
    .default-usable-time li .btn:hover {
      color: #3ec9f6; }

.unusable-time .table-wrapper {
  margin-left: 30px;
  line-height: 40px; }

.unusable-time table {
  display: table;
  border-collapse: collapse;
  width: 100%;
  layout: fixed; }
  .unusable-time table td {
    text-align: center;
    border-bottom: 1px solid #e9e9e9; }
  .unusable-time table tbody tr:hover {
    background: #eaf8fe; }

.unusable-time .btn {
  text-decoration: none;
  color: #39f;
  cursor: pointer;
  display: inline-block;
  min-width: 80px;
  text-align: center; }
  .unusable-time .btn:hover {
    color: #3ec9f6; }

.unusable-time .pager-container {
  margin: 0 12px; }

.course-schedule {
  background: #fff; }
  .course-schedule li {
    box-sizing: border-box;
    display: flex; }
  .course-schedule li[role=row] {
    position: relative; }
    .course-schedule li[role=row] .empty {
      height: 40px;
      margin-top: -4px; }
    .course-schedule li[role=row] > * {
      vertical-align: middle; }
    .course-schedule li[role=row] > div {
      display: -webkit-inline-box;
      display: -webkit-inline-flex;
      display: -moz-inline-flex;
      display: -ms-inline-flexbox;
      display: inline-flex;
      width: 100%; }
      .course-schedule li[role=row] > div [role=cell] {
        flex: 1; }
    .course-schedule li[role=row] > span {
      text-align: center;
      width: 38px;
      height: 100px;
      line-height: 100px; }
  .course-schedule [role=cell], .course-schedule .hd li:first-child {
    border: 1px solid #e9e9e9;
    border-width: 1px 0 0 1px;
    min-height: 36px; }
  .course-schedule ul {
    border-right: 1px solid #e9e9e9; }
  .course-schedule .hd {
    font-weight: 500;
    font-size: 14px; }
    .course-schedule .hd [role=cell] {
      line-height: 36px;
      font-size: 12px; }
      .course-schedule .hd [role=cell] .date {
        font-size: 12px;
        line-height: 12px;
        display: inline-block;
        vertical-align: middle;
        margin-left: 5px; }
    .course-schedule .hd li {
      text-align: center; }
    .course-schedule .hd li:first-child {
      background: #fff;
      height: 50px;
      line-height: 50px;
      margin-left: 0; }
    .course-schedule .hd .duration {
      position: relative;
      margin: 0 auto;
      display: block;
      line-height: 50px;
      cursor: pointer; }
      .course-schedule .hd .duration .component-common-form-datetime {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0; }
        .course-schedule .hd .duration .component-common-form-datetime .text {
          opacity: 0; }
    .course-schedule .hd .next, .course-schedule .hd .previous {
      position: absolute;
      top: 0;
      width: 0;
      height: 36px; }
    .course-schedule .hd .previous {
      border-left: 0;
      border-right-color: #fff;
      left: -18px; }
    .course-schedule .hd .next {
      border-right: 0;
      border-left-color: #fff;
      right: -18px; }
  .course-schedule ul.schedule .fa-remove:before {
    padding: 2px 5px; }
  .course-schedule ul.schedule [role=cell] {
    flex: 1;
    align-items: center;
    display: flex;
    padding: 12px;
    position: relative;
    font-size: 12px;
    white-space: normal;
    word-break: normal;
    word-wrap: break-word;
    min-height: 4em !important;
    line-height: 1.5em;
    height: 100px;
    min-width: 37px; }
    .course-schedule ul.schedule [role=cell]:hover .change-info-tip {
      display: block; }
  .course-schedule ul.schedule .change-info-tip {
    display: none;
    padding-top: 5px;
    position: absolute;
    z-index: 2;
    top: 10px;
    right: 120%;
    width: 136px;
    height: 75px;
    opacity: 0.6;
    border-radius: 4px;
    background-color: #000000; }
    .course-schedule ul.schedule .change-info-tip > div {
      white-space: nowrap;
      color: #fff; }
      .course-schedule ul.schedule .change-info-tip > div label,
      .course-schedule ul.schedule .change-info-tip > div span {
        font-size: 12px; }
      .course-schedule ul.schedule .change-info-tip > div > * {
        display: inline-block;
        vertical-align: middle;
        width: 68px; }
    .course-schedule ul.schedule .change-info-tip label {
      text-align: right; }
      .course-schedule ul.schedule .change-info-tip label:after {
        content: ':'; }
    .course-schedule ul.schedule .change-info-tip span {
      margin-left: 20px; }
  .course-schedule .pre-schedule {
    background: #FFF1B2;
    position: relative; }
    .course-schedule .pre-schedule i {
      display: none; }
    .course-schedule .pre-schedule:hover i {
      position: absolute;
      display: block;
      top: 0;
      right: 0;
      font-size: 16px;
      color: #f00;
      padding: 1px 2px;
      cursor: pointer; }
  .course-schedule .changing {
    background: #36ce42; }
  .course-schedule .disabled {
    background: #eee; }
  .course-schedule .course-info {
    display: inline;
    line-height: 1.5; }
    .course-schedule .course-info span:not(:last-child):after {
      content: ' ';
      visibility: hidden;
      font-size: 0;
      padding-right: 6px; }
    .course-schedule .course-info .includeSpan {
      display: flex; }
    .course-schedule .course-info .littleK {
      display: block;
      margin-right: 2px;
      height: 16px;
      line-height: 16px;
      width: 16px;
      color: #fff !important;
      background: #FFAA00;
      border-radius: 2px;
      text-align: center !important;
      font-weight: normal !important;
      font-size: 12px;
      font-weight: normal; }
    .course-schedule .course-info .numbers {
      padding: 0 6px;
      height: 16px;
      line-height: 16px;
      margin-right: 20px;
      display: block;
      background: #999;
      color: #fff;
      border-radius: 2px; }

.menu-wrapper {
  font-size: 14px;
  /*
  .ant-menu-inline>.ant-menu-submenu>.ant-menu-submenu-title{
    white-space: normal;
    word-break: normal;
    word-wrap: break-word;
    height: auto;
  }
  */ }
  .menu-wrapper .ant-menu-root {
    width: 190px; }
  .menu-wrapper .ant-menu {
    white-space: nowrap !important; }
    .menu-wrapper .ant-menu .ant-menu-submenu-title > span {
      display: block;
      overflow: hidden;
      margin-right: 15px;
      text-overflow: ellipsis; }
  .menu-wrapper .ant-menu .ant-menu-submenu-title {
    padding-left: 10px !important; }
  .menu-wrapper .ant-menu .ant-menu .ant-menu-submenu-title {
    padding-left: 20px !important; }
  .menu-wrapper .ant-menu .ant-menu .ant-menu-item {
    padding-left: 30px !important;
    margin-left: 0 !important;
    left: 0 !important; }
  .menu-wrapper .ant-menu li:not(.ant-menu-selected) {
    border: 0 !important; }

.changing-req .course-schedule .hd li:first-child {
  border-bottom: 1px solid #e6e6e6; }

.changing-req .course-schedule .hd li:last-child {
  border-bottom: 0; }
  .changing-req .course-schedule .hd li:last-child div {
    border-top: 0; }

.most-choose {
  margin-left: 32px; }
