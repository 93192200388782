@keyframes tip-modal-hide {
  0% {
    bottom: 8px; }
  10% {
    bottom: -40px; }
  100% {
    bottom: -130px; } }

@keyframes tip-modal-show {
  0% {
    bottom: -130px; }
  10% {
    bottom: -40px; }
  100% {
    bottom: 8px; } }

.tip-modal {
  position: fixed;
  bottom: -130px;
  right: 8px;
  height: 130px;
  width: 280px;
  background: #fff;
  box-shadow: 0 1px 10px 0 rgba(195, 204, 209, 0.5);
  z-index: 2; }
  .tip-modal > .anticon-cross {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: larger;
    cursor: pointer; }
  .tip-modal .left-text {
    display: flex;
    align-items: center;
    margin: 36px 0 0 20px;
    width: 170px;
    height: 72px;
    font-size: 12px; }
    .tip-modal .left-text .content p {
      margin-bottom: 8px;
      color: #29323a; }
    .tip-modal .left-text .content .message-content {
      max-height: 54px;
      overflow: hidden; }
    .tip-modal .left-text .content .message-height {
      display: none; }
    .tip-modal .left-text .content .watch-detail {
      display: inline-block;
      height: 24px;
      width: 90px;
      text-align: center;
      line-height: 24px;
      color: #5279CF;
      cursor: pointer;
      border: 1px solid #5279CF;
      border-radius: 14px; }
    .tip-modal .left-text .content button {
      width: 68px;
      height: 24px;
      border-radius: 99px;
      color: #5279cf;
      border: 1px solid #5279cf;
      outline: none;
      cursor: pointer;
      background: transparent; }
  .tip-modal .right-img {
    position: absolute;
    right: 10px;
    bottom: 16px;
    width: 80px;
    height: 80px;
    background: transparent; }

.tip-modal.hide {
  animation: tip-modal-hide .5s;
  bottom: -130px; }

.tip-modal.show {
  animation: tip-modal-show .5s;
  bottom: 8px; }
