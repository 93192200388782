.teacher-detail label {
  font-size: 12px;
  color: #666; }

.teacher-detail > h3:first-child {
  font-size: 20px;
  padding-left: 30px;
  line-height: 50px;
  background-color: #fff;
  margin-bottom: 25px; }

.teacher-detail .teacher-detail-title {
  padding: 24px 0;
  border-bottom: 1px solid #e6e6e6;
  background-color: #fff;
  font-weight: 600;
  font-size: 12px; }
  .teacher-detail .teacher-detail-title .is-part-time {
    display: inline-block;
    padding: 0 8px 8px 0;
    color: #666; }

.teacher-detail .container-teacher-base-info {
  background-color: #fff; }
  .teacher-detail .container-teacher-base-info .back {
    position: absolute;
    top: 0;
    left: 0;
    height: 58px;
    width: 100%;
    line-height: 58px;
    padding-left: 32px;
    margin-top: 0 !important;
    text-align: left;
    border-bottom: 1px solid #f7f7f7;
    font-size: 12px;
    cursor: pointer; }
  .teacher-detail .container-teacher-base-info .title {
    margin-bottom: 24px;
    padding: 75px 0 16px 0;
    line-height: 1em;
    font-weight: 600;
    font-size: 16px;
    border-bottom: 1px solid #e9e9e9; }

.teacher-detail .teacher-name {
  margin-bottom: 20px;
  color: #666; }
  .teacher-detail .teacher-name .label-name {
    display: inline-block;
    width: 90px;
    text-align: right; }

.teacher-detail .subject {
  float: left;
  padding-left: 8px;
  line-height: 19px;
  min-width: 70px;
  font-size: 14px;
  color: #282828;
  border-left: 4px solid #39f; }

.teacher-detail .component-common-form-checkbox {
  border: none; }
  .teacher-detail .component-common-form-checkbox .component-common-form-radio {
    background: white;
    border: none; }

.teacher-detail .exam-info {
  background-color: #fff;
  margin-bottom: 25px; }
  .teacher-detail .exam-info .no-colon .readOnly-title {
    display: block;
    padding-left: 8px;
    border-left: 4px solid #39f; }
  .teacher-detail .exam-info .no-colon .readOnly-detail {
    margin: 24px 0 0 27px;
    display: block;
    font-size: 12px;
    color: #282828; }
  .teacher-detail .exam-info .adjusted-div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 35px;
    margin-bottom: 23px;
    overflow: hidden;
    font-size: 14px; }
    .teacher-detail .exam-info .adjusted-div .component-common-form-radio {
      width: 6%; }
    .teacher-detail .exam-info .adjusted-div .label {
      padding-left: 8px;
      border-left: 4px solid #39f;
      font-weight: 500; }
  .teacher-detail .exam-info .adjusted-div + .checkbox-item {
    padding: 24px;
    margin-left: 22px;
    border: 0;
    background: #f7f7f7; }

.teacher-detail .component-teacher-subject {
  padding: 0 0 20px;
  background-color: #fff;
  border-bottom: 1px solid #e9e9e9; }
  .teacher-detail .component-teacher-subject .bordered-detail {
    margin: 24px 0 0 27px; }
    .teacher-detail .component-teacher-subject .bordered-detail .readOnly-title {
      font-size: 12px;
      color: #666; }
    .teacher-detail .component-teacher-subject .bordered-detail .readOnly-detail {
      font-size: 12px;
      color: #282828; }
  .teacher-detail .component-teacher-subject .adjusted-div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 35px;
    margin-bottom: 10px;
    overflow: hidden;
    font-size: 14px; }
    .teacher-detail .component-teacher-subject .adjusted-div .component-common-form-radio {
      width: 6%; }
    .teacher-detail .component-teacher-subject .adjusted-div .label {
      padding-left: 8px;
      border-left: 4px solid #39f;
      font-weight: 500; }
  .teacher-detail .component-teacher-subject .exam-info {
    overflow: hidden;
    padding-left: 0; }
  .teacher-detail .component-teacher-subject .exam-title {
    float: left;
    width: 70px;
    line-height: 37px; }
  .teacher-detail .component-teacher-subject .checkbox-all {
    margin-bottom: 18px; }
    .teacher-detail .component-teacher-subject .checkbox-all .title-item .checkbox-title {
      padding-left: 12px;
      font-size: 12px;
      color: #282828; }
    .teacher-detail .component-teacher-subject .checkbox-all .checkbox-item {
      margin-left: 22px;
      padding: 24px;
      border: none;
      background: #f7f7f7; }

.teacher-time label {
  font-size: 14px; }

.teacher-time .teacher-choose {
  background-color: #fff;
  padding: 20px 0 30px;
  margin-bottom: 25px; }
  .teacher-time .teacher-choose .edit {
    float: right;
    background-color: #39f;
    margin-right: 40px;
    cursor: pointer;
    width: 90px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    border-radius: 20px;
    color: #fff; }
    .teacher-time .teacher-choose .edit:hover {
      background-color: #039be0; }

.teacher-time .teacher-base-info,
.teacher-time .unusable-time,
.teacher-time .default-usable-time {
  margin-bottom: 25px; }

.schedule-container .hd {
  font-weight: 600; }

.schedule-container .schedule-container-choose {
  background-color: #fff;
  padding: 10px;
  margin-bottom: 25px; }

.schedule-container .component-common-form-select-schedule {
  float: left; }

.schedule-container .component-common-suggest {
  margin-top: 3px; }

.schedule-container .desc-info {
  background-color: #fff;
  padding: 10px 60px; }

.schedule-container .teacher-base-info {
  padding: 10px 25px;
  margin-bottom: 25px; }

.schedule-container .schedule-title {
  font-size: 16px;
  line-height: 50px;
  height: 40px;
  background-color: #fff;
  padding: 0 50px;
  border-bottom: 1px solid #e6e6e6;
  font-weight: 500; }

.schedule-container .download-schedule {
  position: absolute;
  right: 110px;
  width: 90px;
  border: 1px solid #ccc;
  text-align: center;
  top: 106px;
  line-height: 30px;
  border-radius: 6px;
  background-color: #f5f5f5; }
  .schedule-container .download-schedule.live-classrooms-schedule {
    right: 220px; }

.admin-schedule .admin-time-des {
  padding: 24px 20px 24px 0;
  margin: 0 !important;
  background-color: #fff;
  font-size: 12px;
  text-align: right; }

.admin-schedule .component-common-filter {
  margin-bottom: 0 25px 25px 0; }

.admin-schedule .ant-table-wrapper {
  margin-top: 0; }

.admin-schedule .ant-table-body table {
  border-radius: 4px !important;
  font-size: 14px;
  text-align: center; }
  .admin-schedule .ant-table-body table .ant-table-thead > tr > th {
    min-width: 80px;
    text-align: center; }

.admin-schedule .export-excel {
  position: absolute;
  right: 0; }

.admin-classroom > h3 {
  padding: 15px 20px;
  background: #fff;
  margin-bottom: 25px; }

.admin-classroom .ant-table-body table {
  font-size: 14px;
  text-align: center; }
  .admin-classroom .ant-table-body table .ant-table-thead .columns-time {
    text-align: left; }
  .admin-classroom .ant-table-body table .ant-table-thead > tr {
    white-space: nowrap; }

.admin-classroom .ant-table-body .columns-time {
  text-align: left; }

.feedback-modal .ant-checkbox-group {
  width: 100%; }
