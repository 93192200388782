.component-student-course-play > .title {
  padding: 16px 18px;
  background: #fff;
  border-radius: 2px;
  box-shadow: rgba(0, 0, 0, 0.0470588) 0 1px 1px 0; }
  .component-student-course-play > .title a {
    margin-right: 10px; }
    .component-student-course-play > .title a:hover {
      color: #0073e6; }

.component-student-course-play > .content {
  width: 100%;
  margin-top: 25px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center; }
  .component-student-course-play > .content .video {
    width: 100%;
    height: 450px;
    background: #000;
    box-shadow: rgba(0, 0, 0, 0.0470588) 0 1px 1px 0;
    overflow: hidden;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -moz-box-flex: 1;
    -moz-flex: 1;
    -ms-flex: 1;
    flex: 1; }
    .component-student-course-play > .content .video .video-js {
      width: 100% !important; }
      .component-student-course-play > .content .video .video-js .vjs-tech {
        width: 100%; }
      .component-student-course-play > .content .video .video-js .vjs-error-modal {
        width: 100%; }
  .component-student-course-play > .content .panel {
    width: 300px;
    margin-left: 15px;
    background: #fff;
    border-radius: 2px;
    box-shadow: rgba(0, 0, 0, 0.0470588) 0 1px 1px 0; }
    .component-student-course-play > .content .panel .tabs {
      height: 40px;
      line-height: 39px; }
      .component-student-course-play > .content .panel .tabs:before, .component-student-course-play > .content .panel .tabs:after {
        content: ' ';
        display: table;
        clear: both; }
      .component-student-course-play > .content .panel .tabs span {
        display: block;
        float: left;
        width: 150px;
        text-align: center;
        border-bottom: 1px solid #eee;
        border-right: 1px solid #eee;
        cursor: pointer;
        background: #FAFAFA;
        transition: background .15s; }
        .component-student-course-play > .content .panel .tabs span:hover {
          background: #f7f7f7; }
        .component-student-course-play > .content .panel .tabs span:last-child {
          border-right: none; }
      .component-student-course-play > .content .panel .tabs .active {
        color: #39f;
        background: #fff; }
        .component-student-course-play > .content .panel .tabs .active:hover {
          background: #fff; }
    .component-student-course-play > .content .panel .btn {
      height: 50px;
      line-height: 49px;
      text-align: center;
      border-top: 1px solid #eee;
      cursor: pointer;
      transition: background .15s; }
      .component-student-course-play > .content .panel .btn:hover {
        background: #fafafa; }
  .component-student-course-play > .content .panel-question,
  .component-student-course-play > .content .panel-note {
    height: 360px;
    overflow-y: auto; }
    .component-student-course-play > .content .panel-question .item,
    .component-student-course-play > .content .panel-note .item {
      padding: 16px 18px;
      border-bottom: 1px dashed #eee;
      font-size: 13px;
      cursor: pointer;
      transition: color .15s; }
      .component-student-course-play > .content .panel-question .item:first-child,
      .component-student-course-play > .content .panel-note .item:first-child {
        margin-top: 0; }
      .component-student-course-play > .content .panel-question .item:last-child,
      .component-student-course-play > .content .panel-note .item:last-child {
        border-bottom: none; }
      .component-student-course-play > .content .panel-question .item:hover,
      .component-student-course-play > .content .panel-note .item:hover {
        color: #0ba6ec; }
