.common-course-schedule .ant-calendar-range-picker.ant-input {
  background: #fff;
  border: none;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  color: #555; }

.common-course-schedule .hd .duration .ant-input {
  background: #fff;
  border: 0;
  text-align: center;
  font-size: 14px;
  font-weight: 600; }

.common-course-schedule .ant-calendar-picker-clear {
  display: none; }

.common-course-schedule .ant-calendar-picker-icon {
  display: none; }

.common-course-schedule .schedule div.month-cell[role='cell'] {
  min-height: 120px !important;
  height: auto;
  padding-top: 20px; }

.common-course-schedule .forbidden {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 4; }
  .common-course-schedule .forbidden .icon {
    display: block;
    height: 12px;
    width: 12px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAAGXcA1uAAAABGdBTUEAALGPC/xhBQAABBRJREFUSA2FVVtvVFUU3mvPDOP0oolNuFiQTBANyLSZzgQqM4UXQkQJJPDAm9FEVBJS4oO+mBj9BQQINHh78IEQHhQIBNGEIDMtTTvTy0xKWq8J1nppGqUm2mHmnO23dmfNnNMysB/OXutb17322uuQwsrmC0d1IPylJRjgRQNjU+1OZWF6kcU3myvMW0kmN24E1VXCsRL+iJSIbhljdmB3tdiyQjrRsZN3CDUxgfiOZYgGsXcDPMy4XXA3BeurwlP/aGG/65iLSPE3OFnDgp5kJ2kviKBvsYCTqaa4qKk0/cQCXpK7ZYzjfs0EgpdtVmwKN0jIWJ5jWIK16kkw51+kaD6d7HiC0ZonUVnb9ngkGo0uDI7e2dgd3/w9omQhS1WV+zSfV5R5n56b/49TKTvlO1aJ9CmR4xaOoIT0gwC1ndRXyDfEhsa452s4CI3cXuYcvWDQqPe8vNDNumll7dAMZnPjZzisKPAOhXKTbmrv6to4W+Xr4qFicd39++YsjHrIqF9JmZOpZOeZuka1Sgxk8oV/0BctXqHQWum9qWTMNoK9ObSN61XGmW7C1+9i4Cr3Sna08BLzaMDxT+Qmke8Y3+aKML2STsTaI6pjhRgZx9gIxKUTkJXh4HUAHzPGfCZX4PZbzbwmeiPIhCxb9yqDaNVuXlRmGJn0+gzEkEgfREpfVAshMHZa42tXkbAyCvGttxAsQ6oZPkMZtD8SUQnKYXEguw41r9IRFXpaANl7Eh2P4YCvCW93or9Snc/8ibPhLeSKR1Dr+o2i+RD/RY+Bg4rZLKwBCwaLxVXlkjsN0pceLvEUGrRXjGsGAvDOo8mtLHyIQ74KNsDYQ1ZFEX0WMcEPksnNuDP/8gVAwY7hzR+XTvGrPprjWUeGjqJP+0TbBrDD1ClNLr0mVoJRTms6tj0eGxAj3m/nJrY55HAyL3hxpuH0XjCsn+uOxf4gfmKlkvsz8GWl0IFgPBV/fgxOdP9I4YIx6qB1RpRv0a274vHo3zyLMF6+s7j/UwlHgusokx+/tuRGRa1CSr9Pyr2HIfkunK8XAe86ENiaim8Z5nL254uzRpk2r5xpXPglBGj87pcbqH81BQ5sT2y5zrLsSDGpXHMDzluX6jKPAHNB1H0G9LMPUvBhpGbTic6VjGVHCntw6ovGdWvjwadbZ2aCeJwnXKVO17EGlKFf8F7fZqlx1Tv4Pso5z5cT2s5Moo8auK3B6IzJ9idb+0JNoc/h/G5N0ICwD64r9qmdLnjrb/Kc5T5uoI+CmuEo/mDbNm2aw1yYaKinlEMBvVtes++hsZGdzY45h1ds/5UPceQXYfiQpkPpeOwbr2BZAK9waGJidWnB2YdfEH4IZgOO8RTkiK1m8PkRv6WrFGq+zFPNa+el/wcydayFn5aPWQAAAABJRU5ErkJggg==);
    background-size: 12px; }

.common-course-schedule .month-cell-div {
  position: absolute;
  opacity: 1;
  right: 4px;
  top: 4px;
  display: inline-block;
  height: 20px;
  width: 20px;
  text-align: center;
  line-height: 20px;
  font-size: 12px;
  color: #999; }

.common-course-schedule .todayBg {
  background: #355AA0;
  border-radius: 10px;
  color: #fff;
  font-weight: 600; }

.common-course-schedule .daycells {
  margin-top: 10px; }

.common-course-schedule .month-cell-line {
  position: relative;
  margin: 2px 0px;
  font-size: 12px;
  background: #fafafa;
  width: 100%; }
  .common-course-schedule .month-cell-line .trangle {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 0;
    height: 0;
    border-top: 10px solid red;
    border-left: 10px solid transparent; }
  .common-course-schedule .month-cell-line .course-info {
    display: block;
    padding: 5px;
    border-radius: 3px; }
    .common-course-schedule .month-cell-line .course-info .course-info {
      position: relative; }
  .common-course-schedule .month-cell-line .unhandle {
    background: #EAEFF2;
    color: #000;
    border: 1px solid #C3CCD1; }
    .common-course-schedule .month-cell-line .unhandle .numbers {
      background: #C3CCD1; }
  .common-course-schedule .month-cell-line .unfinished {
    cursor: pointer;
    background: #FFDADA;
    color: #000;
    border: 1px solid #FFBEBC; }
    .common-course-schedule .month-cell-line .unfinished .numbers {
      background: #FF9390; }
  .common-course-schedule .month-cell-line .completed {
    background: #CBE9FF;
    color: #000;
    border: 1px solid #AFDDFF; }
    .common-course-schedule .month-cell-line .completed .numbers {
      background: #49A9EE; }
  .common-course-schedule .month-cell-line .pink {
    cursor: pointer;
    background: #FFDADA;
    color: #000;
    border: 1px solid #FFBEBC; }
    .common-course-schedule .month-cell-line .pink .numbers {
      background: #FF9390; }
  .common-course-schedule .month-cell-line .blue {
    background: #CBE9FF;
    color: #000;
    border: 1px solid #AFDDFF; }
    .common-course-schedule .month-cell-line .blue .numbers {
      background: #49A9EE; }

.common-course-schedule .com-common-schedule-cell__disabled {
  background: #ccc; }

.common-course-schedule .schedule {
  border-bottom: 1px solid #e9e9e9; }
