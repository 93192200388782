.weekly-list .filter label {
  font-size: 12px; }

.weekly-list .filter .ant-calendar-picker {
  width: 220px;
  margin-right: 20px; }

.weekly-list .filter .ant-select {
  margin-right: 20px; }

.weekly-list .filter .select-status {
  width: 200px; }

.weekly-list table td {
  font-size: 12px; }
  .weekly-list table td p {
    margin-bottom: 0; }
  .weekly-list table td img {
    height: 32px;
    width: 32px;
    border-radius: 16px; }
  .weekly-list table td a {
    margin: 0 10px; }
