.pigai-authorization-manage-container .filter-container {
  display: flex;
  justify-content: space-between; }
  .pigai-authorization-manage-container .filter-container .filter-left {
    display: flex;
    font-size: 12px; }
    .pigai-authorization-manage-container .filter-container .filter-left .filter-item:not(:last-of-type) {
      margin-right: 25px; }
  .pigai-authorization-manage-container .filter-container .select {
    width: 230px; }

.pigai-authorization-manage-container .table-container .ant-table-content table .ant-table-thead tr th {
  text-align: center !important; }

.pigai-authorization-manage-container .table-container .ant-table-content table .ant-table-tbody tr td {
  text-align: center !important; }
