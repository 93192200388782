.form-item-container {
  margin: 16px 0; }
  .form-item-container .ant-row-flex .required {
    margin-right: 5px;
    color: red; }
  .form-item-container .ant-row-flex .ant-calendar-picker, .form-item-container .ant-row-flex .ant-time-picker {
    width: 100%; }
  .form-item-container .ant-row-flex > div {
    text-align: right; }
  .form-item-container .error-text {
    margin-top: 5px;
    color: red;
    text-align: left; }
  .form-item-container.narrow {
    margin: 6px 0; }
  .form-item-container .ant-upload-list-picture-card .ant-upload-list-item-info:before {
    top: 0;
    left: 0; }

.form-item-container.inline {
  display: inline-block; }

.radio-group, .checkbox-group {
  margin: 20px 0; }
  .radio-group .required, .checkbox-group .required {
    margin-right: 5px;
    color: red; }

.checkbox-group .header {
  display: flex;
  align-items: center; }
  .checkbox-group .header .all-checked {
    margin-left: 40px; }
    .checkbox-group .header .all-checked .ant-checkbox-wrapper {
      margin-right: 8px; }

.checkbox-group .ant-checkbox-group .ant-checkbox-group-item {
  margin: 10px 0;
  width: 15%; }
