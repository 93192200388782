@charset "UTF-8";
.transfer-detail-container .component-common-form-back {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 58px;
  padding-left: 32px;
  line-height: 58px;
  font-size: 12px;
  border-bottom: 1px solid #eee;
  cursor: pointer; }

.transfer-detail-container .student-info {
  margin-top: 45px;
  height: 40px;
  line-height: 40px; }
  .transfer-detail-container .student-info > span {
    margin-right: 40px; }
    .transfer-detail-container .student-info > span > span {
      font-size: 12px;
      color: #333; }

.transfer-detail-container .menu-tabs {
  margin-top: 30px; }

.transfer-detail-container .detail-container {
  margin-top: 34px; }
  .transfer-detail-container .detail-container .student-base-info .teacher-base-info {
    border-bottom: none; }
    .transfer-detail-container .detail-container .student-base-info .teacher-base-info label {
      width: 108px; }
    .transfer-detail-container .detail-container .student-base-info .teacher-base-info .content-show {
      font-weight: bolder;
      color: #666; }
    .transfer-detail-container .detail-container .student-base-info .teacher-base-info div {
      border-bottom: none; }
    .transfer-detail-container .detail-container .student-base-info .teacher-base-info > div {
      width: 100%; }
      .transfer-detail-container .detail-container .student-base-info .teacher-base-info > div > div {
        width: 25%; }
  .transfer-detail-container .detail-container .products-info .product-info .title {
    margin-left: 12px;
    font-size: 12px;
    font-weight: 600;
    color: #3559a0; }
  .transfer-detail-container .detail-container .products-info .product-info > div {
    margin-top: 15px;
    float: left;
    width: 25%; }
    .transfer-detail-container .detail-container .products-info .product-info > div > div {
      height: 40px;
      line-height: 40px; }
      .transfer-detail-container .detail-container .products-info .product-info > div > div label {
        display: inline-block;
        width: 120px;
        text-align: right;
        color: #666; }
      .transfer-detail-container .detail-container .products-info .product-info > div > div span {
        color: #666;
        font-size: 12px;
        font-weight: bolder; }
  .transfer-detail-container .detail-container .prepare－exams .prepare－exam {
    font-size: 12px; }
    .transfer-detail-container .detail-container .prepare－exams .prepare－exam .bold {
      font-weight: bolder;
      font-weight: #666;
      margin-right: 0px; }
    .transfer-detail-container .detail-container .prepare－exams .prepare－exam .subject-title {
      height: 40px;
      width: 100%;
      line-height: 40px;
      padding-left: 10px;
      background: #f7f7f7;
      border-radius: 4px;
      color: #000;
      font-weight: 600;
      margin: 20px 0; }
    .transfer-detail-container .detail-container .prepare－exams .prepare－exam .prepare－exam-content {
      padding: 0 14px; }
      .transfer-detail-container .detail-container .prepare－exams .prepare－exam .prepare－exam-content > div {
        line-height: 40px; }
    .transfer-detail-container .detail-container .prepare－exams .prepare－exam .history－record, .transfer-detail-container .detail-container .prepare－exams .prepare－exam .target－record {
      display: flex; }
      .transfer-detail-container .detail-container .prepare－exams .prepare－exam .history－record .record, .transfer-detail-container .detail-container .prepare－exams .prepare－exam .target－record .record {
        display: inline-block;
        width: 185px; }
      .transfer-detail-container .detail-container .prepare－exams .prepare－exam .history－record .score, .transfer-detail-container .detail-container .prepare－exams .prepare－exam .target－record .score {
        width: calc(100% - 185px); }
        .transfer-detail-container .detail-container .prepare－exams .prepare－exam .history－record .score span, .transfer-detail-container .detail-container .prepare－exams .prepare－exam .target－record .score span {
          margin-right: 30px; }
    .transfer-detail-container .detail-container .prepare－exams .prepare－exam .times > span {
      display: inline-block;
      margin-right: 40px; }
  .transfer-detail-container .detail-container .arrange-info .title {
    font-size: 12px;
    color: #3559a0;
    font-weight: 600; }
  .transfer-detail-container .detail-container .arrange-info .intruction {
    line-height: 20px;
    font-size: 12px;
    color: #333; }
  .transfer-detail-container .detail-container .arrange-info > div {
    margin-bottom: 20px;
    padding-left: 12px; }
  .transfer-detail-container .detail-container .arrange-info .zl-calendar {
    margin-top: 20px; }

.transfer-detail-container .transfer-reject-reason {
  margin-top: 15px;
  color: red;
  font-size: 12px; }

.transfer-detail-container > h3 {
  margin-bottom: 25px;
  background: #fff;
  line-height: 40px;
  padding-left: 27px; }

.transfer-detail-container .link-btn {
  text-decoration: none;
  color: #39f;
  cursor: pointer;
  display: inline-block;
  min-width: 80px;
  text-align: center; }
  .transfer-detail-container .link-btn:hover {
    color: #3ec9f6; }

.transfer-detail-container .transfer-title {
  font-size: 16px;
  margin-top: 20px;
  padding: 10px 0 10px 20px;
  font-weight: 500;
  background-color: #fff; }

.transfer-detail-container .checkbox-all .checkbox-item {
  padding-left: 220px;
  border: none;
  background-color: #fff; }

.transfer-detail-container .transfer-textarea-con {
  background-color: #fff;
  padding: 25px 0 0 30px; }
  .transfer-detail-container .transfer-textarea-con:last-child {
    padding-bottom: 20px; }

.transfer-detail-container .transfer-textarea-tag {
  margin-bottom: 15px; }

.transfer-detail-container .transfer-textarea {
  resize: none;
  display: inline-block;
  width: 90%;
  min-height: 80px;
  padding: 10px; }

.transfer-detail-container .component-school-modal-transfer-receive {
  padding: 20px;
  background: #fff;
  width: 100%;
  margin-top: 25px; }

.container-transfer-list .component-school-modal-transfer-receive {
  width: 1000px; }

.transfer-list .component-common-form-input > input {
  width: 250px; }
