.exam-subject-picker {
  margin-bottom: 15px;
  padding-left: 15px; }
  .exam-subject-picker > div:nth-child(1) {
    height: 30px;
    line-height: 30px; }
    .exam-subject-picker > div:nth-child(1) .ant-checkbox-wrapper {
      margin-left: 20px; }
      .exam-subject-picker > div:nth-child(1) .ant-checkbox-wrapper .ant-checkbox {
        transform: translate(3px, -1px); }
  .exam-subject-picker > div:nth-child(2) {
    padding: 15px;
    display: flex;
    background-color: #f5f5f5;
    flex-wrap: wrap;
    line-height: 26px; }
    .exam-subject-picker > div:nth-child(2) .ant-checkbox-wrapper {
      width: 24%;
      flex-grow: 0;
      flex-shrink: 0; }
