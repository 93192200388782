.container-school-plan-view .component-common-form-select .text {
  background: #fff;
  height: 40px;
  line-height: 40px; }
  .container-school-plan-view .component-common-form-select .text .option {
    padding: 0 12px; }
  .container-school-plan-view .component-common-form-select .text .fa {
    margin-top: 12px;
    margin-right: 12px; }

.container-school-plan-view .component-common-form-select .list {
  top: 45px; }
