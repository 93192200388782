.exchange-course-manage-container .filter-operation-container .ant-select:first-of-type {
  margin-right: 20px;
  width: 230px; }

.exchange-course-manage-container .filter-operation-container .ant-select:not(:first-of-type) {
  margin-right: 20px;
  width: 100px; }

.exchange-course-manage-container .filter-operation-container .ant-input-affix-wrapper {
  width: 270px; }

.exchange-course-manage-container .table-container table .ant-table-tbody tr td {
  height: 45px; }
  .exchange-course-manage-container .table-container table .ant-table-tbody tr td .operations a {
    margin-right: 10px; }

.exchange-course-view-modal .item {
  margin: 16px 0; }
  .exchange-course-view-modal .item label {
    width: 20%;
    display: inline-block;
    text-align: right; }

.exchange-course-reject-modal p {
  margin-bottom: 20px; }
