.comment-container {
  padding: 25px;
  flex: 1;
  overflow: auto; }
  .comment-container form {
    display: block;
    padding: 15px 18px;
    background: #fff;
    border-radius: 2px;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.0470588);
    overflow: hidden; }
    .comment-container form .teacher {
      padding: 7px; }
