@charset "UTF-8";
.entrance-exam-wrapper {
  width: 100%;
  height: 100%; }
  .entrance-exam-wrapper .student-entrance-exam-container {
    background: #F5F5FB;
    padding: 40px 145px;
    min-height: 100%; }
    .entrance-exam-wrapper .student-entrance-exam-container .student-entrance-exam-layout-header {
      width: 100%;
      height: 156px;
      background: #3984F8;
      box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
      border-radius: 8px;
      align-items: center;
      overflow: hidden; }
      .entrance-exam-wrapper .student-entrance-exam-container .student-entrance-exam-layout-header .student-entrance-exam-layout-header-content-wrapper {
        padding: 30px 160px 30px 30px;
        flex: 1 1 0%; }
        .entrance-exam-wrapper .student-entrance-exam-container .student-entrance-exam-layout-header .student-entrance-exam-layout-header-content-wrapper .student-entrance-exam-layout-header-1 {
          font-size: 20px;
          font-weight: 600;
          color: white; }
        .entrance-exam-wrapper .student-entrance-exam-container .student-entrance-exam-layout-header .student-entrance-exam-layout-header-content-wrapper .student-entrance-exam-layout-header-2 {
          font-size: 14px;
          font-weight: 400;
          color: white;
          margin-top: 20px; }
    .entrance-exam-wrapper .student-entrance-exam-container .student-entrance-exam-layout-content {
      width: 100%;
      min-height: calc(100% - 231px);
      background: #FFFFFF;
      box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
      border-radius: 14px;
      margin-top: 20px; }
      .entrance-exam-wrapper .student-entrance-exam-container .student-entrance-exam-layout-content .ant-tabs-nav {
        width: 100%; }
        .entrance-exam-wrapper .student-entrance-exam-container .student-entrance-exam-layout-content .ant-tabs-nav .ant-tabs-nav-list {
          margin: 0px 20px;
          padding-top: 20px;
          border-bottom: solid 1px #e6e6e6; }
      .entrance-exam-wrapper .student-entrance-exam-container .student-entrance-exam-layout-content .student-entrance-exam-layout-content-panel {
        padding: 10px;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        /* 左对齐 */ }
        @media screen and (max-width: 1400px) {
          .entrance-exam-wrapper .student-entrance-exam-container .student-entrance-exam-layout-content .student-entrance-exam-layout-content-panel .student-entrance-exam-layout-content-panel-item {
            flex-basis: calc((50% - 20px));
            /* 2列布局 */ } }
        .entrance-exam-wrapper .student-entrance-exam-container .student-entrance-exam-layout-content .student-entrance-exam-layout-content-panel .student-entrance-exam-layout-content-panel-item {
          flex: 1 0 calc(33% - 20px);
          /* 示例：每行3列（需配合gap调整） */
          min-width: 300px;
          max-width: calc(33% - 20px);
          /* 中等屏幕3列 */
          height: 138px;
          background: #FFFFFF;
          border-radius: 6px;
          border: 1px solid #DDE2EE;
          display: flex;
          align-items: center;
          padding: 20px 30px;
          justify-content: space-between;
          transition: all .2s ease-in-out;
          cursor: pointer;
          margin: 10px; }
          .entrance-exam-wrapper .student-entrance-exam-container .student-entrance-exam-layout-content .student-entrance-exam-layout-content-panel .student-entrance-exam-layout-content-panel-item .ant-card-body {
            display: flex;
            width: 100%;
            align-items: center;
            /* 垂直居中 */
            justify-content: center;
            /* 水平居中（可选） */ }
            .entrance-exam-wrapper .student-entrance-exam-container .student-entrance-exam-layout-content .student-entrance-exam-layout-content-panel .student-entrance-exam-layout-content-panel-item .ant-card-body .panel-item-left {
              margin: 0;
              padding: 0;
              outline: 0;
              background: transparent;
              flex: 1 1 0%;
              -moz-box-sizing: border-box;
              -webkit-box-sizing: border-box; }
              .entrance-exam-wrapper .student-entrance-exam-container .student-entrance-exam-layout-content .student-entrance-exam-layout-content-panel .student-entrance-exam-layout-content-panel-item .ant-card-body .panel-item-left .panel-item-title {
                font-size: 16px;
                font-weight: 600;
                color: #3C4352; }
              .entrance-exam-wrapper .student-entrance-exam-container .student-entrance-exam-layout-content .student-entrance-exam-layout-content-panel .student-entrance-exam-layout-content-panel-item .ant-card-body .panel-item-left .panel-item-sub1 {
                margin-top: 14px;
                font-size: 12px;
                font-weight: 400;
                color: #838A9D; }
              .entrance-exam-wrapper .student-entrance-exam-container .student-entrance-exam-layout-content .student-entrance-exam-layout-content-panel .student-entrance-exam-layout-content-panel-item .ant-card-body .panel-item-left .panel-item-sub2 {
                margin-top: 8px;
                font-size: 12px;
                font-weight: 400;
                color: #838A9D;
                overflow: hidden;
                text-overflow: ellipsis; }
            .entrance-exam-wrapper .student-entrance-exam-container .student-entrance-exam-layout-content .student-entrance-exam-layout-content-panel .student-entrance-exam-layout-content-panel-item .ant-card-body .panel-item-button {
              height: 32px;
              border-radius: 4px;
              padding: 0px 20px;
              background-color: #165DFF;
              border: 1px solid #DCDFE6;
              border-color: #165DFF;
              display: inline-block;
              line-height: 1;
              white-space: nowrap;
              cursor: pointer;
              text-align: center;
              box-sizing: border-box;
              outline: 0;
              margin: 0;
              font-weight: 500;
              font-size: 14px; }
              .entrance-exam-wrapper .student-entrance-exam-container .student-entrance-exam-layout-content .student-entrance-exam-layout-content-panel .student-entrance-exam-layout-content-panel-item .ant-card-body .panel-item-button span {
                color: #fff; }

.form-preface {
  color: #838a9d;
  margin-bottom: 15px;
  font-size: 14px; }

.form-sms-code-button {
  color: #3381FC !important;
  background: white !important;
  border-color: #3381FC !important; }

.ant-btn-primary[disabled] {
  color: #3381FC !important; }

.form-sms-code-button:hover {
  color: white !important;
  background: #3381FC !important; }

.ant-form-item-label > label {
  font-size: 14px;
  font-weight: 500; }

.ant-form-item-explain-error > div {
  color: red; }
