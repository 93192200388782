.componet-school__student-manage-assigning-pt {
  display: flex;
  flex-direction: column;
  font-size: 12px; }
  .componet-school__student-manage-assigning-pt > .title {
    margin-bottom: 15px; }
  .componet-school__student-manage-assigning-pt .asign-pt-container {
    padding-bottom: 24px;
    border-bottom: 1px solid #e9e9e9; }
  .componet-school__student-manage-assigning-pt .title {
    display: flex;
    justify-content: space-between;
    padding-left: 15px; }
    .componet-school__student-manage-assigning-pt .title .title-text {
      border-left: 4px solid #39f;
      padding-left: 15px;
      font-weight: 500;
      margin-right: 20px;
      line-height: 33px;
      font-size: 14px; }
    .componet-school__student-manage-assigning-pt .title .title-right > * {
      margin-left: 20px; }
  .componet-school__student-manage-assigning-pt .product {
    padding: 8px 36px; }
    .componet-school__student-manage-assigning-pt .product .product-info {
      margin-bottom: 15px; }
    .componet-school__student-manage-assigning-pt .product .product-name {
      margin-right: 50px; }
    .componet-school__student-manage-assigning-pt .product .product-teachers {
      width: 100%; }
      .componet-school__student-manage-assigning-pt .product .product-teachers > div {
        display: flex; }
        .componet-school__student-manage-assigning-pt .product .product-teachers > div > div {
          flex: 1; }
  .componet-school__student-manage-assigning-pt .form-item {
    width: 23%;
    display: flex;
    margin: 8px;
    justify-content: flex-start; }
  .componet-school__student-manage-assigning-pt .form-text {
    width: 40px;
    text-align: right;
    margin-right: 10px;
    line-height: 26px; }
  .componet-school__student-manage-assigning-pt .form-item-small {
    width: 17%;
    display: flex;
    margin: 8px;
    justify-content: flex-start; }
