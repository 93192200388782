.componet-school__student-manage-transfer-info {
  display: flex;
  flex-direction: column; }
  .componet-school__student-manage-transfer-info .title-right {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px; }
  .componet-school__student-manage-transfer-info .panel-title {
    padding: 0 15px;
    font-weight: 500; }
    .componet-school__student-manage-transfer-info .panel-title .info {
      border-left: 4px solid #39f;
      padding-left: 15px;
      font-weight: 500;
      margin-right: 20px;
      line-height: 33px;
      font-size: 14px; }
  .componet-school__student-manage-transfer-info .panel-content {
    display: flex;
    flex-wrap: wrap;
    padding: 0 0 24px 0;
    margin-bottom: 15px;
    border-bottom: 1px solid #eee;
    font-size: 12px; }
    .componet-school__student-manage-transfer-info .panel-content > div {
      flex-shrink: 0;
      padding: 12px 8px; }
    .componet-school__student-manage-transfer-info .panel-content .product-title {
      margin-left: 25px;
      width: 100%; }
      .componet-school__student-manage-transfer-info .panel-content .product-title > span {
        margin-right: 15px; }
    .componet-school__student-manage-transfer-info .panel-content .form-item-full {
      width: 100%; }
    .componet-school__student-manage-transfer-info .panel-content .form-item {
      width: 35%; }
    .componet-school__student-manage-transfer-info .panel-content .form-item-small {
      width: 30%; }
    .componet-school__student-manage-transfer-info .panel-content .form-item-full,
    .componet-school__student-manage-transfer-info .panel-content .form-item,
    .componet-school__student-manage-transfer-info .panel-content .form-item-small {
      display: flex;
      justify-content: flex-start; }
    .componet-school__student-manage-transfer-info .panel-content .form-text {
      width: 115px;
      text-align: right;
      margin-right: 10px; }
    .componet-school__student-manage-transfer-info .panel-content .form-item-red {
      color: red; }
  .componet-school__student-manage-transfer-info .zl-calendar {
    margin-top: 15px; }
  .componet-school__student-manage-transfer-info > .title {
    margin-bottom: 15px; }
  .componet-school__student-manage-transfer-info .title {
    display: flex;
    justify-content: space-between;
    padding: 5px 15px;
    border-bottom: 1px solid #eee; }
    .componet-school__student-manage-transfer-info .title .title-text {
      font-weight: bolder;
      margin-right: 20px; }
  .componet-school__student-manage-transfer-info .product {
    padding: 8px 20px; }
    .componet-school__student-manage-transfer-info .product .product-name {
      font-weight: bolder;
      margin-right: 50px; }
    .componet-school__student-manage-transfer-info .product .product-teachers {
      display: flex;
      flex-wrap: wrap; }
      .componet-school__student-manage-transfer-info .product .product-teachers > div {
        width: 47%;
        flex-shrink: 0; }
  .componet-school__student-manage-transfer-info .buttons {
    display: flex;
    padding: 8px 30px;
    justify-content: center;
    border-top: 1px solid #aaa; }
    .componet-school__student-manage-transfer-info .buttons .cancel {
      margin-right: 20px; }
