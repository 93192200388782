.performance-container .button {
  float: right; }
  .performance-container .button > span {
    float: right;
    margin-left: 15px; }

.performance-container .operation-button a:first-child {
  margin-right: 10px; }

.performance-container .ant-upload-list {
  display: none !important; }

.add-modal label {
  display: inline-block;
  width: 100px;
  text-align: right; }

.reward-container .static-name {
  font-size: 16px;
  font-weight: 600; }
