.title-tip {
  position: relative; }
  .title-tip .popup {
    display: none;
    position: absolute;
    z-index: 1;
    border: 1px solid #e6e6e6;
    border-radius: 2px;
    background: #e9e9e9;
    width: 300px;
    padding: 5px;
    white-space: normal !important; }
  .title-tip.hovering .popup {
    display: block; }
