.container-index {
  padding-top: 72px;
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column; }
  .container-index .main-content {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -moz-box-flex: 1;
    -moz-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center;
    overflow: hidden; }
    .container-index .main-content > div:nth-child(2):not(.container-common-online-player) {
      position: relative;
      padding: 32px;
      -webkit-box-flex: 1;
      -webkit-flex: 1;
      -moz-box-flex: 1;
      -moz-flex: 1;
      -ms-flex: 1;
      flex: 1;
      overflow: auto; }
      .container-index .main-content > div:nth-child(2):not(.container-common-online-player) .back-container {
        width: 100%;
        height: 60px;
        position: absolute;
        top: 0;
        left: 0;
        padding: 20px 0 0px 32px;
        border-bottom: 2px solid #f7f7f7; }
        .container-index .main-content > div:nth-child(2):not(.container-common-online-player) .back-container .back-link {
          font-size: 12px;
          color: #282828; }
      .container-index .main-content > div:nth-child(2):not(.container-common-online-player) .block-title {
        padding-bottom: 16px;
        font-size: 16px;
        font-weight: 600;
        color: #282828;
        border-bottom: 1px solid #e9e9e9; }
      .container-index .main-content > div:nth-child(2):not(.container-common-online-player) > div:not(:first-child) {
        margin-top: 24px; }

.smart-adaptation-task-container {
  padding-top: 0; }

.report-preview-container {
  width: 100%;
  height: 100%; }
  .report-preview-container img {
    display: block;
    width: 100%; }

.student-container-index {
  height: 100%;
  overflow: auto;
  padding-top: 0;
  background: #f6f8f9; }
  .student-container-index .main-content {
    height: 100%; }
    .student-container-index .main-content .student-page {
      height: 100%;
      padding: 0 !important;
      display: flex;
      flex-direction: column; }
      .student-container-index .main-content .student-page .ant-menu-horizontal {
        border-bottom: 1px solid #eaeff2; }
      .student-container-index .main-content .student-page .menu-tab {
        padding: 0 0 0 40px;
        height: 64px;
        position: absolute;
        left: 0;
        top: 0;
        font-size: 14px; }
        .student-container-index .main-content .student-page .menu-tab .ant-menu-item {
          padding: 0;
          margin-right: 32px;
          height: 64px;
          line-height: 64px;
          color: #818c96 !important; }
        .student-container-index .main-content .student-page .menu-tab .ant-menu-item-active {
          color: #29323a !important;
          border-bottom-color: transparent; }
        .student-container-index .main-content .student-page .menu-tab .ant-menu-item-selected {
          color: #29323a !important;
          border-bottom-color: #385dae !important; }
      .student-container-index .main-content .student-page > .student-header {
        height: 64px;
        flex-grow: 0;
        flex-shrink: 1; }
        .student-container-index .main-content .student-page > .student-header .component-common-header {
          position: relative; }
      .student-container-index .main-content .student-page > .student-body {
        height: calc(100% - 64px);
        margin-top: 0 !important;
        padding: 20px 40px;
        flex-grow: 1;
        flex-shrink: 1;
        -webkit-box-flex: 1;
        -webkit-flex: 1;
        -moz-box-flex: 1;
        -moz-flex: 1;
        -ms-flex: 1;
        flex: 1;
        overflow: auto; }
        .student-container-index .main-content .student-page > .student-body > div:nth-child(1) {
          height: 100%; }
        .student-container-index .main-content .student-page > .student-body .empty-content-container {
          text-align: center; }
          .student-container-index .main-content .student-page > .student-body .empty-content-container img {
            width: 140px;
            margin: 100px 0 10px 0;
            background: transparent; }

.ai-chatbox-trigger {
  position: absolute;
  right: 0px;
  bottom: 0px;
  height: 80px;
  width: 80px;
  background-image: url(//dev-static.smartstudy.com/apollon/assets/images/ai-pt-min.74d0ace.png);
  background-size: 80px 80px;
  z-index: 10; }

.school-chat .assistant-message-content {
  background-color: grey; }

.commonModal {
  display: flex;
  align-items: center; }
  .commonModal .ant-modal {
    width: 400px !important;
    position: static;
    top: 0; }
    .commonModal .ant-modal .ant-modal-content {
      width: 400px;
      min-height: 150px;
      margin-right: 0; }
      .commonModal .ant-modal .ant-modal-content .ant-modal-header {
        border-bottom: 1px solid #e9e9e9;
        text-align: center;
        background: #f7f9fb; }
      .commonModal .ant-modal .ant-modal-content .ant-modal-body {
        text-align: center;
        padding-top: 35px; }
        .commonModal .ant-modal .ant-modal-content .ant-modal-body .header {
          margin-bottom: 15px;
          font-weight: 600;
          font-size: 14px; }
        .commonModal .ant-modal .ant-modal-content .ant-modal-body .contentBody {
          font-size: 14px; }
          .commonModal .ant-modal .ant-modal-content .ant-modal-body .contentBody .confirm-modal .confirm-info {
            color: red; }
      .commonModal .ant-modal .ant-modal-content .ant-modal-footer {
        border-top: none;
        text-align: center;
        padding-bottom: 40px; }
        .commonModal .ant-modal .ant-modal-content .ant-modal-footer .ant-btn {
          height: 32px;
          border-radius: 16px;
          padding: 0 28px; }
          .commonModal .ant-modal .ant-modal-content .ant-modal-footer .ant-btn:last-of-type {
            margin-left: 10px; }
        .commonModal .ant-modal .ant-modal-content .ant-modal-footer .ant-btn-primary {
          background: linear-gradient(-90deg, #62c9ff, #3399ff);
          box-shadow: 0px 5px 20px 0px rgba(51, 153, 255, 0.3);
          border: 0; }
          .commonModal .ant-modal .ant-modal-content .ant-modal-footer .ant-btn-primary:hover {
            opacity: 0.75; }

.rankList-modal .ant-modal-content, .rankList-modal .ant-modal, .medal-modal .ant-modal-content, .medal-modal .ant-modal {
  width: 600px !important; }
  .rankList-modal .ant-modal-content .ant-modal-body, .rankList-modal .ant-modal .ant-modal-body, .medal-modal .ant-modal-content .ant-modal-body, .medal-modal .ant-modal .ant-modal-body {
    text-align: left !important;
    padding: 0 40px; }
    .rankList-modal .ant-modal-content .ant-modal-body .second, .rankList-modal .ant-modal .ant-modal-body .second, .medal-modal .ant-modal-content .ant-modal-body .second, .medal-modal .ant-modal .ant-modal-body .second {
      margin-top: 40px; }
    .rankList-modal .ant-modal-content .ant-modal-body h4, .rankList-modal .ant-modal .ant-modal-body h4, .medal-modal .ant-modal-content .ant-modal-body h4, .medal-modal .ant-modal .ant-modal-body h4 {
      margin-bottom: 20px; }
    .rankList-modal .ant-modal-content .ant-modal-body p, .rankList-modal .ant-modal .ant-modal-body p, .medal-modal .ant-modal-content .ant-modal-body p, .medal-modal .ant-modal .ant-modal-body p {
      line-height: 17px;
      margin-bottom: 12px;
      font-size: 12px; }

body .video-permission {
  height: 100%;
  text-align: center; }
  body .video-permission img {
    width: 120px;
    margin: 120px 0 10px 0;
    background: transparent; }

.ant-select-selection-selected-value, .ant-calendar-range-picker-input, .ant-input, .ant-select-selection__placeholder, .ant-input-number-input {
  font-size: 12px; }

.ant-modal-body {
  font-size: 12px; }
  .ant-modal-body label, .ant-modal-body span, .ant-modal-body table {
    font-size: 12px; }

.ant-pagination .ant-pagination-item-active a {
  color: #fff; }

* {
  font-family: 'PingFangSC','helvetica neue','hiragino sans gb','arial','microsoft yahei ui','microsoft yahei','simsun','sans-serif'; }
