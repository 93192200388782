.component-school-form-pigai-history {
  margin-top: 25px;
  background: #fff; }
  .component-school-form-pigai-history > .schedule > .title {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center; }
    .component-school-form-pigai-history > .schedule > .title .name {
      -webkit-box-flex: 1;
      -webkit-flex: 1;
      -moz-box-flex: 1;
      -moz-flex: 1;
      -ms-flex: 1;
      flex: 1; }
  .component-school-form-pigai-history > .schedule > .task {
    position: relative;
    border-left: 1px solid #eee;
    padding: 11px 0;
    padding-left: 15px;
    font-size: 12px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center; }
    .component-school-form-pigai-history > .schedule > .task > .circle {
      display: block;
      position: absolute;
      left: -4px;
      top: 19px;
      background: #fff;
      width: 7px;
      height: 7px;
      border-radius: 60px;
      border: 1px solid #385DAE; }
    .component-school-form-pigai-history > .schedule > .task .time {
      width: 100px;
      height: 22px;
      line-height: 22px;
      margin-left: 3px;
      border-radius: 2px;
      text-align: left;
      font-size: 12px; }
    .component-school-form-pigai-history > .schedule > .task .duration {
      width: 70px;
      height: 22px;
      line-height: 22px;
      margin-left: 15px; }
      .component-school-form-pigai-history > .schedule > .task .duration .prefix {
        margin: 2px 4px;
        display: inline-block;
        width: 4px;
        height: 4px;
        border-radius: 99px; }
      .component-school-form-pigai-history > .schedule > .task .duration .prefix-red {
        background: #F04134; }
      .component-school-form-pigai-history > .schedule > .task .duration .prefix-green {
        background: #25B756; }
      .component-school-form-pigai-history > .schedule > .task .duration .prefix-blue {
        background: #3399FF; }
      .component-school-form-pigai-history > .schedule > .task .duration .prefix-gray {
        background: #C3CCD1; }
    .component-school-form-pigai-history > .schedule > .task .time-week {
      width: 230px; }
    .component-school-form-pigai-history > .schedule > .task .time-month {
      width: 230px; }
    .component-school-form-pigai-history > .schedule > .task .time-edit input {
      width: 65px;
      height: 22px;
      border: none;
      font-size: 12px;
      padding: 0;
      background: #fff;
      transition: background .15s;
      border: 1px solid #ccc;
      border-radius: 2px;
      text-align: center; }
      .component-school-form-pigai-history > .schedule > .task .time-edit input:hover, .component-school-form-pigai-history > .schedule > .task .time-edit input:focus {
        background: #f5f5f5; }
    .component-school-form-pigai-history > .schedule > .task > .subject {
      width: 45px;
      height: 22px;
      line-height: 22px;
      margin-left: 15px;
      border-radius: 2px;
      text-align: center;
      font-size: 12px; }
    .component-school-form-pigai-history > .schedule > .task > .type {
      width: 80px;
      height: 22px;
      line-height: 20px;
      margin-left: 15px;
      border: 1px solid #EAEFF2;
      text-align: center;
      border-radius: 2px;
      font-size: 12px;
      background: #F6F8F9; }
    .component-school-form-pigai-history > .schedule > .task > .completed,
    .component-school-form-pigai-history > .schedule > .task > .level {
      line-height: 22px;
      margin-left: 15px; }
    .component-school-form-pigai-history > .schedule > .task .completed {
      width: 70px;
      text-align: center;
      background: #eee;
      border-radius: 2px;
      font-size: 12px;
      cursor: pointer; }
    .component-school-form-pigai-history > .schedule > .task .completed-ok {
      background: #b5d171;
      color: #fff; }
    .component-school-form-pigai-history > .schedule > .task > .name {
      height: 22px;
      line-height: 22px;
      font-size: 12px;
      margin-left: 10px;
      padding: 0 5px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-box-flex: 1;
      -webkit-flex: 1;
      -moz-box-flex: 1;
      -moz-flex: 1;
      -ms-flex: 1;
      flex: 1; }
      .component-school-form-pigai-history > .schedule > .task > .name:hover {
        background: #f7f7f7; }
    .component-school-form-pigai-history > .schedule > .task > .remark {
      margin-left: 15px; }
      .component-school-form-pigai-history > .schedule > .task > .remark input {
        width: 200px;
        height: 18px;
        border: none;
        font-size: 12px;
        padding: 0;
        background: #fff;
        transition: background .15s; }
        .component-school-form-pigai-history > .schedule > .task > .remark input:hover, .component-school-form-pigai-history > .schedule > .task > .remark input:focus {
          background: #f5f5f5; }
    .component-school-form-pigai-history > .schedule > .task .report {
      height: 22px;
      line-height: 22px;
      font-size: 12px;
      margin-left: 10px;
      cursor: pointer;
      padding: 0 5px; }
      .component-school-form-pigai-history > .schedule > .task .report:hover {
        background: #f7f7f7; }
  .component-school-form-pigai-history .btn {
    float: right;
    line-height: 22px;
    margin-left: 15px;
    font-weight: normal;
    cursor: pointer;
    transition: color .15s;
    color: #39f;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none; }
    .component-school-form-pigai-history .btn:hover {
      color: #3ec9f6; }
  .component-school-form-pigai-history .btn-left {
    float: left; }
    .component-school-form-pigai-history .btn-left:first-child {
      margin-left: 0; }
  .component-school-form-pigai-history .btn-split {
    float: right;
    width: 1px;
    margin-top: 2px;
    height: 18px;
    margin-left: 15px;
    border-left: 1px solid #eee;
    background: red; }

.time-week-detail,
.time-month-detail,
.wide-date {
  width: 266px;
  display: inline-block; }
