.changing-req {
  position: relative; }
  .changing-req > * {
    background: #fff;
    box-sizing: border-box; }
  .changing-req h5 {
    border-bottom: 1px solid #eee;
    font-size: 14px;
    padding: 20px 0;
    margin-bottom: 10px;
    font-weight: 500; }
  .changing-req .component-common-form-submit {
    box-shadow: none;
    margin-top: -20px; }
    .changing-req .component-common-form-submit .back {
      width: 100%;
      height: 60px;
      line-height: 60px;
      background: #F7F7F7;
      position: absolute;
      right: 0;
      top: 0px;
      text-align: left;
      padding-left: 30px;
      cursor: pointer; }
      .changing-req .component-common-form-submit .back:hover {
        background: #F7F7F7; }
  .changing-req .course-schedule li {
    border-bottom: 1px solid #e9e9e9;
    border-left: 1px solid #e9e9e9; }
    .changing-req .course-schedule li [role=cell] {
      border: 0;
      border-left: 1px solid #e9e9e9; }
      .changing-req .course-schedule li [role=cell] .fa-bgcolor {
        display: flex; }
      .changing-req .course-schedule li [role=cell] .change-bgcolor {
        display: inline-block;
        width: 16px;
        height: 16px;
        line-height: 16px;
        text-align: center;
        background: #999;
        color: #fff;
        border-radius: 3px; }
  .changing-req .course-schedule .hd {
    font-weight: 600; }
    .changing-req .course-schedule .hd li:last-child {
      border-bottom: 1px solid #e9e9e9; }
  .changing-req .desc-info {
    margin-top: 20px; }
    .changing-req .desc-info > span {
      font-size: 12px; }
      .changing-req .desc-info > span:first-child {
        font-size: 14px;
        font-weight: 500; }
  .changing-req .bread-crumb {
    line-height: 1em;
    font-size: 12px;
    margin: 10px 0;
    display: inline-block; }
  .changing-req .select-container {
    margin: 10px 0;
    padding-left: 0.5em;
    display: inline-block; }
  .changing-req .students {
    white-space: nowrap; }
    .changing-req .students li {
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flexbox;
      display: flex; }
      .changing-req .students li span {
        flex: 1;
        padding: 0 10px; }
  .changing-req .main {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex; }
    .changing-req .main .menu-wrapper {
      flex: 0 0 185px;
      margin-top: 14px; }
      .changing-req .main .menu-wrapper > span {
        font-weight: 500;
        font-size: 14px; }
      .changing-req .main .menu-wrapper > :nth-child(2) {
        border: 1px solid #e9e9e9;
        height: 1090px;
        border-radius: 4px; }
      .changing-req .main .menu-wrapper > :first-child {
        display: block;
        padding: 10px 0; }
      .changing-req .main .menu-wrapper ul li {
        margin: 8px 0; }
        .changing-req .main .menu-wrapper ul li span {
          height: 18px;
          margin: 0 6px; }
          .changing-req .main .menu-wrapper ul li span:first-child {
            width: 70px;
            font-size: 12px;
            line-height: 1.5;
            color: #282828; }
          .changing-req .main .menu-wrapper ul li span:nth-child(2) {
            width: 13px;
            font-size: 12px;
            line-height: 1.5;
            color: #282828; }
          .changing-req .main .menu-wrapper ul li span:last-child {
            width: 36px;
            font-size: 12px;
            line-height: 1.5;
            color: #282828; }
    .changing-req .main .schedule-wrapper {
      flex: 5;
      padding-left: 20px; }
    .changing-req .main .oper-wrapper {
      overflow: hidden;
      padding-left: 40px;
      line-height: 40px;
      height: 40px;
      position: relative;
      margin-bottom: 10px; }
      .changing-req .main .oper-wrapper > div {
        float: right; }
        .changing-req .main .oper-wrapper > div button {
          margin-left: 15px; }
  .changing-req .student-base-info > div {
    border-bottom: 1px solid #e6e6e6; }
  .changing-req .student-base-info > h3 {
    line-height: 30px;
    padding-left: 0.5em; }
    .changing-req .student-base-info > h3 > :first-child {
      font-size: 14px;
      font-weight: 500; }
  .changing-req > .content {
    margin-top: -5px; }
    .changing-req > .content > * {
      margin-bottom: 25px; }
  .changing-req .remark-row {
    margin-top: 25px; }
    .changing-req .remark-row > * {
      -webkit-box-align: start;
      -ms-flex-align: start;
      -webkit-align-items: flex-start;
      -moz-align-items: flex-start;
      align-items: flex-start; }
    .changing-req .remark-row > label {
      flex: 1;
      display: block;
      border-left: 5px solid #039fe5;
      padding-left: 10px;
      font-weight: 500; }
    .changing-req .remark-row textarea {
      display: block;
      margin-top: 10px;
      width: 98%;
      margin-left: 2%;
      border-radius: 4px;
      min-height: 120px;
      border: 1px solid #e6e6e6;
      line-height: 1.5;
      padding: 5px;
      color: #666;
      font-size: 12px; }

.select-list-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  text-align: center; }
  .select-list-item span {
    flex: 1;
    font-size: 12px; }

.batch-tiaoke-modal .req-modal-item label {
  margin-right: 30px; }

.batch-tiaoke-modal .req-modal-item b {
  color: red; }

.batch-tiaoke-modal .hide {
  display: none; }

.batch-tiaoke-modal .show {
  display: block; }
