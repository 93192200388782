.trial-course-view-container .trial-lesson-form {
  padding-bottom: 24px;
  border-bottom: 1px solid #e9e9e9; }
  .trial-course-view-container .trial-lesson-form h4 {
    margin-bottom: 24px;
    padding-left: 8px;
    border-left: 4px solid #39f; }
  .trial-course-view-container .trial-lesson-form .ant-form-item-label {
    width: 90px;
    float: left; }
    .trial-course-view-container .trial-lesson-form .ant-form-item-label label {
      color: rgba(0, 0, 0, 0.65);
      font-size: 12px; }
  .trial-course-view-container .trial-lesson-form textarea {
    margin: 5px 0 0 8px;
    width: 600px;
    min-height: 120px; }
  .trial-course-view-container .trial-lesson-form .ant-select-selection {
    font-size: 12px; }
  .trial-course-view-container .trial-lesson-form .pre-blank {
    padding-left: 8px; }

.trial-course-view-container .detail {
  margin: 0;
  padding-top: '24px'; }
  .trial-course-view-container .detail h4 {
    margin-bottom: 24px;
    padding-left: 8px;
    border-left: 4px solid #39f; }
  .trial-course-view-container .detail .left-label {
    width: 33.3%;
    font-size: 12px;
    float: left;
    margin-bottom: 15px; }
    .trial-course-view-container .detail .left-label .left {
      width: 100px;
      float: left;
      text-align: right; }
    .trial-course-view-container .detail .left-label .right {
      padding-left: 8px; }

.trial-course-view-container .component-common-form-submit .ant-btn:last-child {
  background: #355AA0;
  color: #fff; }

.try-lesson-manage table th, .try-lesson-manage table td {
  height: 45px; }

.try-lesson-manage .addTrialBtn {
  float: right; }
