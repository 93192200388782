.component-common-form-input {
  position: relative;
  height: 35px;
  border: 1px solid #eee;
  border-radius: 35px;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center; }
  .component-common-form-input .title {
    height: 33px;
    padding: 0 10px;
    top: 1px;
    left: 1px;
    line-height: 33px;
    padding-left: 16px;
    display: inline-block;
    background: #f7f7f7;
    border-right: 1px solid #eee; }
  .component-common-form-input input {
    height: 33px;
    width: 200px;
    padding: 0 10px;
    padding-right: 34px;
    font-size: 13px;
    border: none;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -moz-box-flex: 1;
    -moz-flex: 1;
    -ms-flex: 1;
    flex: 1; }
  .component-common-form-input .fa-search {
    position: absolute;
    right: 16px;
    top: 9px;
    color: #ccc;
    cursor: pointer; }

.component-common-form-input-form {
  border-radius: 2px;
  border: 1px solid #d9d9d9;
  width: 100%; }
  .component-common-form-input-form input {
    padding: 0 8px; }
  .component-common-form-input-form .fa {
    display: none; }
