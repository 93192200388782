.component-common-tip {
  position: fixed;
  z-index: 800;
  left: 0;
  top: 0;
  width: 100%;
  transition: top .15s, opacity .15s;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center; }
  .component-common-tip .wrapper {
    padding: 12px 20px;
    background: #fff;
    border-radius: 2px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.35);
    margin-top: 20px; }
    .component-common-tip .wrapper .fa-check-circle-o,
    .component-common-tip .wrapper .fa-exclamation-circle {
      font-size: 18px; }
    .component-common-tip .wrapper .fa-times {
      color: #bbb;
      font-size: 16px;
      margin-left: 15px;
      cursor: pointer;
      transition: color .15s; }
      .component-common-tip .wrapper .fa-times:hover {
        color: #999; }
    .component-common-tip .wrapper span {
      display: inline-block;
      margin-left: 10px;
      vertical-align: middle;
      color: #d53929; }
      .component-common-tip .wrapper span:first-child {
        margin-left: 0; }
  .component-common-tip .correct span {
    color: #479948; }
