.component-common-phone-input .ant-input-group-wrapper {
  display: inline; }

.component-common-phone-input .ant-input {
  margin-top: 0 !important;
  padding-left: 10px !important;
  outline: none; }

.component-common-phone-input .ant-input-group-addon {
  margin-left: 0; }
  .component-common-phone-input .ant-input-group-addon .code-wrap {
    width: 30px;
    position: relative;
    font-size: 12px; }
    .component-common-phone-input .ant-input-group-addon .code-wrap .code-select {
      width: 200px;
      position: absolute;
      left: 0;
      opacity: 0; }
      .component-common-phone-input .ant-input-group-addon .code-wrap .code-select .ant-select-selection {
        height: 100%; }
    .component-common-phone-input .ant-input-group-addon .code-wrap .ant-select-selection-selected-value {
      display: flex !important; }

.code-select-item {
  display: flex; }
  .code-select-item .code-select-left {
    flex-shrink: 0;
    flex-grow: 0;
    width: 35px;
    font-size: 12px;
    text-align: right;
    padding-right: 5px; }
  .code-select-item .code-select-right {
    font-size: 12px;
    overflow-x: scroll; }
