.component-common-pagination {
  padding: 15px 18px;
  background: #fff;
  border-radius: 2px;
  box-shadow: rgba(0, 0, 0, 0.0470588) 0 1px 1px 0;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none; }
  .component-common-pagination:before, .component-common-pagination:after {
    content: ' ';
    display: table;
    clear: both; }
  .component-common-pagination .btns {
    float: left; }
    .component-common-pagination .btns .item {
      display: inline-block;
      width: 26px;
      height: 26px;
      background: #FCFCFC;
      margin-left: 10px;
      border: 1px solid #E6E6E6;
      border-radius: 24px;
      line-height: 25px;
      text-align: center;
      font-size: 14px;
      cursor: pointer;
      transition: background .15s; }
      .component-common-pagination .btns .item:first-child {
        margin-left: 0; }
      .component-common-pagination .btns .item:hover {
        background: #f3f3f3; }
    .component-common-pagination .btns .active {
      background: #39f;
      color: #fff;
      border: 1px solid #2491ff; }
      .component-common-pagination .btns .active:hover {
        background: #3ec9f6; }
  .component-common-pagination .info {
    float: right;
    height: 26px;
    background: #FCFCFC;
    border: 1px solid #E6E6E6;
    padding: 0 12px;
    border-radius: 24px;
    font-size: 14px;
    line-height: 25px; }
