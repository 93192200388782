.smart-adaptation-study-videos {
  padding: 40px 0;
  height: calc(100% - 56px); }
  .smart-adaptation-study-videos .switch-btns {
    margin-bottom: 40px; }
    .smart-adaptation-study-videos .switch-btns span {
      width: 75px;
      height: 14px;
      line-height: 14px;
      padding-left: 19px;
      font-size: 14px;
      color: #2e3236;
      background-repeat: no-repeat;
      background-size: 12px 12px;
      background-position: 2px;
      cursor: pointer; }
    .smart-adaptation-study-videos .switch-btns span.active {
      color: #39f; }
    .smart-adaptation-study-videos .switch-btns .course.active {
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAAGXcA1uAAAAAXNSR0IArs4c6QAAAbNJREFUSA3FVDtOxDAQtZc9wXas4AwUINFSs1wAeuAGHIIbAD2cIKm3BUHBGUCk4wQL4T0rMxpbifORVkSKPJ7Pm5k3tr3Dd3hX11yjz3NHi/eu9Ef39SOczuczt69uwSpuqk0Fes1FaVIVonPHD/WebiB04VZv137ZXi2iZhbCymogrimgO4I5vixEJJML/GWkNBu2I2lC4yQOpSujxteR3edLv0JAUCtT4iSBsk9X7S41dO1HB4SSOHhpqgsZvXzTFholC5tf94L9blsAnJ9er/yF2oiengQ1QiDlpF51feXQUXwiWoFSYCanigQhpTkKQJ0r69wmT6NVkLZSUtTDVjIMaroZasVeQ0k8JzIYIcCuODZOHppwlmgkyk/t3jG4hXUeKzP5jncHuHSfjFWSmsO6YGYxjgVvDv3Hpg4Hf8l4y1G4CVPBCWZi9VZpB3RIv7axpz7YFzj/Zy36oMomGHI3uoBFn00wpAMMtcwVkk2QC5QK+1Y75D7fSfZsB0MoQtb/HbJSxJefHKDqMvfgd/HEGMbSLlhBtgHNc3GL5+IEer0s1icjVwBe45m4MRfO/QGb/sR0FjOyBAAAAABJRU5ErkJggg==); }
    .smart-adaptation-study-videos .switch-btns .product.active {
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAAGXcA1uAAAAAXNSR0IArs4c6QAAA6RJREFUSA2VVktIlFEUPv8/MyWSUQuDJolAemGtHAgKidqGldXShZVaEAUtjIggwhaRi0Bo4diLcGdmGrWMIFoEujMqyKgII5CKBmx8zNy+78y91zujBF24/zn3vF/3zkSClek3ZxIiI4o03TW1JMquflOniPs09pkc8ZifKJLPCvlpzJqiGNlMXCBWUMR+9Ox0rYBxApElFMZPRQhBtesBDhDXlcma99jP3FlwOOgPQBB9F89JY6QVcBS+DOxOjHVEO4kna9bKCUrAh/ojzhXnfsqPErr4hUgpfCT1yJFphrhXZxAgjYA2mIjkcvUamYI1BnYfFvJjndFqKuhy2jzA6undD8w64pk+M0TIRW+Q64+ZLyxuLZHxNXJ29o98pxHEcMTRxzsj4u3sxmTBSMYx4N6X19FCqDnAy29k040a9YTMEKfHqqRs8EmTyRgB2kNBlmxlQja+OhlNkV6mwDFCeFkE3wTBKXB74fVWaEAnigSGtVCUjxAeQNfWg9GM0drLUFjyUEkHzTW/jGEPUCp4JcYddtTVnr1gT5wBeiKexG5HjcNctsDaBHrRUJ0SbaBVGuQFokLZgpkdrhMz8/ICzAYKcFyKRp4sUeB40n3lmKYS8i2/IGlfJVrhcrESMrQSVQTCzQj8Jcu5ACYvlF9ItugPFoHMHPsUs4ugfQgFUIQyzyw5rOdfd0RfVQ7a5xhCpScy7Vj7B8GXc88dk54tyBdUW+994PE2CtDhzl7BEQipjCSvIozjyxgIRflQFSBzD5N8xQ1oKFDmgKmAeRM7xqAOo39diGYyVKjENe1IeiDbAh6Lex46vU5OHdh03yGSGpTzwr/unVNcDrL46N8NZJVDs7Yxo8hesU8wTu/bKyPGGDwE7yiU3qyokv00PJeX56A1gDaEx+pY6Mw28i14MaZ1U2zvLxvVVmmciqtS0oqs9sFgLd8qbuKkkRcaJ25ttEEmQdsxkCYycOdHCStXbl66kTYfjemAN00aeQHNo84WbcdIRZ+mmV+S9hIBgiZxyN12HD1bnqN56GzRNqdFO450rnmJAKmtk0s4ckK43dKz5Tmah96Wta2PL5pjwofMSwcI+D3cAWkJam8mby0f9MVHG9PCn7ZhJcZy8X9H1Y7oddWPpAXTpT0tu2hk0hHAAJzVAM5jP+bGyI3x9xS4sMYoA3/cDtudQr35P6TVGQaua4kDxyDUOyJyCLeU/y3q4VQHwQ7GJPJ/ivke8a9aqGzxvzcZuay1A8zBAAAAAElFTkSuQmCC); }
    .smart-adaptation-study-videos .switch-btns .course {
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAAGXcA1uAAAAAXNSR0IArs4c6QAAAcVJREFUSA3NVr1OwzAQtlPUdO5GBc/AKzBTNiYKE5UYEV14CJZWjEh0omVibGdmNp4BRLbMDRKE+9zc1TGJm0aqhJfY9/Pd3ec7t1rRGj5MUnxzS+MEjdZ6rkfj6TRN09NG2NoXM6NlM5G6G1jtsHAVSs+UyqLePT7vsQG+ZbjRoH/WKc6WvAIbwt6LArirBDweiPFpQ+T24GI0nsxzQuuAcjiMKRzEUerCqGWrwO7V+UmXHIxYmGIjduSz+5XqXEXZeWMHkxIunosqQ1ZKx9CZQsHCd7J4pfNukQMV/nR90euJDuhuJ4iSNqAc1ItsfTqr1s7RSigzupMjQaKNS3POgfLs2sZF+3q0MtJWUsrVsJUIlYrOLjVCrVlKOvZdHrWN4ofG9BI8l/2UvNGr0ca5/tJxIwwPaOg+gCEkZc3aRmRWbhoka/r3DKsDf5sjMwl1wQFm+cpUSQUwcFfRtbs2NLGzQb93/Fe+lHgDVJmNMmCWewNUqQCvqC8RbwCfI2e47mtf8jrbWnpvBVUo+j+XjJcfP4l40INm69Lq6UrUYMh+vhb3Kf19ABY7yVMBwdIouaVAh3SUYYGuwooI+CVohjd2cr/7uNvhNIrriwAAAABJRU5ErkJggg==);
      margin-right: 40px; }
    .smart-adaptation-study-videos .switch-btns .product {
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAAGXcA1uAAAAAXNSR0IArs4c6QAABApJREFUSA2VVl1oVEcUvmfu3Zi19edFwbRIwbZaYt/62of2VTRGK3RjHuKmFUGSbh4spRRE7EOpD9msSKGaTShmt7TGn5T6KIW+9tHSFiq0IpGCSDFqVnfvjN83u+dmspFCB/bOmfM7c+Y7Z1YijKnp+nFZ13PNE5Xqd1vIjCoX51/2hH7K07Ul0oYfJ9HfnIWfcrVmjTGvkY4mp+dST3Q+fq22HQWnCt6cGhOjh2MyK7Pf7rDW7VEFuKv9Ua7OXc8Y5dn6vmwBojxTO8F1Elk7jHkB7rA7uVk6MvQm6ST3Yq5IDcTw8UhzmOZS836bXPmKSHv7ONRlZdMN6cycm3CpvSYSfZ/E8WeSN4utR+k+Z+0sNBsItVGNmZHswFMz9WNffXN5K4U41rwqMdpktXbe8Ly5JN6pAufsWKO5/A+dOBcdUH6pOHQgcu4DI+Jupda9pQIXSRZNeeGsKX8gRk4jR2dCYUj73K03L2WHppB7ZNhQkSmL87J9rFBYJH+VAWFknza+BmLexr4WTSSVj0YL51Y50AVCPoB1rxgzkrwQL7hl29dK089x8EMSm4HSSGFBdT3Q9PIzZkDAWZqBhfsOb1Rzz7vgnagdD0064SFLIWCcvA4nN3EX/bHk/AVSkQhgASVchMNFbnfUuQkbNX+CrJ9ywqXZsj+sMSA8Gb4bpvnN+bute4/6fN3Rgw7dK2duTfkP7z/eCxD8DGhIyoJSAT1j+AgTxaHdyidqTU/vUcNbTNP0TxVwBtBWRWbKcazG+PDBO/6my9X6OFA6Fcfxq+Mj798KjZlywHYAkX15Z9A4W6/3pcvuNoRekBmJXMDWPtR1ZqAMzjRuLbtTEB5Z4yBUBM0cYLszSV5OKkBDlVUBOkeZhAKTdwVN7UT3kUJj0u2OZc9gI4NYWhEzUSoWKqrnA/gdP7a/g7kBdffxf9WdGj5v9sm37kvIlpL1ZhdPJO0Se/IXIGtNHL/RvWM030tA40Hk4tfepPddOm60Gjeg349GOV8aHXovDOZPlKa/Qd+YnnWvGNYv88yy7HZOw01m23BkzDt4FrawV/FHmjwvC72Dpg/6ok/6NmwO1GHNd+n65b9u8bQ4ew65vKdy0uRRprxwznzBtwECfGtiQwmVlDZOAHL9Kbe9bsuUtzKrL/o2bGsUsVutqKxQsm3zp7FEg/wpV9eUKS+c1Rd9exRp8yU0ff8PtQN6sjrnu/pE8bB/GgNRRnYqcxCX7AvOB6C087RdIQ2ofvJ/odqB6BfePjaD2rOzABRwdAJdBLkBwibejatA3NXYyC98T6nDHPNxA7L3o5nuR65zYC/hMRhWx9TjWBOgzW5/WSPuydMBJ3aPc7IDyh4IvDy+oOLMj/wPxK4W2oX0M2miCQ176BqiAAAAAElFTkSuQmCC); }
  .smart-adaptation-study-videos .video-blocks .big-title {
    margin-bottom: 18px;
    height: 24px;
    line-height: 24px; }
    .smart-adaptation-study-videos .video-blocks .big-title > * {
      float: left; }
    .smart-adaptation-study-videos .video-blocks .big-title img {
      margin-right: 4px;
      width: 24px;
      height: 24px;
      background: transparent; }
    .smart-adaptation-study-videos .video-blocks .big-title .subject {
      font-size: 16px;
      color: #29323a; }
  .smart-adaptation-study-videos .line-scale-pulse-out {
    margin-top: 13%; }

.smart-adaptation-study-videos .video-blocks .blocks .course-card-wrapper {
  margin: 0 17px 40px; }
