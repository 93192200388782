.students-tests-container .operations {
  margin-bottom: 20px;
  text-align: right; }
  .students-tests-container .operations a {
    display: inline-block;
    width: 80px;
    height: 28px;
    line-height: 28px;
    text-align: center;
    border-radius: 4px;
    background: #385dae;
    color: #fff;
    font-size: 12px; }
    .students-tests-container .operations a:hover {
      background: #7092dc; }

.students-tests-container .hide {
  display: none; }

.students-tests-container .student-reports-container {
  height: calc(100% - 128px); }
