.common-container .go-back {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 50px;
  line-height: 50px;
  padding-left: 32px;
  background: #f7f7f7;
  font-size: 12px;
  cursor: pointer; }

.common-container .filter {
  border-bottom: 1px solid #eee;
  padding: 0  0 15px 0;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  position: relative; }

.common-container .filter-item {
  display: flex;
  flex-wrap: wrap;
  margin-right: 20px;
  padding: 0; }
  .common-container .filter-item > label {
    padding: 7px 0;
    display: inline-block; }
  .common-container .filter-item > div {
    padding: 3px; }

.common-container .filter-item.float-right {
  position: absolute;
  right: 0;
  margin-right: 0;
  margin-top: 3px; }

.common-container .watch-ptdaily th, .common-container .watch-ptdaily td {
  border-right: 1px solid #eee; }

.common-container .expandedrows table, .common-container .expandedrows tr, .common-container .expandedrows td {
  border: none; }

.ZA-manage .ant-table-row {
  position: relative; }
  .ZA-manage .ant-table-row .out {
    position: absolute;
    left: 20px;
    height: 30px;
    width: 50px;
    display: inline-block;
    background-size: cover;
    opacity: 0.3; }
  .ZA-manage .ant-table-row .padding-left {
    font-weight: 600; }

.ZA-manage .ant-table-row {
  position: relative; }
  .ZA-manage .ant-table-row .out {
    position: absolute;
    left: 20px;
    height: 30px;
    width: 50px;
    display: inline-block;
    background-size: cover;
    opacity: 0.3; }
  .ZA-manage .ant-table-row .out-refund {
    position: absolute;
    left: 20px;
    height: 30px;
    width: 50px;
    display: inline-block;
    background-size: cover;
    opacity: 0.3; }
  .ZA-manage .ant-table-row .padding-left {
    font-weight: 600; }
  .ZA-manage .ant-table-row .right-width {
    width: 130px;
    overflow: hidden; }

.common-container-top {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #eee; }

.common-container-top-left,
.common-container-top-right {
  display: flex;
  flex-direction: row; }
  .common-container-top-left > div,
  .common-container-top-right > div {
    margin-right: 8px; }
    .common-container-top-left > div > span,
    .common-container-top-right > div > span {
      margin-right: 8px; }
  .common-container-top-left .back,
  .common-container-top-right .back {
    margin-left: 32px;
    font-size: 12px;
    line-height: 1.5;
    color: #282828; }

.common-container-top-right {
  margin-right: 32px;
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: normal;
  color: #666666; }

.common-table tr > td {
  padding: 8px;
  height: 45px; }

a {
  color: #39f; }

.common-operations a {
  margin-right: 8px; }
