.container-school-my-homepage .item {
  margin-bottom: 15px; }
  .container-school-my-homepage .item .title {
    font-size: 16px;
    font-weight: 500;
    color: #282828;
    border-bottom: 1px solid #ddd;
    height: 28px;
    line-height: 28px;
    padding-bottom: 8px;
    box-sizing: content-box; }
  .container-school-my-homepage .item .content {
    padding: 10px 0; }
    .container-school-my-homepage .item .content .tabs {
      margin-bottom: 15px; }
    .container-school-my-homepage .item .content .spin-wrapper {
      width: 100%;
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center; }
