.container-platform-content-document .component-common-list {
  margin-top: 25px; }

.container-platform-content-document .component-common-pagination {
  margin-top: 25px; }

.container-platform-content-document .questions-filter-container {
  margin: 0 0 40px 0; }

.container-platform-content-document .information-manage tr {
  height: 45px; }

.container-platform-content-document table {
  border-radius: 4px !important; }
  .container-platform-content-document table thead tr th {
    text-align: left !important; }
  .container-platform-content-document table tbody tr {
    height: 45px; }
    .container-platform-content-document table tbody tr td {
      text-align: left !important; }
      .container-platform-content-document table tbody tr td .ellipsis {
        width: 194px;
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; }
      .container-platform-content-document table tbody tr td .short {
        width: 100px; }
      .container-platform-content-document table tbody tr td .nowrap span {
        width: 120px;
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; }

.distribution-of-information .ant-checkbox-group {
  width: 100%;
  height: 250px;
  overflow-y: scroll;
  padding-left: 40px;
  margin-top: 20px; }
  .distribution-of-information .ant-checkbox-group label {
    width: 23%;
    float: left;
    height: 35px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
    .distribution-of-information .ant-checkbox-group label span {
      font-size: 12px; }
