.con-school__group-course-detail {
  position: relative; }
  .con-school__group-course-detail .schedule-calendar {
    background: #fff;
    margin-bottom: 24px;
    border-radius: 2px; }
    .con-school__group-course-detail .schedule-calendar .title {
      position: absolute;
      left: 0;
      top: 0;
      height: 50px;
      width: 100%;
      line-height: 50px;
      padding-left: 32px;
      padding-bottom: 8px;
      background: #f7f7f7; }
      .con-school__group-course-detail .schedule-calendar .title a {
        line-height: 50px;
        color: #333;
        font-size: 12px; }
    .con-school__group-course-detail .schedule-calendar .buttons {
      width: 380px;
      margin-top: 20px;
      margin-bottom: 15px;
      float: right;
      text-align: right; }
      .con-school__group-course-detail .schedule-calendar .buttons button:not(:last-child) {
        margin-right: 10px; }
      .con-school__group-course-detail .schedule-calendar .buttons button:not(.export) {
        background: #fff !important;
        color: #355AA0;
        border: 1px solid #355AA0; }
    .con-school__group-course-detail .schedule-calendar .introduction {
      padding: 8px 0;
      margin-top: 50px;
      clear: both; }
      .con-school__group-course-detail .schedule-calendar .introduction .desc-info {
        font-size: 12px; }
        .con-school__group-course-detail .schedule-calendar .introduction .desc-info div:first-child {
          margin-bottom: 8px; }
    .con-school__group-course-detail .schedule-calendar .detail {
      margin-bottom: 8px;
      font-size: 12px; }
      .con-school__group-course-detail .schedule-calendar .detail > span:nth-child(even) {
        margin-right: 15px; }
      .con-school__group-course-detail .schedule-calendar .detail + p {
        font-size: 12px; }
    .con-school__group-course-detail .schedule-calendar .subjects-wrap {
      padding: 8px 0; }
      .con-school__group-course-detail .schedule-calendar .subjects-wrap .subjects-title {
        font-weight: 500;
        padding-bottom: 8px;
        margin-top: 20px; }
      .con-school__group-course-detail .schedule-calendar .subjects-wrap .subjects-content {
        display: flex; }
        .con-school__group-course-detail .schedule-calendar .subjects-wrap .subjects-content .ant-btn {
          border-radius: 0; }
          .con-school__group-course-detail .schedule-calendar .subjects-wrap .subjects-content .ant-btn:last-child {
            border-radius: 0 3px 3px 0; }
          .con-school__group-course-detail .schedule-calendar .subjects-wrap .subjects-content .ant-btn:first-child {
            border-radius: 3px 0 0 3px; }
      .con-school__group-course-detail .schedule-calendar .subjects-wrap .course-count {
        margin-left: 5px; }
        .con-school__group-course-detail .schedule-calendar .subjects-wrap .course-count .red {
          color: red; }
    .con-school__group-course-detail .schedule-calendar .course-schedule .hd > li:last-child {
      border-bottom: 0; }
      .con-school__group-course-detail .schedule-calendar .course-schedule .hd > li:last-child .empty {
        border: 0;
        width: 38px; }
    .con-school__group-course-detail .schedule-calendar .course-schedule {
      margin-top: 20px;
      clear: both; }
      .con-school__group-course-detail .schedule-calendar .course-schedule li {
        box-sizing: border-box;
        display: flex; }
      .con-school__group-course-detail .schedule-calendar .course-schedule .hd {
        font-weight: 600;
        border: 1px solid #e9e9e9; }
        .con-school__group-course-detail .schedule-calendar .course-schedule .hd li:first-child {
          border: 0;
          border-bottom: 1px solid #e9e9e9; }
        .con-school__group-course-detail .schedule-calendar .course-schedule .hd li:last-child [role=cell] {
          border-top: 0;
          border-bottom: 0; }
        .con-school__group-course-detail .schedule-calendar .course-schedule .hd .duration .ant-input {
          background: #F7F7F7;
          border: 0;
          text-align: center;
          font-size: 14px;
          cursor: pointer; }
      .con-school__group-course-detail .schedule-calendar .course-schedule .schedule li {
        border-bottom: 1px solid #e9e9e9;
        border-left: 1px solid #e9e9e9; }
        .con-school__group-course-detail .schedule-calendar .course-schedule .schedule li [role=cell] {
          border: 0;
          border-left: 1px solid #e9e9e9; }
          .con-school__group-course-detail .schedule-calendar .course-schedule .schedule li [role=cell] .change-color {
            color: #000;
            font-size: 12px;
            width: 100%; }
          .con-school__group-course-detail .schedule-calendar .course-schedule .schedule li [role=cell] .big-box {
            display: flex; }
          .con-school__group-course-detail .schedule-calendar .course-schedule .schedule li [role=cell] .course-info .group-bg {
            width: 16px;
            height: 16px;
            text-align: center;
            line-height: 16px;
            border-radius: 2px;
            margin-right: 8px;
            background: #999;
            color: #fff;
            font-size: 12px; }
          .con-school__group-course-detail .schedule-calendar .course-schedule .schedule li [role=cell] .unhandle {
            background: #EAEFF2;
            color: #000;
            border: 1px solid #C3CCD1; }
            .con-school__group-course-detail .schedule-calendar .course-schedule .schedule li [role=cell] .unhandle .numbers {
              background: #C3CCD1; }
          .con-school__group-course-detail .schedule-calendar .course-schedule .schedule li [role=cell] .unfinished {
            cursor: default;
            background: #FFDADA;
            color: #000;
            border: 1px solid #FFBEBC; }
            .con-school__group-course-detail .schedule-calendar .course-schedule .schedule li [role=cell] .unfinished .numbers {
              background: #FF9390; }
          .con-school__group-course-detail .schedule-calendar .course-schedule .schedule li [role=cell] .completed {
            background: #CBE9FF;
            color: #000;
            border: 1px solid #AFDDFF; }
            .con-school__group-course-detail .schedule-calendar .course-schedule .schedule li [role=cell] .completed .numbers {
              background: #49A9EE; }
          .con-school__group-course-detail .schedule-calendar .course-schedule .schedule li [role=cell] .orange {
            background: #FFD700;
            border: 1px solid #ffd35f; }
          .con-school__group-course-detail .schedule-calendar .course-schedule .schedule li [role=cell] .pink {
            cursor: pointer;
            background: #FFDADA;
            color: #000;
            border: 1px solid #FFBEBC; }
            .con-school__group-course-detail .schedule-calendar .course-schedule .schedule li [role=cell] .pink .numbers {
              background: #FF9390; }
          .con-school__group-course-detail .schedule-calendar .course-schedule .schedule li [role=cell] .blue {
            background: #CBE9FF;
            color: #000;
            border: 1px solid #AFDDFF; }
            .con-school__group-course-detail .schedule-calendar .course-schedule .schedule li [role=cell] .blue .numbers {
              background: #49A9EE; }
        .con-school__group-course-detail .schedule-calendar .course-schedule .schedule li:first-child [role=cell] {
          border-top: 0; }
    .con-school__group-course-detail .schedule-calendar .content {
      padding: 8px;
      display: flex;
      justify-content: center;
      overflow: scroll; }

.select-info .ant-modal-content {
  min-height: 320px; }
  .select-info .ant-modal-content table tr td {
    border: none; }
    .select-info .ant-modal-content table tr td:first-child {
      width: 100px;
      text-align: right; }
    .select-info .ant-modal-content table tr td:last-child {
      text-align: left; }
  .select-info .ant-modal-content .ant-modal-footer {
    width: 100%;
    position: absolute;
    bottom: 0; }
