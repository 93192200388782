.container-school__teaching-req-list .filter {
  background-color: #fff;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  position: relative; }

.container-school__teaching-req-list .ant-table-content {
  border: 1px solid #eee;
  border-radius: 4px 4px 0 0; }

.container-school__teaching-req-list .filter-item {
  display: flex;
  flex-wrap: wrap;
  margin-right: 20px;
  padding: 0; }
  .container-school__teaching-req-list .filter-item:last-child {
    position: absolute;
    right: 0;
    margin-right: 0;
    top: 3px;
    margin-right: 0; }
  .container-school__teaching-req-list .filter-item > label {
    padding: 7px 0;
    display: inline-block; }
  .container-school__teaching-req-list .filter-item > div {
    padding: 3px 0; }

.container-school__teaching-req-list td {
  height: 53px; }

.container-school__teaching-req-list .center-img {
  height: 53px;
  justify-content: center; }

.state {
  line-height: 28px; }
